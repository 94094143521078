import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import parse from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';

import { toast } from 'react-toastify';

import Logo from '../../assets/images/logo.png';

import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';
import { ReactComponent as LockIcon } from '../../assets/images/lock.svg';
import { ReactComponent as UserIcon } from '../../assets/images/placeholder.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';

import { NotificationModal } from '../../components/NotificationModal';
import { TermsModal } from '../../components/TermsModal';
import { api } from '../../services/api';

import { maskCPF } from '../../helpers/maskCPF';
import { maskPhoneNumber } from '../../helpers/maskPhoneNumber';
import {
  CancelButton,
  Container, Content, IconInputContainer, StyledForm, SubmitButton, TermsContainer,
} from './styles';

function maskDate(date: string) {
  return date
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
}

export default function Signup() {
  const history = useHistory();
  const [signupFormData, setSignupFormData] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    password_confirmation: '',
    cpf: '',
    accepted_terms: false,
    born_at: '',
  });
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'cpf') {
      setSignupFormData({ ...signupFormData, [name]: maskCPF(value) });
    } else if (name === 'phone') {
      setSignupFormData({ ...signupFormData, [name]: maskPhoneNumber(value) });
    } else if (name === 'born_at') {
      setSignupFormData({ ...signupFormData, [name]: maskDate(value) });
    } else {
      setSignupFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignupFormData((prevState) => ({
      ...prevState,
      accepted_terms: event.target.checked,
    }));
  };

  const handleOpenTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (signupFormData.password !== signupFormData.password_confirmation) {
      toast.error('Os campos de senha e confirmação de senha devem ser iguais');
      return;
    }

    const phoneObj = {
      country_code: '55',
      state_code: signupFormData.phone.replace(/[^0-9]/g, '').substring(0, 2),
      number: signupFormData.phone.replace(/[^0-9]/g, '').substring(2),
    };

    const bornAtDate = parse(signupFormData.born_at, 'dd/MM/yyyy', new Date());

    if (isAfter(bornAtDate, new Date())) {
      toast.error('Data de nascimento inválida');
      return;
    }

    let parsedBornAt = '';

    try {
      parsedBornAt = bornAtDate.toISOString();
    } catch (error:any) {
      toast.error('Data de nascimento inválida');
      return;
    }

    const individual_person = {
      name: signupFormData.name,
      cpf: signupFormData.cpf.replace(/[^0-9]/g, ''),
      born_at: parsedBornAt,
    };

    const formattedData = {
      user_phone: phoneObj,
      individual_person,
      password: signupFormData.password,
      password_confirmation: signupFormData.password_confirmation,
      email: signupFormData.email.trim(),
    };

    if (signupFormData.accepted_terms) {
      try {
        const response = await api.post('/users', formattedData);

        const validateEmailResponse = await api.post('/users/send-verification', {
          email: signupFormData.email,
        });

        if (response && validateEmailResponse) {
          history.push('/cadastro/confirmar');
        }
      } catch (err: any) {
        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          type: 'error',
          title: err?.response ? err?.response.data.message : err?.message,
        }));
      }
    } else {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: 'É preciso aceitar os termos de uso',
      }));
    }
  };

  return (
    <>
      <Container>
        <Content>
          <img src={Logo} alt="logo" />
          <h1>Cadastre-se</h1>
          <StyledForm onSubmit={handleSubmit}>
            <IconInputContainer active={signupFormData.name.length > 0}>
              <UserIcon />
              <input
                type="text"
                name="name"
                value={signupFormData.name}
                onChange={handleInputChange}
                placeholder="Nome Completo"
                required
              />
            </IconInputContainer>
            <IconInputContainer active={signupFormData.phone.length > 0}>
              <PhoneIcon />
              <input
                type="text"
                name="phone"
                value={signupFormData.phone}
                onChange={handleInputChange}
                placeholder="Celular"
                required
                minLength={8}
                maxLength={17}
              />
            </IconInputContainer>
            <IconInputContainer active={signupFormData.cpf.length > 0}>
              <UserIcon />
              <input
                type="text"
                name="cpf"
                value={signupFormData.cpf}
                onChange={handleInputChange}
                placeholder="CPF"
                required
                minLength={11}
                maxLength={15}
              />
            </IconInputContainer>
            <IconInputContainer active={signupFormData.born_at.length > 0}>
              <UserIcon />
              <input
                type="text"
                name="born_at"
                value={signupFormData.born_at}
                onChange={handleInputChange}
                placeholder="Data de nascimento"
                required
                minLength={10}
                maxLength={10}
              />
            </IconInputContainer>
            <IconInputContainer active={signupFormData.email.length > 0}>
              <EmailIcon />
              <input
                type="email"
                name="email"
                value={signupFormData.email}
                onChange={handleInputChange}
                placeholder="E-mail"
                required
              />
            </IconInputContainer>
            <IconInputContainer active={signupFormData.password.length > 0}>
              <LockIcon />
              <input
                type="password"
                name="password"
                value={signupFormData.password}
                onChange={handleInputChange}
                placeholder="Senha (mínimo 8 dígitos)"
                minLength={8}
                required
              />
            </IconInputContainer>
            <IconInputContainer
              active={signupFormData.password_confirmation.length > 0}
            >
              <LockIcon />
              <input
                type="password"
                name="password_confirmation"
                value={signupFormData.password_confirmation}
                onChange={handleInputChange}
                minLength={8}
                placeholder="Confirmar senha"
                required
              />
            </IconInputContainer>
            <TermsContainer>
              <input
                id="terms-checkbox"
                type="checkbox"
                checked={signupFormData.accepted_terms}
                onChange={handleAcceptTerms}
              />
              <button type="button" onClick={handleOpenTermsModal}>
                Ler termos.
                {' '}
              </button>
              <label htmlFor="terms-checkbox">
                {' '}
                Li e aceito os termos de uso.
              </label>
            </TermsContainer>
            <SubmitButton type="submit">Finalizar cadastro</SubmitButton>
            <CancelButton onClick={handleGoBack}>Voltar</CancelButton>
          </StyledForm>
        </Content>
      </Container>
      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onCheck={() => {
          handleAcceptTerms({
            target: { checked: !signupFormData.accepted_terms },
          } as any);
          setIsTermsModalOpen(false);
        }}
        isChecked={signupFormData.accepted_terms}
      />
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </>
  );
}
