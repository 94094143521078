import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import PasswordIcon from '../../assets/images/password.svg';
import ProfileIcon from '../../assets/images/profile.svg';
import SupportIcon from '../../assets/images/support.svg';
import TermsIcon from '../../assets/images/terms.svg';

import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { Hexagon } from '../Hexagon';
import { useAuth } from '../../hooks/useAuth';

export default function ManageProfile() {
  const { data } = useAuth();

  return (
    <Container>
      <h2>
        {data?.user?.individual_person.name}
      </h2>
      <div className="manage-profiles__agenda-button-container">
        <NavLink to="/historico">
          <CalendarIcon />
          Ver meu histórico
        </NavLink>
      </div>
      <nav>
        <NavLink to="/perfil/dados-pessoais">
          <Hexagon customSize="3.125rem">
            <img src={ProfileIcon} alt="" />
          </Hexagon>
          Meus dados cadastrais
        </NavLink>
        <NavLink to="/perfil/alterar-senha">
          <Hexagon customSize="3.125rem">
            <img src={PasswordIcon} alt="" />
          </Hexagon>
          Alterar senha
        </NavLink>
        <NavLink to="/suporte">
          <Hexagon customSize="3.125rem">
            <img src={SupportIcon} alt="" />
          </Hexagon>
          Ajuda e suporte
        </NavLink>
        <NavLink to="/termos">
          <Hexagon customSize="3.125rem">
            <img src={TermsIcon} alt="" />
          </Hexagon>
          Termos de uso
        </NavLink>
      </nav>
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  margin: 0 auto;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #292EFF;
    text-align: center;
  }

  .manage-profiles__agenda-button-container {
    margin-top: 1.25rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EFEFFF;
    border-radius: 1.25rem;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      max-width: 18.75rem;
      width: 100%;
      height: 2.5rem;

      border-radius: 1.25rem;
      background-color: #fff;
      color: #292EFF;
      border: none;

      font-size: 1.125rem;
      font-weight: 700;

      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }

      &:link,
      &:visited {
        color: #292EFF;
        text-decoration: none;
      }

      svg {
        height: 1.25rem;
        width: 1.25rem;
        fill: #292EFF;
      }
    }
  }

  nav {
    width: 100%;
    max-width: 20rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.75rem;
    margin-top: 1.75rem;

    a {
      width: 100%;
      display: flex;
      align-items: center;
      height: 3rem;
      font-size: 1.125rem;
      font-weight: 700;
      color: #241DCD;
      background-color: #EFEFFF;
      border-radius: 0.5rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }

      &:active,
      &:link,
      &:visited,
      &:focus {
        text-decoration: none;
        color: #241DCD;
      }

      > div {
        margin-left: -0.5rem;
        margin-right: 1rem;
      }
    }
  }
`;
