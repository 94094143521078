import { useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/images/youtube.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagram.svg';
import TwitterIcon from '../../assets/images/twitter.png';
import LinkedInIcon from '../../assets/images/linkedin.png';
import Placeholder from '../../assets/images/placeholder.svg';
import { useAuth } from '../../hooks/useAuth';
import { useOutside } from '../../hooks/useOutside';
import { ProviderInfoModal } from '../ProviderInfoModal';

export default function HeaderButton() {
  const { data, logoutUser } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProviderInfoModalOpen, setIsProviderInfoModalOpen] = useState(false);
  const history = useHistory();
  const containerRef = useRef<HTMLDivElement>(null);
  const avatarRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    handleClose();
  }, [history.location.pathname]);

  const handleLogout = () => {
    logoutUser();
    history.push('/');
  };

  const handleSignin = () => {
    history.push('/entrar');
  };

  function handleClose() {
    setIsExpanded(false);
  }

  const handleOpenProviderInfoModal = () => {
    setIsProviderInfoModalOpen(true);
  };

  const handleImgError = () => {
    if (avatarRef.current) {
      avatarRef.current.src = Placeholder;
    }
  };

  useOutside(handleClose, containerRef);

  return (
    <Container isExpanded={isExpanded} ref={containerRef}>
      <div>
        <div className="top">
          <img
            src={data?.user?.avatar_url ? data?.user?.avatar_url : Placeholder}
            alt="user avatar"
            ref={avatarRef}
            onError={handleImgError}
          />
          <button type="button" onClick={data?.user?.is_active ? handleLogout : handleSignin}>
            {data?.user?.is_active ? 'Sair' : 'Login'}
          </button>
          <button type="button" className="arrow-button" onClick={() => setIsExpanded((prevState) => !prevState)}>
            <ArrowDown />
          </button>
        </div>
        <div className="dropdown-options">
          <NavLink to="/perfil">Minha conta</NavLink>
          <NavLink to="/sobre/quem-somos">Quem somos</NavLink>
          <NavLink to="/sobre/como-funciona">Como funciona</NavLink>
          <button type="button" onClick={handleOpenProviderInfoModal}>Quero ser um prestador</button>
          <a href="https://www.repfy.com.br/" target="_blank" rel="noreferrer">Visitar nosso site</a>
          <NavLink to="/suporte">Suporte</NavLink>
          <div className="social-network-icons">
            <a href="https://www.youtube.com/channel/UC4QwUrsphMc3oJVuDm_5YDA" target="_blank" rel="noreferrer">
              <YoutubeIcon />
            </a>
            <a href="https://www.facebook.com/repfyapp" target="_blank" rel="noreferrer">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/repfyapp" target="_blank" rel="noreferrer">
              <InstagramIcon />
            </a>
            <a href="https://twitter.com/repfy_app" target="_blank" rel="noreferrer">
              <img src={TwitterIcon} alt="twitter" />
            </a>
            <a href="https://linkedin.com/company/repfy" target="_blank" rel="noreferrer">
              <img src={LinkedInIcon} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
      <ProviderInfoModal
        isOpen={isProviderInfoModalOpen}
        onClose={() => setIsProviderInfoModalOpen(false)}
      />
    </Container>
  );
}

const Container = styled.div<{
  isExpanded: boolean;
}>`
  position: relative;
  min-width: 13rem;
  min-height: 3rem;

  > div:first-child {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(215deg, #1218A8, #090C54);
    border-radius: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    text-align: left;

    padding: 0.125rem 0.75rem;

    transition: filter 0.2s;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.8);

    .top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > img {
        border-radius: 50%;
        border: 3px solid #292EFF;
        height: 2.8rem;
        width: 2.8rem;
        object-fit: cover;
        background-color: #EFEFFF;
      }
    }

    .dropdown-options {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      overflow: hidden;
      max-height: 0px;
      transition: max-height 0.5s, margin 0.5s;

      ${({ isExpanded }) => (isExpanded ? css`
        max-height: 300px;
        margin: 1rem 0 1rem 2rem;
        width: 100%;
      ` : css`
        width: 100%;
      `)}

      a,
      a:visited,
      a:link {
        color: #fff;
        text-decoration: none;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8)
        }
      }

      button {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        text-align:left;
        line-height: 1.1;
      }

      .social-network-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 75%;
        gap: 1.25rem;

        overflow: visible;

        svg {
          width: 2rem;
          height: 2rem;

          vertical-align: middle;

          fill: unset;
          stroke: unset;
          overflow: visible;
        }

        img {
          width: 2rem;
          height: 2rem;

          overflow: visible;
        }

        path {
          overflow: visible;
        }
      }
    }



    button {
      border: none;
      background: transparent;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 500;

      svg {
        height: 0.625rem;
        width: auto;
      }

      &:hover {
        filter: brightness(0.7);
      }
    }

    .arrow-button {
      padding-left: 2rem;
      padding-right: 0.5rem;
      border-left: 2px solid #fff;
      svg {
        transition: transform 0.3s;
        transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : '0deg')});
      }
    }
  }
`;
