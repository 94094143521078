import Modal from 'react-modal';
import styled from 'styled-components';

interface IProviderInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ProviderInfoModal({ isOpen, onClose }: IProviderInfoModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onClose}>X</button>
        <h1>Quero ser um prestador</h1>
        <div className="wrapper">
          <div>

            <p>Nós da REPFY valorizamos nossas parcerias como sendo a alma de todo o negócio.</p>
            <p>
              Caso deseje se cadastrar na plataforma como parceiro prestador de serviços,
              de forma
              {' '}
              <strong>GRATUITA</strong>
              , baixe o aplicativo REPFY PRO, e
              preencha as informações apresentadas na tela de cadastro.
            </p>
            <p>
              É possível também realizar seu pré-cadastro através do nosso site, através do link
              {' '}
              <a href="http://profissional.repfy.com.br" target="_blank" rel="noreferrer">profissional.repfy.com.br</a>
              .
            </p>
            <p>
              Caso ainda tenha dúvidas, acesse nosso site, ou envie nos um e-mail para
              {' '}
              <a href="mailto:contato@repfy.com.br">contato@repfy.com.br</a>
              .
            </p>
          </div>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;
  padding: 3rem;
  padding-right: 2rem;
  max-width: max(30vw, 30rem);

  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  h1 {
    color: #292EFF;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
  }


  .wrapper {
    max-height: 70vh;
    overflow: auto;

    padding-right: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1.25rem;
      background-color: #e4e4ef;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1.25rem;
      background-color: #1117a2;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.5;
  }

  strong {
    font-weight: bold;
  }

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;
