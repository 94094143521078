import styled from 'styled-components';

import Logo from '../../assets/images/logo.png';
import { IServiceType } from '../../types';

import { CategoryIcon } from '../CategoryIcon';
import { Hexagon } from '../Hexagon';
import { LoadingBackground } from '../LoadingBackground';

interface IServiceSplashScreenProps {
  serviceType: IServiceType | undefined;
  onClose: () => void;
}

export default function ServiceSplashScreen({ serviceType, onClose }: IServiceSplashScreenProps) {
  return (
    <LoadingBackground isAnimated={false}>
      <SplashScreenContainer>
        <img src={Logo} alt="logo" />
        <h2>{serviceType?.name || ''}</h2>
        <Hexagon customSize="8rem">
          <CategoryIcon text={serviceType?.name || ''} />
        </Hexagon>
        <p>
          Vamos te fazer algumas
          perguntas e buscar o profissional
          mais perto de você!
        </p>
        <button type="button" onClick={onClose}>
          Pedir orçamento, vamos lá!
        </button>
      </SplashScreenContainer>
    </LoadingBackground>
  );
}

const SplashScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    width: 9rem;
    height: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 900;

    margin-top: 1.25rem;
    margin-bottom: 2.75rem;
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    max-width: 18.75rem;

    margin-top: 3rem;
  }

  button {
    border: none;

    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;

    background: #292EFF;

    min-height: 3.5rem;
    padding: 0 3.75rem;
    margin-top: 3rem;

    border-radius: 2rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
