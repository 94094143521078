import React from 'react';
import {
  DirectionsRenderer, DirectionsService, GoogleMap, OverlayView, useJsApiLoader,
} from '@react-google-maps/api';

import { ReactComponent as MarkerIcon } from '../../assets/images/mapmarker.svg';
import Placeholder from '../../assets/images/placeholder.svg';
import PlaceholderJPG from '../../assets/images/placeholder.jpg';

import { Container } from './styles';

const containerStyle = {
  width: '100%',
  height: '100%',
};

interface IAppMapProps {
  destinationGeo: {
    lat: number;
    lng: number;
  },
  originGeo: {
    lat: number;
    lng: number;
  },
  destinationAddress?: string;
  providerAvatar?: string;
  timeToArrive: string;
  onTimeToArriveChange: (time: string) => void;
}

const libraries: any = ['places'] as any;

function AppMap({
  destinationGeo, originGeo, destinationAddress, providerAvatar, timeToArrive, onTimeToArriveChange,
}: IAppMapProps) {
  const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [map, setMap] = React.useState<null | google.maps.Map>(null);
  const [directionsResponse, setDirectionsResponse] = React.useState<any | null>(null);
  const [geoFromAddress, setGeoFromAddress] = React.useState<any>();

  const onLoad = React.useCallback((m: google.maps.Map) => {
    let newGeo = null as any;
    if (!destinationGeo || !destinationGeo.lat || !destinationGeo.lng) {
      const service = new google.maps.places.PlacesService(m);
      const request: any = {
        fields: ['geometry'],
        query: destinationAddress,
      };
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (
            results !== null
            && results.length > 0
            && results[0].geometry
            && results[0].geometry.location
          ) {
            setGeoFromAddress(results[0].geometry.location);
            newGeo = results[0].geometry.location;
          } else {
            setGeoFromAddress({ lat: -23.5489, lng: -46.6388 });
          }
        }
      });
    }

    const bounds = new window.google.maps.LatLngBounds(
      newGeo !== null ? newGeo : destinationGeo, originGeo,
    );
    m.fitBounds(bounds);
    m.setOptions({
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.neighborhood',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'landscape',
          stylers: [
            {
              color: '#ECECFF',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              weight: 1.5,
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'simplified',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
      streetViewControl: false,
    });
    setMap(m);
  }, []);

  const onUnmount = React.useCallback((m) => {
    setMap(null);
  }, []);

  const directionsCallback = React.useCallback((res) => {
    if (res !== null) {
      setDirectionsResponse(res);
      onTimeToArriveChange(res.routes[0].legs[0].duration.text);
    }
  }, []);

  const directionsServiceOptions = React.useMemo(() => ({
    destination: destinationAddress as string,
    origin: originGeo,
    travelMode: 'DRIVING' as any,
  }), [originGeo, destinationGeo, destinationAddress]);

  const directionsRendererOptions = React.useMemo(() => ({
    directions: directionsResponse,
    markerOptions: {
      visible: false,
    },
    polylineOptions: {
      strokeColor: '#242BF3',
    },
  }), [directionsResponse]);

  return isLoaded ? (
    <Container>
      <span>...</span>
    </Container>
  ) : <></>;
}

export default React.memo(AppMap);
