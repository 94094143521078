import { Switch } from 'react-router-dom';
import {
  EditAccountInformation, EditAddresses, EditEmail, EditName, EditPhone,
} from '../../components/EditAccountInformation';
import PrivateRoute from '../PrivateRoute';

export default function EditProfileRouter() {
  return (
    <Switch>
      <PrivateRoute exact path="/perfil/dados-pessoais" component={EditAccountInformation} />
      <PrivateRoute exact path="/perfil/dados-pessoais/nome" component={EditName} />
      <PrivateRoute exact path="/perfil/dados-pessoais/telefone" component={EditPhone} />
      <PrivateRoute exact path="/perfil/dados-pessoais/email" component={EditEmail} />
      <PrivateRoute exact path="/perfil/dados-pessoais/endereco" component={EditAddresses} />
    </Switch>
  );
}
