import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as CircleAddIcon } from '../../assets/images/circleadd.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/question.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';

import FyCash from '../../assets/images/fy-cash.png';
import Mastercard from '../../assets/images/mastercard.png';
import Visa from '../../assets/images/visa.png';

import { NotificationModal } from '../../components/NotificationModal';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { api } from '../../services/api';
import { AddCardModal } from '../../components/AddCardModal';
import { PaymentInformationModal } from '../../components/PaymentInformationModal';
import { EditMainCardModal } from '../../components/EditMainCardModal';
import { GoBackButton } from '../../components/GoBackButton';
import { IUserCard } from '../../types';

export default function Payment() {
  const history = useHistory();
  const [cards, setCards] = useState<IUserCard[]>([]);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isPaymentInformationModalOpen, setIsPaymentInformationModalOpen] = useState(false);
  const [walletFunds, setWalletFunds] = useState(0);
  const [selectedCard, setSelectedCard] = useState<IUserCard>();
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    type: 'success' as 'success' | 'error',
    title: '',
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
  });
  const [handleConfirmationModal, setHandleConfirmationModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onCancel: () => setHandleConfirmationModal((prevState) => ({ ...prevState, isOpen: false })),
    onConfirm: () => setHandleConfirmationModal((prevState) => ({ ...prevState, isOpen: false })),
  });
  const [isEditMainCardModalOpen, setIsEditMainCardModalOpen] = useState(false);

  useEffect(() => {
    getCards();
  }, []);

  const getCards = async () => {
    const response = await api.get('/cards');
    setCards(response.data.results);
  };

  const goBack = () => {
    history.push('/');
  };

  const handleOpenAddCardModal = () => {
    setIsAddCardModalOpen(true);
  };

  const handleCloseAddCardModal = (id: string) => {
    setIsAddCardModalOpen(false);
    if (id) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: 'Cartão adicionado com sucesso!',
      }));
    }
    getCards();
  };

  const handleOpenEditMainCardModal = () => {
    setIsEditMainCardModalOpen(true);
  };

  const handleCloseEditMainCardModal = () => {
    setIsEditMainCardModalOpen(false);
  };

  const handleUpdateMainCard = async (id: string) => {
    setIsEditMainCardModalOpen(false);
    try {
      const response = await api.patch(`/cards/${id}/set-preferential`);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: 'Alteração realizada com sucesso!',
        type: 'success',
      }));
      getCards();
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: error?.response?.data?.message || error?.message || 'Algo deu errado',
        type: 'error',
      }));
    }
  };

  const handleRemoveCard = (id: string) => {
    setHandleConfirmationModal((prevState) => ({
      ...prevState,
      isOpen: true,
      title: 'Apagar cartão?',
      message: 'Tem certeza que gostaria de apagar este cartão?',
      confirmText: 'Sim',
      cancelText: 'Não',
      onConfirm: () => confirmRemoveCard(id),
    }));
  };

  const confirmRemoveCard = async (id: string) => {
    try {
      await api.delete(`/cards/${id}`);
      setHandleConfirmationModal((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
      getCards();
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || 'Erro ao apagar cartão',
      }));
    }
  };

  return (
    <Container>
      <GoBackButton onClick={goBack} />
      <Content>
        <CardListContainer>
          <h2>Minhas formas de pagamento</h2>
          {cards.map((card) => (
            <div key={card.id}>
              <CardBrandLogo brand={card.card_brand_id} />
              <span className="center-dots">
                ....
              </span>
              {card.last_digits}
              {card.is_preferential && (
              <span style={{ marginLeft: '0.5rem' }}>
                (Meio preferencial)
              </span>
              )}
              <button type="button" className="remove-card-button" onClick={() => handleRemoveCard(card.id)}>
                <TrashIcon />
              </button>
              {/* <ArrowRightIcon /> */}
            </div>
          ))}
          {/* <div>
            <CardBrandLogo brand={999} />
            FY-CASH:
            <p>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency', currency: 'BRL',
              }).format(walletFunds)}
            </p>
            <ArrowRightIcon />
          </div> */}
          <button type="button" onClick={handleOpenAddCardModal}>
            <CircleAddIcon />
            Adicionar nova forma de pagamento
            <ArrowRightIcon />
          </button>
        </CardListContainer>
        <CardInformationContainer>
          {/* <Card>
            <h2>FY-CASH</h2>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency', currency: 'BRL',
            }).format(walletFunds)}
          </Card> */}
          <div>
            <button type="button" onClick={handleOpenEditMainCardModal}>
              <strong>
                Alterar meio de pagamento preferencial
              </strong>
            </button>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <button type="button" onClick={() => setIsPaymentInformationModalOpen(true)} style={{ alignSelf: 'flex-end' }}>
                <QuestionIcon />
              </button>
              <span>Ajuda sobre pagamentos</span>
            </div>
          </div>
        </CardInformationContainer>
      </Content>
      <AddCardModal
        isOpen={isAddCardModalOpen}
        onClose={handleCloseAddCardModal}
        isNotPaying
      />
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        type={handleNotificationModal.type}
        title={handleNotificationModal.title}
        onClose={handleNotificationModal.onClose}
      />
      <PaymentInformationModal
        isOpen={isPaymentInformationModalOpen}
        onClose={() => setIsPaymentInformationModalOpen(false)}
      />
      <ConfirmationModal
        {...handleConfirmationModal}
      />
      <EditMainCardModal
        isOpen={isEditMainCardModalOpen}
        onClose={handleCloseEditMainCardModal}
        onSelect={handleUpdateMainCard}
        cards={cards}
      />
    </Container>
  );
}

function CardBrandLogo({ brand }: { brand: number }) {
  switch (brand) {
    case 1:
      return <img src={Visa} alt="Visa" />;
    case 2:
      return <img src={Mastercard} alt="Mastercard" />;
    default:
      return <img src={FyCash} alt="FyCash" />;
  }
}

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  width: 90%;
  margin: 4rem auto;
`;

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 31.25rem;
  margin: 0 auto;

  h2 {
    color: #241DCD;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    align-items: center;

    min-height: 4.375rem;
    padding: 1rem;

    font-size: 1rem;
    color: #05007A;

    .center-dots {
      color: #05007a;
      font-size: 2.5rem;
      margin-top: -1.25rem;
    }

    > span {
      color: #241DCD;
      margin-right: 0.5rem;
    }

    > img {
      width: 3.4rem;
      height: 2rem;
      object-fit: cover;
      margin-right: 1.75rem;
    }

    button {
      background: transparent;
      border: none;

      transition: filter 0.2s;

      margin-left: auto;

      > svg {
        height: 1.5rem;
        width: 1.5rem;
        stroke: #241DCD;
      }

      > svg {
        path {
          // stroke: #241DCD;
        }
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  > div:nth-child(2n) {
    background-color: #EFEFFF;
    border-radius: 0.5rem;
  }

  > button {
    margin-top: 2rem;
    width: 100%;
    height: 4.375rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #EFEFFF;
    font-size: 1rem;
    font-weight: 700;
    color: #241DCD;
    border-radius: 2.125rem;
    border: none;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    > svg:first-child {
      width: 3rem;
      height: 3rem;
      margin-left: 0.5rem;
    }

    >svg:last-child {
      width: 1rem;
      height: 1rem;
      margin-left: auto;
      margin-right: 1.5rem;
    }
  }
`;

const CardInformationContainer = styled.div`
  margin: 0 auto;
  margin-top: 3.5rem;

  > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 5rem;
    padding: 1rem;

    background-color: #F5F5FA;
    border-radius: 0.5rem;

    button {
      border: none;
      background: transparent;
      color: #241DCD;
      font-size: 0.875rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }

      strong {
        font-weight: 500;
      }

      svg {
        height: 2rem;
        width: 2rem;
      }
    }

    span {
      color: #241DCD;
      font-size: 0.75rem;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;

  color: #fff;

  width: 22.7087rem;
  height: 14.125rem;

  border-radius: 1rem;

  padding: 1.5rem;

  box-shadow: 0px 10px 19px rgba(0, 0, 0, 1);

  background: linear-gradient(45deg, #021793 0%, #000A42 100%);

  justify-content: space-evenly;

  > img {
    width: 4rem;
    height: auto;
    margin-left: auto;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
    opacity: 50%;
  }

  p {
    font-size: 1rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 0.875rem;

      > span:first-of-type {
        opacity: 0.5;
        width: 0.5rem;
        font-size: 0.5rem;
        display:block;
      }
    }
  }
`;
