import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import BackgroundHexagons from '../../assets/images/backgroundhexagons.png';
import Logo from '../../assets/images/logo.png';
import { NotificationModal } from '../../components/NotificationModal';
import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';

export default function Signin() {
  const { loginUser } = useAuth();
  const history = useHistory();
  const [error, setError] = useState('');

  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    type: 'success' as 'error' | 'success',
    title: '',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const [signinFormData, setSigninFormData] = useState({
    email: '',
    password: '',
    remember: true,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await api.post(
        '/sessions',
        {
          email: signinFormData.email.trim(),
          password: signinFormData.password,
        },
        {
          params: {
            role_id: '2',
          },
        },
      );
      if (response?.data && response?.data?.user.role_id === 2) {
        loginUser(response.data, signinFormData.remember);
        history.push('/solicitar/1');
      } else {
        setError('Usuário não autorizado');
      }
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message);

      if (err?.response?.status === 300) {
        history.push('/cadastro/confirmar');
      }

      setError(err?.response?.data?.message || err?.message || 'Algo deu errado');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSigninFormData({ ...signinFormData, [name]: value });
  };

  const handleRemember = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSigninFormData({ ...signinFormData, remember: e.target.checked });
  };

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />
        <h1>Bem-Vindo(a) de volta!</h1>
        <StyledForm onSubmit={handleSubmit}>
          <input
            name="email"
            type="email"
            value={signinFormData.email}
            onChange={handleInputChange}
            placeholder="E-mail"
            required
          />
          <input
            name="password"
            type="password"
            value={signinFormData.password}
            onChange={handleInputChange}
            placeholder="Senha"
            required
          />
          <ForgotPasswordLink to="/alterar-senha">
            Esqueceu sua senha?
          </ForgotPasswordLink>
          <label>
            <input name="remember" type="checkbox" checked={signinFormData.remember} onChange={handleRemember} />
            Salvar dados de login
          </label>
          <button type="submit">Entrar</button>
        </StyledForm>
        {/* <p>
          É novo(a) aqui?
          <SignUpLink to="/cadastro"> Cadastre-se</SignUpLink>
        </p> */}
      </Content>
      <NotificationModal
        {...handleNotificationModal}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  background:  url(${BackgroundHexagons}), linear-gradient(to bottom,#001489 0%, #0b0a45 100%);
  background-position: center;
  background-size: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #ffffff;

  > * + * {
    margin-top: 2rem;
  }

  img {
    height: 2rem;

  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
  }


`;

const SignUpLink = styled(NavLink)`
  text-decoration: none;

  transition: filter 0.2s;

  font-size: 1rem;
  font-weight: 700;
  color: #0007FF;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 98%;

  > input {
    border: none;
    border-radius: 1.25rem;
    background-color: #fff;
    width: 100%;

    height: 3rem;

    font-size: 1rem;

    padding: 0 1.5rem;

    line-height: 3rem;


    &::placeholder {
      color: #ADACAC;
    }
  }

  label {
    display: flex;
    align-items: center;


    input {
      margin-right: 0.5rem;
    }
  }

  button {
    border: none;
    color: #fff;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  button[type="button"] {
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    margin-left: auto;
  }

  button[type="submit"] {
    height: 3rem;
    background-color: #292eff;
    font-size: 1.25rem;
    border-radius: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
  }
`;

const ForgotPasswordLink = styled(NavLink)`
  text-decoration: underline;

  font-size: 1rem;
  font-weight: 500;
  margin-left: auto;

  color: #fff;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
