import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { useAuth } from '../../hooks/useAuth';

import { NotificationModal } from '../../components/NotificationModal';

import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { maskPhoneNumber } from '../../helpers/maskPhoneNumber';
import { api } from '../../services/api';

export default function EditAccountInformation() {
  const history = useHistory();
  const { data, updateUserInfo, updatePhoneNumber } = useAuth();
  const [editFormData, setEditFormData] = useState({
    name: data?.user.individual_person.name,
    email: data?.user.email,
    phone: data?.user.user_phones[0] ? maskPhoneNumber(`${data?.user?.user_phones[0].state_code}${data?.user?.user_phones[0].number}`) : '',
  });
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'phone') {
      setEditFormData((prevState) => ({ ...prevState, phone: maskPhoneNumber(value) }));
    } else {
      setEditFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const userResponse = await api.put('/users', {
        name: editFormData.name,
        email: editFormData.email,
      });
      updateUserInfo(userResponse.data);

      if (data?.user?.user_phones.length > 0) {
        const response = await api.put(`/users/phones/${data?.user?.user_phones[0]?.id}`, {
          state_code: editFormData.phone.replace(/\D/g, '').substring(0, 2),
          number: editFormData.phone.replace(/\D/g, '').substring(2),
        });
        updatePhoneNumber(data?.user?.user_phones[0]?.id, response.data.phone);
      } else {
        const response = await api.post('/users/phones', {
          state_code: editFormData.phone.replace(/\D/g, '').substring(0, 2),
          number: editFormData.phone.replace(/\D/g, '').substring(2),
        });
        updatePhoneNumber(response.data.phone.id, response.data.phone);
      }

      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: 'Dados atualizados com sucesso!',
        type: 'success',
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || 'Algo deu errado na alteração dos dados',
      }));
    }
  };

  if (!data) {
    return null;
  }

  const mainAddress = data.user.addresses.find((address) => address.main);

  const addressToShow = mainAddress?.street ? mainAddress : data.user.addresses[0];

  return (
    <FormContainer onSubmit={handleSubmit}>
      <InformationContainer>
        <div>
          <div>
            <label htmlFor="name">
              Nome:
            </label>
            <input id="name" name="name" type="text" value={editFormData.name} onChange={handleInputChange} disabled />
          </div>
          {/* <button type="button" onClick={() => history.push('/perfil/dados-pessoais/nome')}>
            <EditIcon />
          </button> */}
          {/* <EditIcon /> */}
        </div>
        <div>
          <div>
            <label htmlFor="phone">
              Celular:
            </label>
            <input id="phone" name="phone" type="text" value={editFormData.phone} onChange={handleInputChange} minLength={14} maxLength={15} required />
          </div>
          {/* <button type="button" onClick={() => history.push('/perfil/dados-pessoais/telefone')}>
            <EditIcon />
          </button>
          <EditIcon /> */}
        </div>
        <div>
          <div>
            <label htmlFor="email">
              E-mail:
            </label>
            <input id="email" type="email" name="email" value={editFormData.email} onChange={handleInputChange} required />
          </div>
          {/* <EditIcon />
          <button type="button" onClick={() => history.push('/perfil/dados-pessoais/email')}>
            <EditIcon />
          </button> */}
        </div>
        <div>
          <div>
            <label>Endereço:</label>
            <p>
              {
                addressToShow?.street ? `
                ${addressToShow?.street}, ${addressToShow?.number}, ${addressToShow?.neighborhood}, ${addressToShow?.cep}
              ` : ''
              }
            </p>
          </div>
          <button type="button" onClick={() => history.push('/perfil/dados-pessoais/endereco')}>
            <EditIcon />
          </button>
        </div>
      </InformationContainer>
      <button type="submit">Salvar</button>
      <NotificationModal
        {...handleNotificationModal}
      />
    </FormContainer>
  );
}

const FormContainer = styled.form`
  button[type="submit"] {
    background: #292EFF;
    border: none;
    width: 100%;
    margin: 2rem auto;
    max-width: 28.75rem;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;
    height: 3rem;
    padding: 0 1.5rem;
    border-radius: 1.5rem;
    color: #fff;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const InformationContainer = styled.div`
  background-color: #EFEFFF;
  border-radius: 1rem;

  width: 100%;
  margin: 0 auto;
  max-width: 28.75rem;

  margin-top: 2.25rem;

  padding: 0 1.75rem;

  > div {
    padding: 1.75rem 0;
    border-bottom: 1px solid #868FDB;
    line-height: 1.25;
    display: flex;

    > div:first-child {
      width: 100%;
    }

    input {
      border: none;
      background: transparent;
      outline: none;
      color: #292EFF;
      font-weight: 500;
      font-size: 1.25rem;
      width: 100%;
      flex: 1;

      &:disabled {
        background: transparent;
      }
    }

    label:first-child {
      color: #31409A;
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1.25rem;
      font-weight: 500;
      color: #292EFF;
    }

    svg {
      height: 2.375rem;
      width: 2.375rem;
      margin-left: auto;
    }

    button {
      background: transparent;
      border: none;
      margin-left: auto;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.3);
      }

      cursor: pointer;


    }
  }

  > div:last-of-type {
    border-bottom: 0;
  }
`;
