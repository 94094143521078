import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
`;

export const GoBackButton = styled(NavLink)`
  position: absolute;

  top: 1rem;
  left: -2.5rem;

  padding: 0.5rem;

  text-decoration: none;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 600px) {
    position: unset;

    display: block;
    width: fit-content;
  }
`;

export const StyledHeader = styled.header`
  background-color: #081564;
  width: 100%;
  height: 6.75rem;
  display: flex;
  align-items: center;

  margin-top: -2rem;
  margin-bottom: 2rem;

  > div {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  h2 {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 600;
  }
`;

export const PaymentQuestion = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.25rem;
  text-align:right;
  max-width: 14rem;

  color: #241DCD;
  font-weight: 400;

  .payment-information-button {
    background: transparent;
    border: none;
    display: block;
    margin-bottom: -0.5rem;

    > svg {
      overflow: visible;
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const PaymentContainer = styled.div`
  border-radius: 0.5rem;

  padding: 1.375rem 1rem;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.27);

  background-color: #fff;

  span {
    font-size: 1.25rem;
    font-weight: 400;
    color: #241DCD;
  }

  > * + * {
    margin-top: 1.5rem;
  }

  .request-service__value-container {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;

      > span:not(:first-child) {
        margin-top: 0.5rem;
        text-decoration-line: line-through;
        color: #2C2C2C60;
        font-size: 1rem;
        margin-left: auto;
      }
    }
  }

  .request-service__add-payment-container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      font-size: 0.875rem;
      font-weight: 400;
      max-width: 10rem;

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      cursor: pointer;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .request-service__card-information-container {
    img {
      width: 2rem;
      height: 1.5rem;
    }
    margin-bottom: 0.25rem;
  }

  .request-service__add-card-button {
    display: flex;
    align-items: center;

    text-align: left;

    border: none;

    background-color: transparent;
    color: #241DCD;

    font-size: 0.875rem;
    font-weight: 400;

    border-radius: unset;
    box-shadow: unset;
    padding: unset;

    cursor: pointer;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    > svg {
      margin-right: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      vertical-align: middle;
    }
  }


`;

export const AppointmentDateContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(3, 1fr);

  grid-gap: 1rem;
`;

export const InformationContainer = styled.div`
  background-color: #EFEFFF;
  border-radius: 1.25rem;

  padding: 2rem;

  line-height: 1.25;

  h3 {
    color: #241DCD;
    font-size: 1.5rem;
    font-weight: 500;
  }

  p {
    font-size: 1.5rem;
    font-weight: 300;
    color: #001383;

    margin-top: 1.5rem;

    a,
    a:visited {
      text-decoration: none;
      color: inherit;
    }

    strong {
      font-weight: bold;
    }
  }
`;

export const StyledForm = styled.form`
  background-color: #F5F5FA;
  border-radius: 1.5rem;

  display: flex;
  flex-direction: column;

  padding: 2.5rem 3.5rem;

  margin-top: 1.75rem;

  max-width: 41rem;

  label {
    display: flex;
    align-items: center;

    font-size: 1.5rem;
    font-weight: 700;
    color: #241DCD;

    margin-bottom: 1.5rem;

    svg, path {
      overflow: visible;
    }

    svg {
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
    }

    &.address-select-label {
      svg, path {
        fill: #292eff;
      }
    }

    &.request-service__photo-upload-label {
      color: #fff;
      font-size: 1.25rem;
      font-weight: 500;
      min-width: 15rem;
      width: fit-content;
      cursor:pointer;

      input[type="file"]{
        display: none;
      }
    }
  }

  .request-service__address-wrapper {
    display: flex;
    align-items: center;

    > button {
      display: flex;
      align-items: center;
      background: transparent;
      border: none;

      > svg {
        margin-right: 1rem;
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }

  .request-service__input {
    border: none;

    min-width: 0;
    min-height: 3rem;
    width: 100%;

    padding: 0.875rem 2.5rem 0.875rem 1.625rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    font-size: 1.25rem;

    color: #241DCD;
  }

  .request-service__product-photo {
    max-height: 25rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  > * + * {
    margin-top: 2rem;
  }

`;

export const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;

  .code-input-container {
    display: flex;
    gap: 1.5rem;
  }

  > label > svg {
    width: 2rem;
    height: 2rem;
  }

  > p {
    color: #626262;
    font-weight: 300;
    margin-top: 1rem;
  }

  input {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    font-size: 1.25rem;
    min-height: 3rem;
    padding: 1rem;

    color: #241DCD;

    &::placeholder {
      color: #241DCD;
    }
  }

  button {
    background: transparent;
    border: none;

    font-size: 1.25rem;
    font-weight: 700;
    color: #241DCD;

    &:disabled {
      color: #9F9F9F;

      cursor: not-allowed;

      &:hover {
        filter: unset;
      }
    }
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 1rem;

  > div {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.25rem;
      color: rgba(44, 44, 44, 0.69);
      font-weight: 700;
      line-height: 1.15;
      margin-bottom: 0.5rem;
    }

    p {
      line-height: 1.15;
      font-size: 1rem;
      color: rgba(44, 44, 44, 0.69);
    }

  }

  label {
    font-size: 1rem;
    cursor: pointer;

    align-self: flex-end;

    margin-bottom: 0;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  input[type="file"] {
    display: none;
  }
`;

export const RequestServiceButton = styled.button`

  background-color: #241DCD;
  color: #fff;
  border-radius: 2.25rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-size: 1.25rem;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 15rem;

  border: none;

  height: 3.5rem;

  padding: 0 1.25rem;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  svg {
    width: 2rem;
    height: 2rem;

    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
