import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Logo from '../../assets/images/logo.png';
import { ReactComponent as Payment } from '../../assets/images/payment.svg';
import { ReactComponent as Profile } from '../../assets/images/profile.svg';
import { ReactComponent as History } from '../../assets/images/history.svg';
import { ReactComponent as Support } from '../../assets/images/support.svg';
import { useAuth } from '../../hooks/useAuth';
import { UnauthorizedModal } from '../UnauthorizedModal';

export default function NavBar() {
  const { data } = useAuth();
  const history = useHistory();
  const [isUnauthorizedModalOpen, setIsUnauthorizedModalOpen] = useState(false);

  const handlePrivateRoute = (path: string) => {
    if (data?.access_token) {
      history.push(path);
    } else {
      handleOpenUnauthorizedModal();
    }
  };

  const handleCloseUnauthorizedModal = () => {
    setIsUnauthorizedModalOpen(false);
  };

  const handleOpenUnauthorizedModal = () => {
    setIsUnauthorizedModalOpen(true);
  };

  return (
    <Container>
      <NavButton as={NavLink} to="/" className="aside-logo" onClick={() => handlePrivateRoute('/cliente/solicitar/1')}>
        <img src={Logo} alt="logo" />
      </NavButton>
      <NavButton onClick={() => handlePrivateRoute('/pagamento')}>
        <Payment />
        <span>Pagamento</span>
      </NavButton>
      <NavButton onClick={() => handlePrivateRoute('/perfil')}>
        <Profile />
        <span>Perfil</span>
      </NavButton>
      <NavButton as={NavLink} to="/" className="footer-logo">
        <img src={Logo} alt="logo" />
      </NavButton>
      <NavButton onClick={() => handlePrivateRoute('/perfil/agenda')}>
        <History />
        <span>Agenda</span>
      </NavButton>
      <NavButton as={NavLink} to="/suporte">
        <Support />
        <span>Suporte</span>
      </NavButton>
      <UnauthorizedModal
        isOpen={isUnauthorizedModalOpen}
        onClose={handleCloseUnauthorizedModal}
      />
    </Container>
  );
}

const Container = styled.nav`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-height: 29.5rem;
  height: 80vh;

  padding: 3rem 0;

  .aside-logo {
    > img {
      width: 70%;
    }

    @media(max-width: 601px) {
      display: none;
      visibility: hidden;
    }
  }

  .footer-logo {
    width: 20%;
    > img {
      width: 100%;
    }

    @media(min-width: 601px) {
      display: none;
    }
  }

  @media(max-width: 601px) {
    flex-direction: row;
    align-items: center;
    width: 98%;
  }
`;

const NavButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;

  font-size: 1rem;
  font-weight: 700;

  text-decoration: none;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  span {
    margin-top: 0.5rem;
    color: #fff;
  }

  svg {

    width: 2.625rem;
    height: 2.625rem;
  }
`;
