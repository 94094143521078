import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { ReactComponent as StarIcon } from '../../assets/images/star.svg';
import { GoBackButton } from '../../components/GoBackButton';
import { api } from '../../services/api';

interface IQuestion {
  id: string;
  title: string;
  content: string;
}

export default function Faq() {
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [filter, setFilter] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | undefined>();
  const history = useHistory();

  useEffect(() => {
    getQuestions();
  }, []);

  const filteredQuestions = questions.filter(
    (question) => question.title.toLowerCase().includes(filter.toLowerCase()),
  );

  const handleToggleQuestion = (index: number) => {
    if (selectedQuestion || index === -1) {
      setSelectedQuestion(undefined);
    } else {
      setSelectedQuestion(filteredQuestions[index]);
    }
  };

  const getQuestions = async () => {
    const response = await api.get('/system-infos/facs');
    setQuestions(response.data.facs);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    if (selectedQuestion) {
      setSelectedQuestion(undefined);
    }
  };

  const goBack = () => {
    if (selectedQuestion) {
      setSelectedQuestion(undefined);
    } else {
      history.goBack();
    }
  };

  return (
    <Container>
      <GoBackButton onClick={goBack} />
      <Content>
        <QuestionsContainer>
          <SearchBox>
            <input type="text" placeholder="Buscar pergunta..." value={filter} onChange={handleFilterChange} />
            <SearchIcon />
          </SearchBox>
          {selectedQuestion ? (
            <>
              <Question type="button" onClick={() => handleToggleQuestion(-1)}>
                <StarIcon />
                <h2>{selectedQuestion.title}</h2>
              </Question>
              <p>{selectedQuestion.content}</p>
            </>
          ) : (
            filteredQuestions.map((question, index) => (
              <Question type="button" key={question.id} onClick={() => handleToggleQuestion(index)}>
                <StarIcon />
                <h2>{question.title}</h2>
              </Question>
            ))
          )}
        </QuestionsContainer>
        <h2>
          Sua dúvida não está aqui?
          Entre em contato com nosso
          {' '}
          <NavLink to="/suporte">suporte</NavLink>
        </h2>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 2rem;
  width: 90%;
  margin: 4rem auto;

  > h2 {
    position: absolute;
    left: 70%;
    top: 7.5rem;
    max-width: 20rem;
    min-width: 10rem;

    font-size: 1.5rem;
    font-weight: 500;
    color: #241DCD;
    white-space: pre-line;

    a {
      font-weight: 700;
      text-decoration: none;
      color: #241DCD;

      &:visited,
      &:active,
      &:link {
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1250px) {
    > h2 {
      position: relative;
      left: unset;
      top: unset;
      max-width: 100%;
    }
  }
`;

const SearchBox = styled.div`
  width: 100%;

  font-size: 1.75rem;
  height: 4.5rem;
  border-radius: 2.25rem;
  padding: 0 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  box-shadow: 0px 3px 6px #20048126;

  input {
    color: #001489;
    border: none;
    outline: none;
    font-size: 1.75rem;
    flex: 1;
    min-width: 0;

    &::placeholder {
      color: #001489;
    }
  }

  svg {
    fill: #292EFF;
    margin-top: -0.75rem;
    height: 3.5rem;
    width: 3.5rem;
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 38.5rem;

  gap: 2rem;

  p {
    word-wrap: break-word;
    font-size: 1rem;
    color: #202125;
    line-height: 1.5;
    max-width: 100vw;

    white-space: pre-line;
    text-align:justify;
    text-justify: distribute;
  }
`;

const Question = styled.button`
  border: none;
  text-align: left;

  display: flex;
  width: 100%;
  max-width: 100vw;
  align-items: center;

  background-color: #EFEFFF;
  border-radius: 0.5rem;
  min-height: 6.9375rem;

  padding: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #241DCD;
    word-wrap: break-word;
    min-width: 0;
  }

  svg {
    min-height: 4rem;
    min-width: 4rem;
    height: 4rem;
    width: 4rem;
    margin-right: 0.75rem;
  }
`;
