import React, { useState } from 'react';
import Modal from 'react-modal';

import styled from 'styled-components';

import { useAuth } from '../../hooks/useAuth';

import VisaLogo from '../../assets/images/visa.png';
import MastercardLogo from '../../assets/images/mastercard.png';

import { Hexagon } from '../Hexagon';
import { NotificationModal } from '../NotificationModal';
import { api } from '../../services/api';

interface IAddCardModalProps {
  isOpen: boolean;
  onClose: (id: string) => void;
  isNotPaying?: boolean;
}

const maskExpirationDate = (value: string) => {
  const newValue = value
    .replace(/[^0-9/]/g, '')
    .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
    .replace(/^(\d{1})\//, '0$1/')
    .replace(/[/]/g, (match, offset, all) => {
      if (match === '/') {
        return (all.indexOf('/') === offset) ? '/' : '';
      }
      return '';
    });

  return newValue;
};

const maskCardNumber = (cardNumber: string) => {
  let fullCardNumber = cardNumber.replace(/\D/g, '');
  if (cardNumber.length < 16) {
    fullCardNumber = fullCardNumber.padEnd(16, 'X');
  }
  const places = fullCardNumber.match(/.{1,4}/g);
  return places?.join(' ');
};

const maskInputCardNumber = (cardNumber: string) => {
  // will add space after every 4 digits to cardNumber
  const newCardNumber = cardNumber.replace(/\D/g, '').replace(/\d{4}(?=.)/g, '$& ');
  return newCardNumber;
};

const getCardBrand = (cardNumber: string) => {
  const cards = {
    visa: /^4[0-9]{12}(?:[0-9]{3})/,
    mastercard: /^5[1-5][0-9]{14}/,
  } as {[key: string]: RegExp};

  let brand = '';

  Object.keys(cards).forEach((card) => {
    if (cards[card].test(cardNumber.replace(/\D/g, ''))) {
      brand = card;
    }
  });

  switch (brand) {
    case 'visa':
      return VisaLogo;
    case 'mastercard':
      return MastercardLogo;
    default:
      return VisaLogo;
  }
};

const initialFormData = {
  name: '',
  cardNumber: '',
  securityCode: '',
  validThrough: '',
};

export default function AddCardModal({ isOpen, onClose, isNotPaying }: IAddCardModalProps) {
  const { data } = useAuth();
  const [addCardFormData, setAddCardFormData] = useState(
    initialFormData,
  );
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'validThrough') {
      setAddCardFormData({ ...addCardFormData, validThrough: maskExpirationDate(value) });
    } else if (name === 'cardNumber') {
      setAddCardFormData({ ...addCardFormData, cardNumber: maskInputCardNumber(value) as string });
    } else if (name === 'securityCode') {
      setAddCardFormData({ ...addCardFormData, securityCode: value.replace(/\D/g, '') });
    } else {
      setAddCardFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleClose = () => {
    onClose('');
    setAddCardFormData(initialFormData);
  };

  const cardBrandLogo = getCardBrand(addCardFormData.cardNumber);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const headers = {
        Authorization: `Bearer ${data?.access_token}`,
      };

      const expiration_date_year = addCardFormData.validThrough.slice(3);
      const expiration_date_month = addCardFormData.validThrough.slice(0, 2);

      const cardData = {
        expiration_date_month,
        expiration_date_year,
        cvv: addCardFormData.securityCode,
        brand_id: getCardBrand(addCardFormData.cardNumber) === VisaLogo ? 1 : 2,
        card_number: addCardFormData.cardNumber.replace(/[^0-9]/g, ''),
        // last_digits: addCardFormData.cardNumber.replace(/[^0-9]/g, '').slice(-4),
        // card_id:
        // `${addCardFormData.cardNumber.slice(-4)}${Math.random().toString().replace(
        //  /[^0-9]/g, ''
        // ).slice(-4)}${expiration_date_year}${expiration_date_month}`,
        financial_provider_id: 1,
        card_type_id: 1,
      };

      const expirationYearNumber = Number(expiration_date_year);
      const expirationMonthNumber = Number(expiration_date_month);
      const currentYearNumber = new Date().getFullYear() - 2000;
      const currentMonthNumber = new Date().getMonth() + 1;

      if (expirationYearNumber < currentYearNumber) {
        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: 'Cartão expirado',
          type: 'error',
        }));
        return;
      }
      if (expirationYearNumber === currentYearNumber
        && expirationMonthNumber < currentMonthNumber) {
        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          title: 'Cartão expirado',
          type: 'error',
        }));
        return;
      }

      const response = await api.post('/cards', cardData, { headers });
      if (response) {
        onClose(response.data.id);
        setAddCardFormData(initialFormData);
      }
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response ? error?.response.data.message : error?.message,
      }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={handleClose}>X</button>
        <StyledForm onSubmit={handleSubmit}>
          <h1>Adicione seu cartão</h1>
          <input type="text" name="name" value={addCardFormData.name} onChange={handleInputChange} placeholder="Nome do Titular" required />
          <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
            <input type="text" name="cardNumber" value={addCardFormData.cardNumber} onChange={handleInputChange} placeholder="Número do cartão" minLength={19} maxLength={19} required />
            <input type="text" name="securityCode" value={addCardFormData.securityCode} onChange={handleInputChange} placeholder="CVV" style={{ width: '8rem' }} minLength={3} maxLength={3} required />
          </div>
          <input type="text" name="validThrough" value={addCardFormData.validThrough} onChange={handleInputChange} placeholder="Mês e ano - vencimento" required minLength={5} maxLength={5} />
          <button type="submit">
            {
              isNotPaying ? 'Salvar cartão' : 'Salvar cartão e pagar'
            }
          </button>
        </StyledForm>
        <CardContainer>
          <Hexagon customSize="20rem">{' '}</Hexagon>
          <Card>
            <img src={cardBrandLogo} alt="card brand" />
            <h2>
              {maskCardNumber(addCardFormData.cardNumber) || 'XXXX XXXX XXXX XXXX'}
            </h2>
            <h3>
              {addCardFormData.securityCode || 'CVV'}
            </h3>
            <div>
              <p>
                {addCardFormData.name || 'Nome do titular'}
              </p>
              <div>
                <span>
                  Valid thru
                </span>
                <span>
                  {`${addCardFormData.validThrough}` || 'MM/AA'}
                </span>
              </div>
            </div>
          </Card>
        </CardContainer>
      </Container>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  max-width: 67.5rem;

  position: relative;

  padding: 3rem;

  background-color: white;

  border-radius: 1rem;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);


  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const Card = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 5rem;

  display: flex;
  flex-direction: column;

  color: #fff;

  width: 22.7087rem;
  height: 14.125rem;

  border-radius: 1rem;

  padding: 1.5rem;

  box-shadow: 0px 10px 19px rgba(0, 0, 0, 1);

  background: linear-gradient(45deg, #021793 0%, #000A42 100%);

  justify-content: space-evenly;

  > img {
    width: 4rem;
    height: auto;
    margin-left: auto;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.125rem;
    opacity: 50%;
  }

  p {
    font-size: 1rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      gap: 0.875rem;

      > span:first-of-type {
        opacity: 0.5;
        width: 0.5rem;
        font-size: 0.5rem;
        display:block;
      }
    }
  }
`;

const CardContainer = styled.div`
  position: relative;

  padding-right: 6rem;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  max-width: 28rem;

  > * + * {
    margin-top: 3rem;
  }

  > h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #292eff;
  }

  input {
    border: none;

    min-width: 0;

    font-size: 1.5rem;
    font-weight: 500;

    height: 3.75rem;

    padding: 0 1.75rem;

    border-radius: 1rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  button[type="submit"] {
    border: none;
    background-color: #292eff;

    color: #fff;

    font-size: 1.75rem;
    font-weight: 500;

    border-radius: 1rem;

    height: 4rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

  }


`;
