import styled, { css } from 'styled-components';

export const Container = styled.div<{
  iconColor?: string;
  hasNewNotifications: boolean;
}>`
  position: relative;
  z-index: 999;

  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;

    > svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 2rem;

      > path {
        fill: ${({ iconColor }) => `${iconColor}` || '#081564'};
        overflow: visible;
      }
    }
    ${({ hasNewNotifications, iconColor }) => (hasNewNotifications
    ? css`
            &::after {
              content: "";
              position: absolute;
              top: -0.25rem;
              left: 0.75rem;

              width: 0.75rem;
              height: 0.75rem;

              background-color: ${iconColor};

              border-radius: 50%;
            }
          `
    : '')}
  }
`;

export const NotificationsWrapper = styled.div<{
  isExpanded: boolean;
}>`
  position: absolute;
  width: 19.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-105%, 7.5%);
  background: #fff;
  border-radius: 1rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  transition: all 0.3s ease 0s;

  ${({ isExpanded }) => isExpanded
    && css`
      padding: 1rem 0.5rem 1rem 0.75rem;
      z-index: 3;
    `}

  @media (max-width: 600px) {
    transform: translate(-50%, 85%);
  }
`;

export const NotificationsList = styled.div<{
  isExpanded: boolean;
}>`
  max-height: 0px;

  width: 100%;
  max-width: 98vw;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  overflow: auto;

  font-family: "Axiforma", sans-serif;

  ${({ isExpanded }) => isExpanded
    && css`
      max-height: 40vh;
      overflow: auto;
      padding-right: 0.25rem;
    `}

  scrollbar-width: thin;
  scrollbar-color: #241dcd #cfcfff;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: #cfcfff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: #241dcd;
  }

  > a {
    text-decoration: none;
    border-radius: 0.875rem;
    padding: 0.875rem;
    background: #efefff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    line-height: 1.5;
    width: 100%;

    color: #241dcd;

  }

  .notification-bell__prompt-text {
    font-size: 1rem;
    color: #adacac;
    line-height: 1.75;
    padding: 0 0.25rem;
  }

  .notification-bell__prompt-button {
    background: transparent;
    border: none;
    margin: 0 auto;
    padding: 0 1rem;
    color: #241dcd;
    font-size: 1rem;
    font-weight: 500;

    &::after {
      display: none;
    }
  }
`;

export const NotificationHeading = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
`;

export const NotificationContent = styled.p`
  font-size: 0.75rem;
  color: #001383;
`;

export const NotificationTime = styled.time`
  font-size: 0.625rem;
  color: #001383;

  margin-top: 0.25rem;
`;
