import styled from 'styled-components';

export const StyledForm = styled.form`
  margin: 2.25rem auto;
  background-color: #EFEFFF;
  border-radius: 2rem;
  padding: 2rem 3rem;

  width: 100%;
  max-width: 31.25rem;

  display: flex;
  flex-direction: column;
  gap: 1.75rem;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #021793;
  }

  label {
    font-size: 1.5rem;
    font-weight: 500;

    div {
      display: flex;
      align-items: center;
      background-color:#fff;
      border-radius: 2rem;
      height: 4rem;
      padding: 0 1.5rem;

      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      svg {
        height: 2rem;
        width: 2rem;
        margin-right: 0.5rem;
      }

      path {
        fill: #CDCDCD;
      }

      input {
        width: 90%;
        outline: none;
        border: none;
        color: #222;
        font-size: 1.25rem;
        font-weight: 400;
        background-color: transparent;

        &::placeholder {
          color: #ADACAC;
        }
      }
    }
  }

  button {
    border: none;
    border-radius: 2rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    color: #fff;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-size: 1.375rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  button[type="submit"] {
    background-color: #292EFF;
  }

  button.edit-address__delete-button {
    background-color: #fc0302;
  }
`;

export const AddressListContainer = styled.div`
  background: #EFEFFF;
  border-radius: 0.75rem;

  width: 100%;
  max-width: 31.25rem;
  margin: 2rem auto;

  padding: 1rem 1.5rem;

  line-height: 1.25;

  > div {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #021793;

    h3 {
      color: #021793;
      font-size: 1.25rem;
      font-weight: 700;
    }

    p {
      color: #001383;
      font-weight: 400;
      font-size: 1rem;
    }

    button {
      background: transparent;
      border: none;
      margin-left: auto;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(1.2);
      }

      svg {
        height: 2.375rem;
        width: 2.375rem;
      }
    }
  }

  > div:last-child {
    border: none;
  }


`;
