import { useHistory } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { ReactComponent as Notification } from '../../assets/images/notification.svg';

import { NavigationTitles } from '../../resources/NavigationTitles';

import { HeaderButton } from '../HeaderButton';
import { NotificationBell } from '../NotificationBell';

export default function Header() {
  const history = useHistory();

  const pathParams = history.location.pathname.split('/');

  const parentPath = pathParams[1] ? pathParams[1] : 'home';

  return (
    <Container headerBackgroundColor={NavigationTitles[parentPath]?.headerBackgroundColor}>
      <Content>
        <NavigationTitleContainer iconColor={NavigationTitles[parentPath]?.iconColor}>
          {NavigationTitles[parentPath] ? NavigationTitles[parentPath]?.icon : null}
          <h1>
            {' '}
            {NavigationTitles[parentPath] ? NavigationTitles[parentPath]?.text : 'Home' }
            {' '}
          </h1>
        </NavigationTitleContainer>
        <UserInfoContainer iconColor={NavigationTitles[parentPath]?.iconColor}>
          <NotificationBell iconColor={NavigationTitles[parentPath]?.iconColor} />
          <HeaderButton />
        </UserInfoContainer>
      </Content>
    </Container>
  );
}

const Container = styled.header<{
  headerBackgroundColor?: 'darkBlue' | 'lightBlue';
  iconColor?: string;
}>`
  --darkBlue: #081564;
  --lightBlue: #efefff;

  display: flex;
  align-items: center;
  width: 100%;
  min-height: 6.75rem;
  background-color: var(--lightBlue);

  ${({ headerBackgroundColor, iconColor }) => headerBackgroundColor === 'darkBlue' && css`
      background-color: var(--darkBlue);
      color: ${iconColor};
    `
}
`;

const Content = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`;

const NavigationTitleContainer = styled.div<{
  iconColor?: string;
}>`
  display: flex;
  align-items: center;
  height: 100%;

  h1 {
    font-size: 2rem;
    color: #081564;
  }

  svg {
    margin-right: 1.25rem;
    width: 2rem;
    height: 2rem;
  }

  path {
    fill: #081564;
  }

  ${({ iconColor }) => iconColor && css`
    path {
      fill: ${iconColor};
    }

    h1 {
      color: ${iconColor};
    }
  `}
`;

const UserInfoContainer = styled.div<{
  iconColor?: string;
}>`
  display: flex;
  align-items: center;

  > svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 2rem;

    > path {
      fill: ${({ iconColor }) => (`${iconColor}` || '#081564')};
      overflow: visible;
    }
  }
`;
