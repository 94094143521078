import styled, { css } from 'styled-components';

import HexagonImg from '../../assets/images/hexagon.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .home__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    width: 90%;
    margin: 1rem auto;
  }

  .home__header__left-wrapper {
    display: flex;

    justify-content: space-between;
    flex: 1;

    flex-wrap: wrap;
    gap: 1.25rem;
  }

  .home__welcome-container {

    h2 {
      font-size: 2rem;
      color: #252731;

      span {
        color: #292EFF;
        font-weight: 900;
      }
    }

    p {
      margin-top: 1rem;
      color: #7C7D83;
      font-size: 1rem;
      line-height: 1.5;
      max-width: 22.5rem;
    }
  }

  .home__location-container {
    background-color: #F5F5FA;
    border-radius: 0.5rem;
    min-height: 3.5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0 1.875rem;

    span {
      color: #6A65DE;
      font-size: 1rem;
      word-wrap: break-word;
    }

    svg {
      height: 1.5rem;
      width: auto;
      margin-right: 0.5rem;
    }
  }

  .home__content {
    position: relative;
    background-color: #081564;
    width: 100%;
    flex-grow: 1;
    height: 100%;

    overflow: hidden;

    border-top-right-radius: 4rem;

    margin-top: 1rem;

    padding: 2rem 0;


    color: #fFf;

    z-index: 1;

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      max-width: 22rem;
      line-height: 1.75;
      margin-top: 4rem;
    }

    > * {
      width: 90%;
      margin: 0 auto;
    }
  }

  .home__content-top {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: '';
      position: absolute;
      bottom: -45%;
      left: -2.5rem;
      width: 20rem;
      height: 20rem;

      z-index: -1;

      background-image: url(${HexagonImg});
      background-size: cover;

      transform: rotate(45deg);
    }
  }

  .home__categories-container {
    padding-bottom: 8rem;
    margin-top: 2rem;
    margin-right: 6rem;
  }

  .home__banners-container{
    position: relative;
    max-width: 1080px;
    margin-top: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home__banner {
    height: auto;
    width: 100%;
  }

  @media(max-width: 600px) {
    .home__content {
      margin-bottom: -10rem;
      padding-bottom: 10rem;
    }
  }

  @media (max-width: 1000px) {
    .home__content-top {
      justify-content: center;
      text-align: center;
      h2 {
        max-width: unset;
      }
    }

    .home__categories-container {
      margin-right: 0;
      margin-top: 3rem;
    }
  }
`;

export const HexagonGridA = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: fit-content;
  height: 4.25rem;

  > * + * {
    margin-left: 5rem;
  }
`;

export const HexagonGridB = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  width: fit-content;
  height: 4.25rem;
  margin-left: 7rem;

  > * + * {
    margin-left: 5rem;
  }
`;

export const HexagonWrap = styled.div<{
  active?: boolean;
}>`
  position: relative;
  transition: all 0.2s;

  ${(props) => (!props.active
    ? css`
          filter: brightness(0.7);

        `
    : css`
          filter: drop-shadow(0 0.5rem 3rem rgba(0, 0, 0, 0.8));
          cursor: pointer;

          &:hover {
            filter: drop-shadow(0 0.5rem 3rem rgba(0, 0, 0, 0.8)) brightness(1.1);
          }
        `)}
`;

export const HexagonCaption = styled.div<{
  top: boolean;
}>`
  font-size: 0.7rem;
  font-weight: 500;
  color: #fff;

  text-align: center;
  width: 100%;
  max-width: 7rem;
  min-height: 3rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  position: absolute;

  left: 1rem;

  ${(props) => (props.top
    ? css`
          top: -3.25rem;
          justify-content: flex-end;
        `
    : css`
          bottom: -3rem;
          justify-content: flex-start;
        `)}

  > p {
    max-width: 6rem;
    // word-break: break-word;
  }
`;

export const Hexagon = styled.div<{
  active?: boolean;
}>`
  width: 9rem;
  height: 9rem;
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  background-color: #292eff;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    overflow: visible;
    pointer-events: none;
  }
`;
