import { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuth } from '../../hooks/useAuth';

import { ReactComponent as Marker } from '../../assets/images/marker.svg';
import { api } from '../../services/api';
import {
  Container,
  Hexagon,
  HexagonCaption,
  HexagonGridA,
  HexagonGridB,
  HexagonWrap,
} from './styles';
import { UnauthorizedModal } from '../../components/UnauthorizedModal';
import { NewAppointmentModal } from '../../components/NewAppointmentModal';
import { CategoryIcon } from '../../components/CategoryIcon';

import { AppointmentContext, IClientSolicitationAccepted } from '../../contexts/AppointmentContext';
import { ServiceAppointment } from '../../components/ServiceAppointment';
import { IAddress } from '../../types';
import { MobileLinks } from '../../components/MobileLinks';
import SearchingProvider from '../../components/SearchingProvider/SearchingProvider';
import { useAppointments } from '../../hooks/useAppointments';

interface ICategory {
  id: number;
  order: number;
  is_active: boolean;
  icon: string;
  name: string;
}

export interface IBanner {
  id: string;
  order: number;
  link: string;
  filename: string;
  created_at: string;
  url: string;
}

export default function Home() {
  const { data } = useAuth();
  const history = useHistory();
  const [categories, setCategories] = useState<ICategory[]>([] as ICategory[]);
  const [banners, setBanners] = useState<IBanner[]>([] as IBanner[]);
  const [userAddress, setUserAddress] = useState<IAddress>();
  const [isUnauthorizedModalOpen, setIsUnauthorizedModalOpen] = useState(false);

  const {
    requestedSolicitation,
    clearNewAppointment,
    isSearchingProviderOpen,
  } = useAppointments();

  useEffect(() => {
    ensureCategories();
  }, []);

  useEffect(() => {
    getBanners();
  }, []);

  useEffect(() => {
    if (data?.user?.is_active) {
      getUserAddress();
    }
  }, [data]);

  const ensureCategories = async () => {
    const response = await api.get('/categories');
    const categoriesFromApi = response.data.categories[0]
      .service_types as ICategory[];

    // Remove this when the API is ready
    const newCategories = categoriesFromApi
      .filter((category) => category.name !== 'atualizado')
      .sort((a, b) => a.order - b.order)
      .map((category) => {
        if (['montador de móveis'].includes(category.name.toLowerCase())) {
          return {
            ...category,
            is_active: true,
          };
        }
        return category;
      });

    setCategories(newCategories);
  };

  const getBanners = async () => {
    try {
      const response = await api.get('/system-infos/banners');
      setBanners(response.data.banners as IBanner[]);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const getUserAddress = async () => {
    const headers = {
      Authorization: `Bearer ${data?.access_token}`,
    };
    try {
      const response = await api.get('/addresses', { headers });
      if (response.data.results.length > 0) {
        setUserAddress(
          response.data.results.find((address: IAddress) => address.main)
            || response.data.results[0],
        );
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const handleCategoryClick = (index: number) => {
    if (!data?.user?.is_active) {
      setIsUnauthorizedModalOpen(true);
      return;
    }
    if (categories[index].is_active) {
      history.push(`/solicitar/${categories[index].id}`);
    }
  };

  return (
    <Container>
      <div className="home__header">
        <div className="home__header__left-wrapper">
          <div className="home__welcome-container">
            {data?.user?.individual_person?.name ? (
              <h2>
                Seja Bem-Vindo
                <span>
                  {` ${data?.user?.individual_person?.name.split(' ')[0]}`}
                  !
                </span>
              </h2>
            ) : (
              <>
                <h2>Seja Bem-Vindo!</h2>
                <p>Faça Login ou cadastre-se para poder solicitar um serviço</p>
              </>
            )}
          </div>
          <MobileLinks />
        </div>
        <div className="home__location-container">
          <Marker />
          <span>
            {data?.user?.is_active && userAddress
              ? `${userAddress.street}, ${userAddress.number} - ${userAddress.neighborhood} - ${userAddress.city.name} - ${userAddress.city.state.uf} - ${userAddress.cep}`
              : 'Após o cadastro sua localização atual irá aparecer aqui'}
          </span>
        </div>
      </div>
      <div className="home__content">
        <div className="home__content-top">
          <h2>Que tipo de profissional procura hoje?</h2>
          <div className="home__categories-container">
            {categories.length > 0 && (
              <>
                <HexagonGridA>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(0)}
                    active={categories[0].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[0].name} />
                    </Hexagon>
                    <HexagonCaption top>
                      <p>{categories[0].name}</p>
                      <p>{!categories[0].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(4)}
                    active={categories[4].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[4].name} />
                    </Hexagon>
                    <HexagonCaption top>
                      <p>{categories[4].name}</p>
                      <p>{!categories[4].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                </HexagonGridA>
                <HexagonGridB>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(2)}
                    active={categories[2].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[2].name} />
                    </Hexagon>
                    <HexagonCaption top>
                      <p>{categories[2].name}</p>
                      <p>{!categories[2].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(6)}
                    active={categories[6].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[6].name} />
                    </Hexagon>
                    <HexagonCaption top>
                      <p>{categories[6].name}</p>
                      <p>{!categories[6].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                </HexagonGridB>
                <HexagonGridA>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(1)}
                    active={categories[1].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[1].name} />
                    </Hexagon>
                    <HexagonCaption top={false}>
                      <p>{categories[1].name}</p>
                      <p>{!categories[1].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(5)}
                    active={categories[5].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[5].name} />
                    </Hexagon>
                    <HexagonCaption top={false}>
                      <p>{categories[5].name}</p>
                      <p>{!categories[5].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                </HexagonGridA>
                <HexagonGridB>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(3)}
                    active={categories[3].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[3].name} />
                    </Hexagon>
                    <HexagonCaption top={false}>
                      <p>{categories[3].name}</p>
                      <p>{!categories[3].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                  <HexagonWrap
                    onClick={() => handleCategoryClick(7)}
                    active={categories[7].is_active}
                  >
                    <Hexagon>
                      <CategoryIcon text={categories[7].name} />
                    </Hexagon>
                    <HexagonCaption top={false}>
                      <p>{categories[7].name}</p>
                      <p>{!categories[7].is_active && '(Em breve)'}</p>
                    </HexagonCaption>
                  </HexagonWrap>
                </HexagonGridB>
              </>
            )}
          </div>
        </div>
        <ServiceAppointment />
        <div className="home__banners-container">
          <img
            src={banners[0]?.url || ''}
            alt="Banner"
            className="home__banner"
          />
        </div>
      </div>
      <NewAppointmentModal />
      {isSearchingProviderOpen && (
        <SearchingProvider
          serviceName={requestedSolicitation?.serviceType?.name || ''}
          serviceSolicitationId={requestedSolicitation?.id || ''}
          clearNewAppointment={clearNewAppointment}
        />
      )}
      <UnauthorizedModal
        isOpen={isUnauthorizedModalOpen}
        onClose={() => setIsUnauthorizedModalOpen(false)}
      />
    </Container>
  );
}
