import styled from 'styled-components';

interface IHexagonProps{
  customSize: number | string;
  children: React.ReactNode;
}

export default function Hexagon({ children, customSize, ...rest }: IHexagonProps) {
  return (
    <Container customSize={customSize} {...rest}>
      {children}
    </Container>
  );
}

const Container = styled.div<{
  customSize?: number | string;
}>`
  width: ${({ customSize }) => (customSize || '3.5rem')};
  height: ${({ customSize }) => (customSize || '3.5rem')};
  -webkit-clip-path: polygon(
    25% 5%,
    75% 5%,
    100% 50%,
    75% 95%,
    25% 95%,
    0% 50%
  );
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  background-color: #292eff;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
    overflow: visible;
    pointer-events: none;
  }
`;
