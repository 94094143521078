import { useEffect, useRef, useState } from 'react';

import styled, { css } from 'styled-components';

import SelectArrow from '../../assets/images/select-arrow.svg';
import { useOutside } from '../../hooks/useOutside';

interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: {
    value: string | number;
    label: string | number;
  }[];
  customFontSize?: string;
  useInput?: number;
}

export default function Select({
  options, placeholder, onChange, value, name, customFontSize = '1rem', useInput = 1, ...rest
}: ISelectProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const toggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const handleChange = (text: string | number) => {
    if (onChange === undefined) return;
    onChange({
      target: {
        name,
        value: text,
        id: rest.id,
      },
    } as unknown as React.ChangeEvent<HTMLSelectElement>);
  };

  const selectedLabel = options.find((option) => String(option.value) === String(value))?.label;

  function handleClose() {
    setIsExpanded(false);
  }

  useOutside(handleClose, selectRef);

  return (
    <Container
      ref={selectRef}
      customFontSize={customFontSize}
      onClick={toggle}
      isExpanded={isExpanded}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          toggle();
        } else if (e.key === 'Escape') {
          handleClose();
        }
      }}
      useInput={useInput}
    >
      {(selectedLabel !== undefined && selectedLabel !== null) ? selectedLabel : (placeholder || 'Selecione...')}
      <OptionsWrapper isExpanded={isExpanded} useInput={useInput}>
        <OptionsContainer useInput={useInput}>
          {options.map(({ value: v, label }) => (
            <Option key={v} value={v} type="button" onClick={() => { handleChange(v); }} selected={v === value} useInput={useInput}>
              {label}
            </Option>
          ))}
        </OptionsContainer>
      </OptionsWrapper>
    </Container>
  );
}

const Container = styled.div<{
  customFontSize: string;
  isExpanded: boolean;
  useInput: number;
}>`
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding: 1em 2.5em 1em 1.25em;
  font-size: ${({ customFontSize }) => customFontSize};
  border-radius: ${({ isExpanded }) => (isExpanded ? '0.5rem 0.5rem 0 0' : '0.5rem')};
  color: ${({ useInput }) => (useInput === 0 ? '#5f5f5f' : '#241DCD')};

  //box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.16), 0px 4px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.16);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1em;
    width: 1em;
    height: 0.75em;

    overflow: visible;

    background-repeat: no-repeat;
    background-size: contain;

    transition: transform 0.3s ease 0s;

    transform: ${({ isExpanded }) => (isExpanded ? 'translateY(-50%) rotate(-180deg)' : 'translateY(-50%) rotate(0deg)')};

    background-image: url(${SelectArrow});
  }
`;

const OptionsWrapper = styled.div<{
  isExpanded?: boolean;
  useInput: number;
}>`
  color: ${({ useInput }) => (useInput === 0 ? '#5f5f5f' : '#241DCD')};
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  z-index: 1;
  height: 0px;
  opacity: 0;
  visibility: hidden;

  background: #fff;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.16), 0px 4px 3px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 0.5rem 0.5rem;

  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  transition: all 0.3s ease 0s;

  ${({ isExpanded }) => isExpanded
    && css`
      height: unset;
      opacity: 1;
      top: 100%;
      visibility: visible;
      `}
`;

const OptionsContainer = styled.div<{
  useInput: number;
}>`
  color: ${({ useInput }) => (useInput === 0 ? '#5f5f5f' : '#241DCD')};
  max-height: 300px;
  overflow-y: auto;

  margin: 0 1rem 1rem;


  scrollbar-width: thin;
  scrollbar-color: #241DCD #CFCFFF;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: #CFCFFF;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    color: ${({ useInput }) => (useInput === 0 ? '#5f5f5f' : '#241DCD')};
  }

`;

const Option = styled.button<{
  selected: boolean;
  useInput: number;
}>`
  color: ${({ useInput }) => (useInput === 0 ? '#5f5f5f' : '#241DCD')};
  display: block;
  background: transparent;
  border: none;

  padding: 0.25em 0.5em;
  width: 100%;

  font-size: inherit;

  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};

  text-align: left;
`;
