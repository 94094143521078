import styled from 'styled-components';
import Modal from 'react-modal';

interface IPaymentInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PaymentInformationModal({
  isOpen, onClose,
}: IPaymentInformationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onClose}>X</button>
        <h1>Ajuda sobre pagamentos</h1>
        <div className="wrapper">
          <div>
            <h3>Como funciona o cálculo do valor do serviço?</h3>

            <p>
              O profissional ao se cadastrar na plataforma, estipula seu preço base de quilometragem
              percorrida e de atendimento,de acordo com o grau de dificuldade do serviço a ser
              realizado,ou pelo tipo de equipamento a ser instalado.
            </p>

            <p>
              O prestador tem total liberdade para informar o preço que desejar no ato de seu
              cadastro, ou alterá-lo. O último preço atualizado pelo profissional na
              plataforma, é o que servira de base para o cálculo.
            </p>

            <p>Dessa forma o cálculo é feito da seguinte forma:</p>

            <p>A * D + B = C</p>
            <p>A: Valor do KM percorrido</p>
            <p>D: Distância do prestador até o cliente</p>
            <p>B: Valor fixado para o tipo de trabalho a ser executado.</p>
            <p>C: Valor total cobrado.</p>

            <p>
              Por esse motivo, os preços variam de um prestador para outro, caso seja necessário
              localizar um novo prestador.
            </p>

            <p>A plataforma sempre prioriza localizar os parceiros da seguinte forma:</p>
            <ul>
              <li>Menor distância</li>
              <li>Menor preço</li>
              <li>Melhor avaliação</li>
            </ul>

            <p>Ainda precisa de ajuda?</p>

            <p>
              Consulte nosso FAQ na seção SUPORTE, ou mande um e-mail para
              {' '}
              <a href="mailto:contato@repfy.com.br">contato@repfy.com.br</a>
            </p>
          </div>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;
  padding: 3rem;
  padding-right: 2rem;
  max-width: max(40vw, 30rem);

  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  h1 {
    color: #292EFF;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
  }

  h3 {
    font-weight: bold;
  }

  .wrapper {
    max-height: 70vh;
    overflow: auto;

    padding-right: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1.25rem;
      background-color: #CFCFFF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1.25rem;
      background-color: #241DCD;
    }
  }

  ul, ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }
  ol {
    list-style-type: decimal;
  }


  p {
    font-size: 1rem;
    line-height: 1.5;
  }

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;
