import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { maskPhoneNumber } from '../../helpers/maskPhoneNumber';
import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';
import { NotificationModal } from '../NotificationModal';

import { StyledForm } from './styles';

export default function EditPhoneForm() {
  const history = useHistory();
  const { data, updatePhoneNumber } = useAuth();
  const [phone, setPhone] = useState(() => {
    const p = data?.user.user_phones[0];

    if (!p) {
      return '';
    }
    return maskPhoneNumber(`${p.state_code}${p.number}`);
  });
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(maskPhoneNumber(e.target.value));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const phoneObj = {
      country_code: '55',
      state_code: phone.replace(/[^0-9]/g, '').substring(0, 2),
      number: phone.replace(/[^0-9]/g, '').substring(2),
    };

    try {
      const response = await api.put(`/users/phones/${data?.user.user_phones[0].id}`, phoneObj);
      updatePhoneNumber(data?.user.user_phones[0].id, response.data.phone);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Alteração salva com sucesso!',
        onClose: () => {
          setHandleNotificationModal((pState) => ({
            ...pState,
            isOpen: false,
          }));
          history.push('/perfil/dados-pessoais');
        },
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || '',
      }));
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <h3>
        Alterar número de celular
      </h3>
      <label>
        <div>
          <PhoneIcon />
          <input type="text" onChange={handleChange} value={phone} minLength={9} maxLength={17} placeholder="Celular" />
        </div>
      </label>
      <button type="submit">
        Salvar alteração
      </button>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
        onClose={handleNotificationModal.onClose}
      />
    </StyledForm>
  );
}
