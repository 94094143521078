export const MonthsOptions = [
  {
    value: '0',
    label: 'Jan',
  },
  {
    value: '1',
    label: 'Fev',
  },
  {
    value: '2',
    label: 'Mar',
  },
  {
    value: '3',
    label: 'Abr',
  },
  {
    value: '4',
    label: 'Maio',
  },
  {
    value: '5',
    label: 'Jun',
  },
  {
    value: '6',
    label: 'Jul',
  },
  {
    value: '7',
    label: 'Ago',
  },
  {
    value: '8',
    label: 'Set',
  },
  {
    value: '9',
    label: 'Out',
  },
  {
    value: '10',
    label: 'Nov',
  },
  {
    value: '11',
    label: 'Dez',
  },
];

export const LongMonthsOptions = [
  {
    value: '0',
    label: 'Janeiro',
  },
  {
    value: '1',
    label: 'Fevereiro',
  },
  {
    value: '2',
    label: 'Março',
  },
  {
    value: '3',
    label: 'Abril',
  },
  {
    value: '4',
    label: 'Maio',
  },
  {
    value: '5',
    label: 'Junho',
  },
  {
    value: '6',
    label: 'Julho',
  },
  {
    value: '7',
    label: 'Agosto',
  },
  {
    value: '8',
    label: 'Setembro',
  },
  {
    value: '9',
    label: 'Outubro',
  },
  {
    value: '10',
    label: 'Novembro',
  },
  {
    value: '11',
    label: 'Dezembro',
  },
];
