import { useEffect, useState } from 'react';

import styled from 'styled-components';

import Datepicker, { ReactDatePickerCustomHeaderProps, registerLocale } from 'react-datepicker';

import format from 'date-fns/format';
import setMonth from 'date-fns/setMonth';
import setYear from 'date-fns/setYear';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import parseISO from 'date-fns/parseISO';
import ptBR from 'date-fns/locale/pt-BR';
import 'react-datepicker/dist/react-datepicker.min.css';

import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';

import { ReactComponent as CalendarMenuIcon } from '../../assets/images/calendar-menu.svg';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as HistoryIcon } from '../../assets/images/history.svg';

import { CancelAppointmentModal } from '../CancelAppointmentModal';
import { NotificationModal } from '../NotificationModal';
import { IServiceAppointment } from '../../types';
import { api } from '../../services/api';
import { Select } from '../Select';
import { LongMonthsOptions } from '../../resources/MonthsOptions';
import { AppointmentContext } from '../../contexts/AppointmentContext';

registerLocale('pt-BR', ptBR);

const formatWeekDay = (day: string) => day.substring(0, 1).toUpperCase();

const formatCurrency = (value: number) => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format(value);

const generateYearsOptions = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const yearsOptions = [];

  for (let i = currentYear - 10; i <= currentYear + 10; i++) {
    yearsOptions.push({
      value: i.toString(),
      label: i.toString(),
    });
  }

  return yearsOptions;
};

export default function Agenda() {
  const { data } = useAuth();
  const [appointments, setAppointments] = useState<IServiceAppointment[]>([]);
  const [date, setDate] = useState(new Date());
  const [appoinmentId, setAppointmentId] = useState('');
  const [isMonthSelectorOpen, setIsMonthSelectorOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    type: 'success' as 'error' | 'success',
    title: '',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const appointmentsDates = appointments.map(
    (appointment) => parseISO(appointment.service_solicitation.date),
  );

  useEffect(() => {
    getAppointments();
  }, [date]);

  const getAppointments = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${data?.access_token}`,
      };

      const response = await api.get<{
        service_appointments: IServiceAppointment[];
      }>('/appointments', {
        headers,
        params: {
          initial_date: '1970-01-01',
          final_date: '2080-01-01',
        },
      });
      setAppointments(response.data.service_appointments.sort((a, b) => (
        (new Date(a.service_solicitation.date) > new Date(b.service_solicitation.date)) ? -1 : 1
      )));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const handleConfirmCancel = async () => {
    setIsCancelModalOpen(false);
    try {
      await api.delete(`/appointments/${appoinmentId}/cancel`, {
        data: {
          cancellation_time: new Date().toISOString(),
        },
      });
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Agendamento cancelado com sucesso!',
        onClose: () => {
          setHandleNotificationModal((pState) => ({
            ...pState,
            isOpen: false,
          }));
        },
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || 'Algo deu errado',
      }));
    }
  };

  const renderCustomDay = (day: number, d: Date | undefined) => {
    const doesDateHaveAppointment = appointmentsDates.some(
      (appointmentDate) => appointmentDate.getDate() === day,
    );

    if (doesDateHaveAppointment) {
      const dayDateObj = new Date(date).setDate(day);
      return (
        <>
          <span>{day}</span>
          <span>
            <br />
            {format(dayDateObj, 'EEEEEE', {
              locale: ptBR,
            })}
          </span>
        </>
      );
    }

    return (
      <span>
        {day}
      </span>
    );
  };

  const renderCustomHeader = (params: ReactDatePickerCustomHeaderProps) => {
    const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newDate = setMonth(new Date(date), parseInt(e.target.value, 10));
      setDate(newDate);
    };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newDate = setYear(new Date(date), parseInt(e.target.value, 10));
    setDate(newDate);
  };

    return (
      <div className="custom-header">
        <h3>
          <button type="button" onClick={() => setIsMonthSelectorOpen((prevState) => !prevState)}>
            <CalendarMenuIcon />
          </button>
          {isMonthSelectorOpen ? (
            <>
              <Select
                options={LongMonthsOptions}
                onChange={handleMonthChange}
                value={date.getMonth().toString()}
              />
              <Select
                options={generateYearsOptions()}
                onChange={handleYearChange}
                value={date.getFullYear().toString()}
              />
            </>
          ) : (
            <>
              {format(date, 'MMMM ', { locale: ptBR })}
              <strong>
                {' '}
                {format(date, "' ' yyyy", { locale: ptBR })}
              </strong>
            </>
          )}
        </h3>
      </div>
    );
  };
  return (
    <Container>
      <h2>
        <CalendarIcon />
        Ver minha agenda
      </h2>
      <CalendarContainer>
        <Datepicker
          selected={date}
          onChange={(d) => setDate(d as Date)}
          renderCustomHeader={renderCustomHeader}
          formatWeekDay={formatWeekDay}
          renderDayContents={renderCustomDay}
          highlightDates={appointmentsDates}
          locale="pt-BR"
          inline
        />
      </CalendarContainer>
      <AppointmentsContainer>
        {appointments.map((appointment) => (
          <div key={appointment.id}>
            <HistoryIcon />
            <div>
              <h3>
                Você agendou os serviços de
                <br />

                <span>
                  {appointment.service_type.name}
                </span>
              </h3>
              <h3>
                Codigo: {appointment.code}
              </h3>
              <div>
                <p>
                  <time>
                    {format(parseISO(appointment.service_solicitation.date), "dd/MM/yyyy 'às' HH:mm'h'", { locale: ptBR })}
                  </time>
                </p>
                <p>
                  Pagamento debitado:
                  {formatCurrency(Number(appointment.transaction.value) / 100)}
                  {' '}
                  em seu cartão final:
                  {' '}
                  {appointment.transaction.card_last_digits}
                </p>
              </div>
              <div className="custom-footer">
                <button
                  type="button"
                  className="cancel-appointment"
                  onClick={() => {
                    setAppointmentId(appointment.id);
                    setIsCancelModalOpen(true);
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        ))}
      </AppointmentsContainer>

      <CancelAppointmentModal
        isOpen={isCancelModalOpen}
        onClose={() => {
          setIsCancelModalOpen(false);
        }}
        onConfirm={handleConfirmCancel}
      />
      <NotificationModal
        {...handleNotificationModal}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .custom-footer {
    button {
        border: none;
        background-color: #292eff;
        color: #fff;
        border-radius: 3rem;
        height: 2rem;
        padding: 0 1rem;
        transition: filter 0.2s;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

        &:disabled {
          opacity: 0.2;
          cursor: not-allowed;
          &:hover {
            filter: unset;
          }
        }

        &:hover {
          filter: brightness(0.8);
        }

        &.cancel-appointment {
          background-color: rgba(255, 0, 0, 0.65);
        }
    }
  }

  h2 {
    font-size: 1.375rem;
    font-weight: 700;
    color: #292EFF;
    text-align: center;

    margin-bottom: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 1rem;
      width: 1.75rem;
      height: 1.5rem;
    }
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  background-color: #EFEFFF;

  padding: 1rem;

  * {
    font-family: 'Axiforma', sans-serif;
  }

  > div:first-child {
    margin: 0 auto;
    width: fit-content;
  }

  .react-datepicker {
    margin: 0 auto;
    padding: 0.5rem;
    padding-bottom: 3rem;
    border-radius: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 0;

    max-width: 28.75rem;

    * {
      background-color: #fff;
    }
  }

  .react-datepicker__header {
    position: unset;
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .react-datepicker__header--custom {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .custom-header {
    background-color: transparent;
    margin-left: 1.25rem;
    margin-top: 0.5rem;

    h3 {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      text-transform: uppercase;
      font-size: 1.375rem;
      font-weight: 400;
      color: #292EFF;

      button {
        background: transparent;
        border: none;
        margin-right: 1.5rem;
      }

      svg {
        width: 1.375rem;
        height: 1.375rem;
      }

      strong {
        font-weight: 700;
        margin-left: 0.5rem;
      }

      > div {
        margin-top: -0.5rem;
        svg {
          top: 0.3em;
          right: 0.5em;
          width: 1rem;
          height: 1rem;
        }

        & + div {
          margin-left: 0.5rem;
        }
      }
    }

    select {
      height: 2rem;
      font-size: 1rem;
    }



  }

  .react-datepicker__day-names {
    position: absolute;
    bottom: 1rem;
    padding: 0 0.4rem;
    > * {
      color: #0126FF;
      font-size: 1.25rem;
      width: 3.125rem;
    }
  }

  // hide calendar days that arent the current month
  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day {
    color: #31409A;
    height: 3.25rem;
    width: 3.125rem;
    line-height: 1;
    vertical-align: middle;
    padding: 0.5rem 0.1rem;

    span:first-child {
      font-size: 1.25rem;
      background-color: transparent;
      vertical-align: middle;
    }

    span:not(:first-child) {
      background-color: transparent;
      font-size: 0.875rem;
      font-weight: 300;
      display: block;
      color: #fff;
      margin-top: -0.25rem;
    }

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  .react-datepicker__day--highlighted {
    span {
      color: #fff;
    }

    span {
      display: block;
      margin-top: -2.25rem;
      font-weight: 700;
    }

    span:not(:first-child) {
      display: block;
      margin-top: -0.75rem;
      font-weight: 300;
      text-transform: capitalize;
    }
  }
 
  // all classes for selected days
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--highlighted,
  .react-datepicker__day--highlighted:hover,
  .react-datepicker__day--selected:hover {
    background-color: #292eff;
    color: #fff;
  }

`;

const AppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 28.75rem;
  margin: 2rem auto;

  > div {
    display: flex;
    align-items: center;
    background-color: #F5F5FA;
    border-radius: 1rem;
    padding: 1.25rem;
    line-height: 1.25;

    svg {
      height: 2rem;
      width: 1.875rem;
      margin-right: 1rem;
      path:first-child {
        fill: #00ABFF;
      }

      path {
        fill: #001383;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 1.125rem;

      h3 {
        font-size: 0.875rem;
        font-weight: 500;
        color: #241DCD;

        span {
          text-transform: uppercase;
        }
      }

      p {
        color: #001383;
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

  }

  > div:nth-child(2n+1) {
    background-color: #EFEFFF;
  }
`;
