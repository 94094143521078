import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;

  gap: 1rem;

  margin-right: 1rem;
`;

export const ImageLink = styled.a`
  transition: filter 0.3s;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const StoreLogo = styled.img`
  max-height: 3.5rem;
  background: transparent;
`;
