import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from '../services/api';

interface IAuthContextData {
  user: IUser;
  access_token: string;
  refresh_token: string;
}

interface IUser {
  id: string;
  avatar: string;
  avatar_url: string;
  email: string;
  individual_person: {
    name: string;
    cpf: string;
  };
  is_active: boolean;
  role_id: number;
  user_phones: {
    id: string;
    state_code: string;
    number: string;
  }[];
  addresses: {
    id: string;
    cep: string;
    main: boolean;
    street: string;
    neighborhood: string;
    number: string;
    city_id: number | string;
  }[];
}

interface IAuthContext {
  data: IAuthContextData;
  loginUser: (data: IAuthContextData, shouldSave: boolean) => void;
  logoutUser: () => void;
  updateUserInfo: (user: IUser) => void;
  updateAddress: (id: string, address?: IUser['addresses'][0]) => void,
  updatePhoneNumber: (id: string, phone: IUser['user_phones'][0]) => void,
  isAuthLoading: boolean;
}

interface IAuthContextProviderProps {
  children: React.ReactNode;
}

const getLocalStorage = (key: string) => {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return {};
  } catch (error) {
    return {};
  }
};

export const AuthContext = createContext({} as IAuthContext);

export function AuthContextProvider({
  children,
}: IAuthContextProviderProps) {
  const user = getLocalStorage('@repfyWebClient-user');
  const token = window.localStorage.getItem('@repfyWebClient-token') || '';
  const refreshToken = window.localStorage.getItem('@repfyWebClient-refreshToken') || '';
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<IAuthContextData>({
    user: token ? user : undefined,
    access_token: token,
    refresh_token: refreshToken,
  });

  useEffect(() => {
    updateByRefreshToken();
  }, []);

  const loginUser = (newData: IAuthContextData, shouldSave: boolean) => {
    setData(newData);
    if (shouldSave) {
      localStorage.setItem('@repfyWebClient-user', JSON.stringify(newData.user));
      localStorage.setItem('@repfyWebClient-token', newData.access_token);
      localStorage.setItem('@repfyWebClient-refreshToken', newData.refresh_token);
    } else {
      localStorage.setItem('@repfyWebClient-user', JSON.stringify(newData.user));
      api.interceptors.request.use((config) => {
        if (!newData.access_token) {
          return config;
        }
        const newConfig = {
          ...config,
          headers: {
            Authorization: `Bearer ${newData.access_token}`,
            ...config.headers,
          },
        };
        return newConfig;
      }, (error) => Promise.reject(error));
    }
    setIsLoading(false);
  };

  const logoutUser = () => {
    setData({} as IAuthContextData);

    localStorage.removeItem('@repfyWebClient-user');
    localStorage.removeItem('@repfyWebClient-token');
    localStorage.removeItem('@repfyWebClient-refreshToken');
  };

  const updateByRefreshToken = async () => {
    if (!refreshToken) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await api.put('/sessions/refresh-token', {
        refresh_token: refreshToken,
      });

      const { access_token, refresh_token, user: updatedUser } = response.data;

      localStorage.setItem('@repfyWebClient-token', access_token);
      localStorage.setItem('@repfyWebClient-refreshToken', refresh_token);
      localStorage.setItem('@repfyWebClient-user', JSON.stringify(updatedUser));

      setData((prevState) => ({
        ...prevState,
        access_token,
        refresh_token,
        user: updatedUser,
      }));
    } catch (error: any) {
      logoutUser();

      history.push('/');
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserInfo = (updatedUser: IUser) => {
    const newUserData = {
      ...data.user,
      ...updatedUser,
    };

    const cachedToken = localStorage.getItem('@repfyWebClient-token');
    if (cachedToken) {
      localStorage.setItem('@repfyWebClient-user', JSON.stringify(newUserData));
    }

    setData((prevState) => ({
      ...prevState,
      user: newUserData,
    }));
  };

  const updatePhoneNumber = (id: string, phone: IUser['user_phones'][0]) => {
    const userData = { ...data.user };
    const userPhones = userData.user_phones.map((phoneItem) => {
      if (phoneItem.id === id) {
        return phone;
      }
      return phoneItem;
    });
    setData((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        user_phones: userPhones,
      },
    }));
  };

  const updateAddress = async (id: string, address?: (IUser['addresses'][0])) => {
    const userData = { ...data.user };

    let userAddresses: IUser['addresses'];

    if (!address) {
      userAddresses = userData.addresses.filter((addressItem) => addressItem.id !== id);
    } else {
      const doesAddressExist = userData.addresses.find((addressItem) => addressItem.id === id);

      if (doesAddressExist) {
        userAddresses = userData.addresses.map((addressItem) => {
          if (addressItem.id === id) {
            return address;
          }
          return addressItem;
        });
      } else {
        userAddresses = [
          ...userData.addresses,
          address,
        ];
      }
    }
    setData((prevState) => {
      const localStorageToken = localStorage.getItem('@repfyWebClient-token');
      if (localStorageToken) {
        localStorage.setItem('@repfyWebClient-user', JSON.stringify({
          ...prevState.user,
          addresses: userAddresses,
        }));
      }
      return ({
        ...prevState,
        user: {
          ...prevState.user,
          addresses: userAddresses,
        },
      });
    });
  };

  return (
    <AuthContext.Provider
      value={{
        data,
        loginUser,
        logoutUser,
        updateUserInfo,
        updateAddress,
        updatePhoneNumber,
        isAuthLoading: isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
