import AppStore from '../../assets/images/app-store.png';
import GooglePlay from '../../assets/images/google-play.png';

import { Container, ImageLink, StoreLogo } from './styles';

export default function MobileLinks() {
  return (
    <Container>

      <ImageLink href="https://apps.apple.com/us/app/repfy/id1613953568" target="_blank" rel="noreferrer">
        <StoreLogo src={AppStore} alt="disponível na app store" />
      </ImageLink>
      <ImageLink href="https://play.google.com/store/apps/details?id=com.fixfyappclient" target="_blank" rel="noreferrer">
        <StoreLogo src={GooglePlay} alt="disponível no google play" />
      </ImageLink>
    </Container>
  );
}
