import axios from 'axios';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* import {
  useJsApiLoader,
} from '@react-google-maps/api'; */

import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';

import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';

import { NotificationModal } from '../NotificationModal';

import { AddressListContainer, StyledForm } from './styles';
import { IAddress } from '../../types';

export default function AddressList() {
  const history = useHistory();
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number | null>(null);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserAddresses();
  }, []);

  const getUserAddresses = async () => {
    try {
      const response = await api.get('/addresses');
      setAddresses(response.data.results);
      setIsLoading(false);
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || '',
      }));
    }
  };

  const handleSuccess = () => {
    setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: true,
      type: 'success',
      title: 'Alteração salva com sucesso!',
      onClose: () => {
        setHandleNotificationModal((pState) => ({
          ...pState,
          isOpen: false,
        }));
        history.push('/perfil/dados-pessoais');
      },
    }));
  };

  const handleError = (error: string) => {
    setHandleNotificationModal((prevState) => ({
      ...prevState,
      onClose: () => setHandleNotificationModal((pState) => ({
        ...pState,
        isOpen: false,
      })),
      isOpen: true,
      type: 'error',
      title: error,
    }));
  };

  return isLoading ? <></> : (
    <>
      {selectedAddressIndex !== null || !addresses[0]?.street ? (
        <EditAddressForm
          address={selectedAddressIndex !== null ? addresses[selectedAddressIndex] : undefined}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      ) : (
        <AddressListContainer>
          {addresses.map((address, index) => (
            <div key={address.id}>
              <div>
                <h3>
                  {address.name}
                </h3>
                <p>
                  {address.street}
                  ,
                  {' '}
                  {address.number}
                  ,
                  {' '}
                  {address.neighborhood}
                  {' '}
                  -
                  {' '}
                  {address.city.name}
                  {' '}
                  -
                  {' '}
                  {address.city.state.uf}
                  {' '}
                  -
                  {' '}
                  {address.cep}
                </p>
              </div>
              <button type="button" onClick={() => setSelectedAddressIndex(index)}>
                <EditIcon />
              </button>
            </div>
          ))}

        </AddressListContainer>
      )}
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
        onClose={handleNotificationModal.onClose}
      />
    </>
  );
}

interface IAddressFormData {
  name: string;
  street: string;
  neighborhood: string;
  complement: string;
  number: string;
  cep: string;
  city: string;
  city_ibge_code: string;
}

interface IEditAddressFormProps {
  address?: IAddress;
  onError: (error: string) => void;
  onSuccess: () => void;
}

const libraries = ['places'] as any;

function EditAddressForm({ address, onSuccess, onError }: IEditAddressFormProps) {
  /* const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  }); */
  const { updateAddress } = useAuth();
  const [addressFormData, setAddressFormData] = useState<IAddressFormData>({
    name: address?.name || '',
    street: address?.street || '',
    neighborhood: address?.neighborhood || '',
    complement: address?.complement || '',
    number: address?.number || '',
    cep: address?.cep || '',
    city: address?.city.name || '',
    city_ibge_code: address?.city.ibge_code || '',
  } as IAddressFormData);
  const [cepError, setCepError] = useState(false);

  const getGeolocation = async () => {
    const service = new google.maps.Geocoder();
    const request: any = {
      address: `${addressFormData?.street} ${addressFormData?.number} ${addressFormData?.cep}`,
    };
    const newGeo = await service.geocode(request, (results, status) => {
      if (results && results.length) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        return {
          latitude,
          longitude,
        };
      }
      return {
        latitude: 0,
        longitude: 0,
      };
    });
    return {
      latitude: newGeo.results[0] ? newGeo.results[0].geometry.location.lat() : 0,
      longitude: newGeo.results[0] ? newGeo.results[0].geometry.location.lng() : 0,
    };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (cepError) {
      onError('CEP inválido');
      return;
    }

    const dataToSubmit = {
      ...addressFormData,
      city: undefined,
      main: !(address),
    };

    if (address) {
      try {
        const response = await api.put(`/addresses/${address?.id}`, dataToSubmit);
        updateAddress(address.id, response.data);
        onSuccess();
      } catch (error: any) {
        onError(error?.response?.data.message || error?.message);
      }
    } else {
      try {
        const newGeo = {
          latitude: 0,
          longitude: 0,
        };
        const response = await api.post('/addresses', {
          ...dataToSubmit,
          // geolocation: newGeo,
        });
        updateAddress(response.data.id, response.data);
        onSuccess();
      } catch (error: any) {
        onError(error?.response?.data.message || error?.message);
      }
    }
  };

  const handleDeleteAddress = async () => {
    if (!address) return;
    try {
      await api.delete(`/addresses/${address?.id}`);
      updateAddress(address?.id, undefined);
      onSuccess();
    } catch (error: any) {
      onError(error?.response?.data.message || error?.message);
    }
  };

  const handleCepBlur = async () => {
    if (addressFormData.cep.length < 8) {
      return;
    }
    try {
      const response = await axios.get(`
        https://viacep.com.br/ws/${addressFormData.cep.replace(/\D/g, '')}/json/
      `);
      if (response.data.erro) {
        setCepError(true);
      } else {
        setCepError(false);
        setAddressFormData((prevState) => ({
          ...prevState,
          cep: response.data.cep.replace(/-/g, ''),
          street: response.data.logradouro,
          neighborhood: response.data.bairro,
          complement: response.data.complemento,
          city: response.data.localidade,
          city_ibge_code: response.data.ibge,
        }));
      }
    } catch (error: any) {
      setCepError(true);
    }
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <h3>
          {address ? 'Alterar ' : 'Adicionar '}
          endereço
        </h3>
        <label>
          <div>
            <input
              type="text"
              name="name"
              placeholder="Nome"
              value={addressFormData.name}
              onChange={handleInputChange}
              required
            />
          </div>
        </label>
        <label>
          <div>
            <input
              type="text"
              name="cep"
              placeholder="CEP"
              value={addressFormData.cep}
              onChange={handleInputChange}
              onBlur={handleCepBlur}
              required
            />
          </div>
        </label>
        <label>
          <div>
            <input
              type="text"
              name="street"
              placeholder="Rua"
              value={addressFormData.street}
              onChange={handleInputChange}
              required
            />
          </div>
        </label>
        <label>
          <div>
            <input
              type="text"
              name="number"
              placeholder="Número"
              value={addressFormData.number}
              onChange={handleInputChange}
              required
            />
          </div>
        </label>
        <label>
          <div>
            <input
              type="text"
              name="complement"
              placeholder="Complemento"
              value={addressFormData.complement}
              onChange={handleInputChange}
            />
          </div>
        </label>
        <label>
          <div>
            <input
              type="text"
              name="neighborhood"
              placeholder="Bairro"
              value={addressFormData.neighborhood}
              onChange={handleInputChange}
              required
            />
          </div>
        </label>

        <button type="submit">
          {address ? 'Salvar alteração' : 'Salvar endereço'}
        </button>

        {!!address && (
        <button type="button" className="edit-address__delete-button" onClick={handleDeleteAddress}>
          Apagar endereço
        </button>
        )}
      </StyledForm>
    </>
  );
}
