import {
  useCallback, useContext, useEffect, useState,
} from 'react';

import { Rating } from 'react-simple-star-rating';

import { toast } from 'react-toastify';

import Placeholder from '../../assets/images/placeholder.jpg';

import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';
import { AppointmentContext } from '../../contexts/AppointmentContext';
import { Map } from '../../components/Map';
import { SearchingProvider } from '../../components/SearchingProvider';

import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg';
import { ReactComponent as BlockIcon } from '../../assets/images/block.svg';
import { ReactComponent as MarkerIcon } from '../../assets/images/marker.svg';

import {
  CancelAppointmentButton,
  ChatContainer,
  Container,
  InitialSplash,
} from './styles';
import { Chat } from '../../components/Chat';
import { ProviderNotFound } from '../../components/ProviderNotFound';

type GeoLocation = {
  lat: number;
  lng: number;
};

export default function AppointmentMap() {
  const { data, isAuthLoading } = useAuth();
  const [providerGeo, setProviderGeo] = useState<GeoLocation>();
  const [clientGeo, setClientGeo] = useState<GeoLocation>();
  const {
    activeAppointment, cancelAppointment, clearNewAppointment, socket,
  } = useContext(AppointmentContext);
  const [timeToArrive, setTimeToArrive] = useState('');
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isShowingProviderInfo, setIsShowingProviderInfo] = useState(false);
  const [providerNotFound, setProviderNotFound] = useState(false);

  const isLoading = !activeAppointment || !providerGeo;

  useEffect(() => {
    if (!socket) return () => {};

    socket.on('provider_address', (e) => {
      const [lat, lng] = e.coordinates;
      onProviderGeoChange({ lat, lng });
    });

    return () => {
      socket.off('provider_address');
    };
  }, [socket]);

  useEffect(() => {
    const [lng, lat] = activeAppointment?.address.geolocation.coordinates || [
      -23.45, -46.6,
    ];
    setClientGeo({
      lat,
      lng,
    });
  }, [activeAppointment]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isLoading) {
      timeout = setTimeout(() => {
        showProviderNotFound();
      }, 100000);
    }
    return () => clearTimeout(timeout);
  }, [isLoading, providerNotFound]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (providerNotFound) {
      timeout = setTimeout(() => {
        disableProviderNotFound();
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [providerNotFound, isLoading]);

  const showProviderNotFound = () => {
    if (isLoading) {
      setProviderNotFound(true);
    }
  };

  const disableProviderNotFound = () => {
    if (providerNotFound && isLoading) {
      setProviderNotFound(false);
    }
  };

  const onProviderGeoChange = useCallback(({ lat, lng }: GeoLocation) => {
    setProviderGeo((prevState) => {
      if (prevState?.lat !== lat || prevState?.lng !== lng) {
        return { lat, lng };
      }
      return prevState;
    });
  }, []);

  const handleCancelAppointment = async () => {
    const headers = {
      Authorization: `Bearer ${data?.access_token}`,
    };
    try {
      await api.delete(`/appointments/${activeAppointment?.id}/cancel`, {
        headers,
        data: {
          cancellation_time: new Date().toISOString(),
        },
      });
      cancelAppointment();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const handleToggleIsChatOpen = () => {
    setIsChatOpen((prevState) => !prevState);
  };

  const handleToggleShowProviderInfo = () => {
    setIsShowingProviderInfo((prevState) => !prevState);
  };

  const handleTimeToArriveChange = (text: string) => {
    setTimeToArrive(text);
  };

  if (isLoading && !providerNotFound) {
    return (
      <SearchingProvider
        serviceName={
          activeAppointment ? activeAppointment?.service_type.name : ''
        }
        firstText="Hoje é o dia do seu agendamento!"
        secondText="Buscando a localização do profissional..."
        clearNewAppointment={clearNewAppointment}
      />
    );
  }
  if (providerNotFound && isLoading) {
    if (!activeAppointment) return null;
    return (
      <ProviderNotFound
        providerName={
          activeAppointment.provider.user.individual_person.name.split(' ')[0]
        }
        serviceName={activeAppointment.service_type.name}
      />
    );
  }

  return (
    <Container>
      <InitialSplash>
        <div>
          <img
            src={activeAppointment?.provider.user.avatar_url || Placeholder}
            alt=""
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              (e.target as HTMLImageElement).src = Placeholder;
            }}
          />
          <h1>
            O
            {' '}
            {activeAppointment?.service_type.name.split(' ')[0]}
            <strong>
              {' '}
              {activeAppointment?.provider.user.individual_person.name}
            </strong>
            {' '}
            está a caminho...
          </h1>
          <h2>
            Previsão de chegada: chegará em
            {' '}
            {timeToArrive}
          </h2>
        </div>
      </InitialSplash>
      <div className="address-container">
        <MarkerIcon />
        {activeAppointment
          && `
            ${activeAppointment.address.street}, ${activeAppointment.address.number} - ${activeAppointment.address.cep}
          `}
      </div>
      <Map
        destinationGeo={clientGeo as GeoLocation}
        originGeo={providerGeo as GeoLocation}
        destinationAddress={`${activeAppointment?.address.street} ${activeAppointment?.address.number} ${activeAppointment?.address.cep}`}
        providerAvatar={activeAppointment?.provider.user.avatar_url}
        timeToArrive={timeToArrive}
        onTimeToArriveChange={handleTimeToArriveChange}
      />
      <ChatContainer isOpen={isChatOpen}>
        <button type="button" onClick={handleToggleIsChatOpen}>
          <ArrowDown />
        </button>
        <div className="provider-information">
          <img
            src={activeAppointment?.provider?.user?.avatar_url || Placeholder}
            alt=""
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              (e.target as HTMLImageElement).src = Placeholder;
            }}
          />
          <div>
            <h3>{activeAppointment?.service_type.name}</h3>
            <h2>{activeAppointment?.provider.user.individual_person.name}</h2>

            <Rating
              readonly
              ratingValue={0}
              initialValue={activeAppointment?.provider?.average_rating || 0}
              onClick={() => {}}
              size={22}
              fillColor="#005FFF"
            />
            <button type="button" onClick={handleToggleShowProviderInfo}>
              Ver mais sobre
              {' '}
              {activeAppointment?.provider.user.individual_person.name}
            </button>
          </div>
        </div>
        {/* <div>
          <ChatIcon />
          <input
            type="text"
            placeholder={`Enviar uma mensagem a
            ${activeAppointment?.provider.user.individual_person.name.split(' ')[0]}`}
          />
        </div> */}
        <Chat appointmentId={activeAppointment?.id || ''} user={data?.user} />
      </ChatContainer>
      <CancelAppointmentButton type="button" onClick={handleCancelAppointment}>
        <div>
          <BlockIcon />
          <h2>Cancelar solicitação</h2>
        </div>
        <p>Você tem 5 minutos para cancelar essa solicitação sem ser cobrado</p>
      </CancelAppointmentButton>
    </Container>
  );
}
