import styled from 'styled-components';

import Logo from '../../assets/images/logo.png';
import { CategoryIcon } from '../CategoryIcon';
import { Hexagon } from '../Hexagon';

import { LoadingBackground } from '../LoadingBackground';

interface IProviderNotFoundProps {
  providerName: string;
  serviceName: string;
}

export default function ProviderNotFound({ providerName, serviceName }: IProviderNotFoundProps) {
  return (
    <LoadingBackground isAnimated>
      <Container>
        <div className="headings-container">
          <img src={Logo} alt="repfy logo" />
          <h1>
            Infelizmente não encontramos
            {' '}
            {providerName}
          </h1>
        </div>
        <p>
          Não se preocupe estamos direcionando a sua solicitação
          para outro profissional mais próximo.
        </p>
        <div className="hexagon-wrapper">
          <Hexagon customSize="12rem">
            <CategoryIcon text={serviceName} />
          </Hexagon>
        </div>
      </Container>
    </LoadingBackground>
  );
}

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; */

  line-height: 1.5;

  max-width: 20rem;

  max-height: 95vh;

  text-align: center;

  overflow-y: auto;
  scrollbar-width: none;
  margin: 0 auto;

  .headings-container {

    max-width: 20rem;

    img {
      width: 9.375rem;
      height: auto;
    }

    > h1 {
      margin-top: 2rem;
      font-size: 2rem;
      text-align: left;
    }
  }

  p {
    margin-top: 2rem;
    font-size: 1.125rem;
    text-align: left;
    margin-bottom: 2rem;
  }

  .hexagon-wrapper {
    > div {
      margin: 0 auto;
    }
  }
`;
