import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../Header';
import { NavBar } from '../NavBar';

import AsideHexagons from '../../assets/images/asidehexagons.svg';
import Router from '../../routes/Router';

export default function Layout() {
  const { pathname } = useLocation();
  const SingleElementPages = [
    '/entrar',
    '/cadastro',
    '/cadastro/confirmar',
    '/alterar-senha',
    '/esqueci-senha',
  ];

  if (pathname.includes('/alterar-senha')) {
    window.document.title = 'Repfy';
  }

  if (SingleElementPages.includes(pathname)) {
    return (
      <Router />
    );
  }
  return (
    <Container>
      <aside>
        <NavBar />
      </aside>
      <Content>
        {!(['sobre', 'agendamento', 'solicitar'].includes(pathname.split('/')[1])) && <Header />}
        <main>
          <Router />
        </main>
      </Content>
      <footer>
        <NavBar />
      </footer>
    </Container>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  main {
    flex: 1;
    width: 100%;
    margin: 0 auto;
    margin-top: 1.25rem;
  }

  @media (max-width: 601px) {
    main {
      padding-bottom: 10rem;
    }
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;



  aside {
    position: relative;
    min-height: 100%;
    max-width: 11rem;
    background: url(${AsideHexagons}), linear-gradient(to bottom, #001489 0%, #001489 33%, #000D58 70%, #000A45 100%) ;
    background-position: 160% 10vh, center;
    background-repeat: no-repeat;


    display: flex;
    flex-direction: column;
    align-items: space-between;

    @media (max-width: 601px) {
      display: none;
    }
  }

  footer {
    @media (min-width: 601px) {
      display: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100%;
    max-width: 98vw;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    height: 7rem;
    min-height: unset;

    z-index: 9999999;

    overflow: hidden;

    background: linear-gradient(to bottom, #001489 0%, #001489 33%, #000D58 70%, #000A45 100%) ;
  }
`;
