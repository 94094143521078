import { useContext, useRef, useState } from 'react';

import formatRelative from 'date-fns/formatRelative';
import ptBR from 'date-fns/locale/pt-BR';

import { NavLink } from 'react-router-dom';
import { ReactComponent as NotificationIcon } from '../../assets/images/notification.svg';

import { NotificationContext } from '../../contexts/NotificationContext';
import { useAuth } from '../../hooks/useAuth';
import { useOutside } from '../../hooks/useOutside';
import { UnauthorizedModal } from '../UnauthorizedModal';
import {
  Container,
  NotificationContent,
  NotificationHeading,
  NotificationsList,
  NotificationsWrapper,
  NotificationTime,
} from './styles';

interface INotificationBellProps {
  iconColor?: string;
}

const formatRelativeLocale = {
  ...ptBR.formatRelative,
  today: "'Hoje às' HH:mm'h'",
  yesterday: "'Ontem às' HH:mm'h'",
  lastWeek: "dd/MM/yyyy 'às' HH:mm'h'",
  other: "dd/MM/yyyy 'às' HH:mm'h'",
} as { [key: string]: string };

export const locale = {
  ...ptBR,
  formatRelative: (token: string) => formatRelativeLocale[token],
};

export default function NotificationBell({
  iconColor = '#081564',
}: INotificationBellProps) {
  const { data } = useAuth();
  const {
    notifications,
    canReceivePushNotifications,
    handleOpenNativeNotificationPrompt,
  } = useContext(NotificationContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUnauthorizedModalOpen, setIsUnauthorizedModalOpen] = useState(false);
  const [readNotifications, setReadNotifications] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleToggleNotifications = () => {
    if (!data?.access_token) {
      setIsUnauthorizedModalOpen(true);
    } else if (!canReceivePushNotifications) {
      handleOpenNativeNotificationPrompt();
    } else {
      setReadNotifications(notifications.length);
      setIsExpanded((prevState) => !prevState);
    }
  };

  const handleCloseNotifications = () => {
    setIsExpanded(false);
  };

  const handleCloseUnauthorizedModal = () => {
    setIsUnauthorizedModalOpen(false);
  };

  useOutside(handleCloseNotifications, containerRef);

  return (
    <Container
      iconColor={iconColor}
      ref={containerRef}
      hasNewNotifications={notifications.length > readNotifications}
    >
      <button type="button" onClick={handleToggleNotifications}>
        <NotificationIcon />
      </button>
      <NotificationsWrapper isExpanded={isExpanded}>
        <NotificationsList isExpanded={isExpanded}>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NavLink key={notification.id} to="/notificacoes">
                <NotificationHeading>
                  {notification.heading}
                </NotificationHeading>
                <NotificationContent>
                  {notification.content}
                </NotificationContent>
                <NotificationTime>
                  {formatRelative(notification.date, new Date(), {
                    locale,
                  })}
                </NotificationTime>
              </NavLink>
            ))
          ) : (
            <span className="notification-bell__prompt-text">
              Nenhuma notificação recebida!
            </span>
          )}
        </NotificationsList>
      </NotificationsWrapper>
      <UnauthorizedModal
        isOpen={isUnauthorizedModalOpen}
        onClose={handleCloseUnauthorizedModal}
      />
    </Container>
  );
}
