import styled from 'styled-components';

export const Container = styled.article`
  background-color: #021793;
  width: 100%;
  border-radius: 1rem;

  padding: 1.5rem 3rem;

  border: none;
  color: #fff;

  display: block;

  line-height: 3.5rem;

  text-align:left;

  > button:first-child {
    background: transparent;
    border: none;
    width: 100%;
    color: #fff;
    text-align:left;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.875rem;
      color: #fff;

      text-decoration: none;

      transition: filter 0.2s;

      svg {
        fill: #fff;
        overflow: visible;
        height: 2rem;
        width: 2rem;
      }

      &:hover {
        filter: brightness(0.8);
      }

      &:link,
      &:visited,
      & {
        color: #fff;
        text-decoration: none;
      }
    }

    time {
      display: flex;
      align-items: center;

      gap: 1.5rem;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  h3 {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;

    strong {
      font-weight: 700;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    gap: 1rem;

    button {
      border: none;
      background-color: #292eff;
      color: #fff;
      border-radius: 3rem;
      height: 4rem;
      padding: 0 3rem;

      font-size: 1.25rem;

      transition: filter 0.2s;

      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
        &:hover {
          filter: unset;
        }
      }

      &:hover {
        filter: brightness(0.8);
      }

      &.cancel-appointment {
        background-color: rgba(255, 0, 0, 0.65);
      }
    }
  }
`;
