import styled from 'styled-components';

import HexagonImg from '../../assets/images/hexagon.svg';

interface ILoadingBackgroundProps {
  children: React.ReactNode;
  isAnimated: boolean;
}

export default function LoadingBackground({ children, isAnimated } :ILoadingBackgroundProps) {
  return (
    <Container isAnimated={isAnimated}>
      <img src={HexagonImg} alt="hexagon" className="loading__hexagon-1" />
      <img src={HexagonImg} alt="hexagon" className="loading__hexagon-2" />
      {children}
    </Container>
  );
}

const Container = styled.div<{
  isAnimated?: boolean;
}>`
  position:fixed;

  width: 100vw;
  min-height: 100vh;
  background: linear-gradient(to bottom, #001489 0%, #001489 30%, #000D58 60%, #0B0A45 100%);

  z-index: 999999999;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;

  .loading__hexagon-1{
    --x-translation: 40%;
    --y-translation: -40%;
    --rotation: 330deg;

    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    min-width: 20rem;
    height: auto;

    transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1.0) ;

    animation: ${({ isAnimated }) => (isAnimated ? 'lowerHexagon 3.5s infinite' : 'none')};

  }

  .loading__hexagon-2{
    --x-translation: -50%;
    --y-translation: 50%;
    --rotation: 30deg;

    position: absolute;
    bottom: 0;
    left: 0;
    width: 30%;
    min-width: 20rem;
    height: auto;

    transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1.0) ;

    animation: ${({ isAnimated }) => (isAnimated ? 'raiseHexagon 3.5s infinite' : 'none')};
  }

  @keyframes raiseHexagon {
    25% {
      transform: translate(var(--x-translation), calc(var(--y-translation) - 30%)) rotate(var(--rotation)) scale(1.35) ;
    }

    50% {
      transform: translate(var(--x-translation), calc(var(--y-translation) - 30%)) rotate(var(--rotation)) scale(1.35) ;
    }

    75% {
      transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1);
    }

    100% {
      transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1);
    }
  }

  @keyframes lowerHexagon {
    25% {
      transform: translate(var(--x-translation), calc(var(--y-translation) + 30%)) rotate(var(--rotation)) scale(1.35) ;
    }

    50% {
      transform: translate(var(--x-translation), calc(var(--y-translation) + 30%)) rotate(var(--rotation)) scale(1.35) ;
    }

    75% {
      transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1);
    }

    100% {
      transform: translate(var(--x-translation), var(--y-translation)) rotate(var(--rotation)) scale(1);
    }
  }
`;
