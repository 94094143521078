import { BrowserRouter } from 'react-router-dom';

import { AuthContextProvider } from './contexts/AuthContext';

import { SplashScreen } from './components/SplashScreen';
import { Layout } from './components/Layout';
import { AppointmentProvider } from './contexts/AppointmentContext';
import { NotificationProvider } from './contexts/NotificationContext';

function App() {
  const baseName = process.env.PUBLIC_URL || undefined;

  return (
    <>
      <SplashScreen />
      <BrowserRouter basename={baseName}>
        <AuthContextProvider>
          <NotificationProvider>
            <AppointmentProvider>
              <Layout />
            </AppointmentProvider>
          </NotificationProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
