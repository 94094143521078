import { Switch } from 'react-router-dom';

import { AccountInformation } from '../../components/AccountInformation';
import { Agenda } from '../../components/Agenda';
import { EditPasswordForm } from '../../components/EditPasswordForm';
import { ManageProfile } from '../../components/ManageProfile';
import PrivateRoute from '../PrivateRoute';

export default function ProfileRouter() {
  return (
    <Switch>
      <PrivateRoute exact path="/perfil" component={ManageProfile} />
      <PrivateRoute exact path="/perfil/agenda" component={Agenda} />
      <PrivateRoute exact path="/perfil/alterar-senha" component={EditPasswordForm} />
      <PrivateRoute path="/perfil/dados-pessoais" component={AccountInformation} />
    </Switch>
  );
}
