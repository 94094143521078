import styled from 'styled-components';
import BackgroundHexagons from '../../assets/images/backgroundhexagons.png';

export const IconInputContainer = styled.div<{
  active: boolean;
  error?: boolean;
}>`
  width: 100%;
  background-color: #fff;

  border: ${({ error }) => (error ? '1px solid #f00' : '')};

  border-radius: 1.75rem;

  font-size: 1rem;
  font-weight: 300;

  height: 3.5rem;

  display: flex;
  align-items: center;

  padding: 0 1.5rem;

  svg {
    margin-right: 0.875rem;
    width: 1.375rem;
    height: 1.375rem;
    overflow: visible;
    fill: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    // stroke: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    path {
      fill: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    }
  }

  input {
    flex: 1;
    font-size: 1rem;
    border: none;
    outline: none;
    color: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
  }
`;

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  background: url(${BackgroundHexagons}),
    linear-gradient(to bottom, #001489 0%, #0b0a45 100%);
  background-position: center;
  background-size: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  color: #ffffff;

  padding: 2rem 0;

  > * + * {
    margin-top: 2rem;
  }

  img {
    height: 2rem;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 98%;
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;

  gap: 0.5rem;

  label {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
  }

  input {
    background-color: #0027ff;
    color: #fff;
    width: 1rem;
    height: 1rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    border: none;
    color: #0027ff;
    font-size: 0.75rem;
    font-weight: 700;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const SubmitButton = styled.button`
  border: none;
  color: #fff;
  height: 3rem;
  background-color: #292eff;
  font-size: 1.25rem;
  border-radius: 3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const CancelButton = styled.button`
  border: none;
  color: #fff;
  height: 3rem;
  background-color: #fc0302;
  opacity: 0.9;
  font-size: 1.25rem;
  border-radius: 3rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);

  transition: filter 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  &:hover {
    filter: brightness(0.8);
  }
`;
