import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  a[href^="http://maps.google.com/maps"],
  a[href^="https://maps.google.com/maps"],
  a[href^="https://www.google.com/maps"] {
      display: none !important;
  }
  .gm-bundled-control .gmnoprint {
      display: block;
  }
  .gmnoprint:not(.gm-bundled-control) {
      display: none;
  }

  .poi-business {
    visibility: hidden;
  }

  .provider-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -85%);
    position: relative;

    > div:first-child {
      width: fit-content;
      color: #292EFF;
      font-size: 0.75rem;
      border-radius: 0.875rem;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: white;
      padding: 0.5rem 0.5rem 0.25rem;
      font-family: 'Axiforma', sans-serif;
      font-weight: 300;
      top: -50%;
      position: absolute;
      white-space: nowrap;
    }

    svg {
      height: 3.125rem;
      width: 3.125rem;
    }

    svg, path {
      fill: #242BF3;
    }

    img {
      width: 3.375rem;
      height: 3.375rem;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid #242BF3;
      margin-bottom: -2.25rem;
      z-index: 999;
    }
  }

  .client-overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -85%);
    svg {
      height: 3.125rem;
      width: 3.125rem;
    }
    svg, path {
      fill: #242BF3;
    }
  }


  .bullseye {
    height: 10px;
    width: 10px;
    padding: 2px;
    border: solid 2px #242BF3;
    border-radius: 50%;
    background-color: #242BF3;
    background-clip: content-box;


  }

  @media(max-width: 600px) {
    height: 100vh;
  }
`;
