import Modal from 'react-modal';
import styled from 'styled-components';

interface IConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
}

export default function ConfirmationModal({
  isOpen, onCancel, onConfirm, title, message, confirmText, cancelText,
}: IConfirmationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onCancel}>X</button>
        <h1>{title}</h1>
        <p>{message}</p>
        <div>
          <button type="button" className="cancel" onClick={onCancel}>{cancelText}</button>
          <button type="button" className="confirm" onClick={onConfirm}>{confirmText}</button>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;
  padding: 3rem;
  max-width: 30rem;

  position: relative;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  h1 {
    color: #292EFF;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    line-height: 1.25;
  }

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }

  > div {
    display: flex;
    width: 50%;
    margin: 0 auto;
    justify-content: space-between;

    margin-top: 2rem;

    > button {
      background: transparent;
      border: none;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2rem;

      min-height: 2.5rem;

      font-size: 1rem;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }

      &.confirm {
        // color: #241DCD;
        color: #f00;
        border-radius: 0.5rem;

        transition: background-color 0.2s;

        &:hover {
          // background: #241DCD10;
          background: #ff000010;
        }
      }

      &.cancel {
        color: #241DCD;
      }
    }
  }
`;
