import Modal from 'react-modal';

import styled from 'styled-components';

import Failure from '../../assets/images/failure.svg';

import { Hexagon } from '../Hexagon';

interface ICancelAppointmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export default function CancelAppointmentModal({
  isOpen, onClose, onConfirm,
}: ICancelAppointmentModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onClose}>X</button>
        <h2>Deseja cancelar esse agendamento?</h2>
        <Hexagon customSize="7rem">
          <img src={Failure} alt="" />
        </Hexagon>
        <button type="button" onClick={onConfirm} className="confirm-button">Prosseguir</button>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;

  max-width: 25rem;

  position: relative;

  padding: 3rem;

  background-color: white;

  border-radius: 1rem;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);


  h2 {
    font-size: 1.5rem;
    text-align: center;
    color: #292eff;
    font-weight: bold;

  }

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .confirm-button {
    background: #292eff;
    border: none;

    margin-top: 3rem;

    display: flex;
    align-items: center;
    height: 3rem;
    border-radius: 1.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 300;
    padding: 0 2.5rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
