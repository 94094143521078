import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Modal from 'react-modal';

interface IUnauthorizedModalProps {
  isOpen: boolean;
  onClose: () => void;
}

Modal.setAppElement('#root');

export default function UnauthorizedModal({ isOpen, onClose }: IUnauthorizedModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__blue-background"
      onRequestClose={onClose}
      closeTimeoutMS={400}
    >
      <Container>
        <StyledButton as={NavLink} to="/cadastro">
          Cadastre-se para utilizar o aplicativo
        </StyledButton>
        <p>
          Ou se você já é cadastrado faça
          {' '}
          <StyledLink as={NavLink} to="/entrar">login</StyledLink>
          {' '}
          para continuar
        </p>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a, a:visited, a:active {
    text-decoration: none;
  }

  p {
    font-size: 1.375rem;
    font-weight: 400;
    text-align: center;
  }

  > * + * {
    margin-top: 2rem;
  }
`;

const StyledButton = styled.button`
  background-color: #292EFF;
  border: none;
  border-radius: 3.25rem;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  padding: 0 4.375rem;
  text-align: center;
  min-height: 6.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

const StyledLink = styled.a`
  color: #fff;
  font-weight: 700;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

`;
