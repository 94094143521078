import { useCallback, useEffect } from 'react';

type UseOutside = (onOutsideClick: () => void, ref: React.RefObject<any>) => void;

export const useOutside: UseOutside = (onOutsideClick, ref) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    },
    [onOutsideClick, ref],
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
};
