import { useState } from 'react';
import Modal from 'react-modal';
import validator from 'validator';
import MaskedInput from 'react-text-mask';
/* import {
  useJsApiLoader,
} from '@react-google-maps/api'; */

import axios from 'axios';

import styled from 'styled-components';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { NotificationModal } from '../NotificationModal';

interface IClientGetNinjasModalProps {
  isOpen: boolean;
  onClose: (cgnId: string, cgnName: string, cgnEmail: string, cgnTelephone: string) => void;
  isEditing?:boolean;
  onEditSubmit?:(formData: IClientGetNinjasFormData) => void;
}

export interface IClientGetNinjasFormData {
  name: string;
  email: string;
  telephone: string;
}

const initialFormData = {
  name: '',
  email: '',
  telephone: '',
};

const libraries = ['places'] as any;

export default function ClientGetNinjasModal({
  isEditing, isOpen, onClose, onEditSubmit,
}: IClientGetNinjasModalProps) {
  const { data } = useAuth();
  const [emailError, setEmailError] = useState('');
  const [telephoneError, setTelephoneError] = useState('');
  const [clientGetNinjasFormData, setClientGetNinjasFormData] = useState(initialFormData);
  const [cepError, setCepError] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const dataToSubmit = {
      name: clientGetNinjasFormData.name,
      email: clientGetNinjasFormData.email,
      telephone: clientGetNinjasFormData.telephone,
    };

    if (isEditing) {
      if (onEditSubmit) {
        onEditSubmit(clientGetNinjasFormData);
      }
    }

    if (!validator.isEmail(dataToSubmit.email)) {
      setEmailError('Email inválido');
      return;
    }
    setEmailError('');

    if (!validator.isMobilePhone(dataToSubmit.telephone, 'any', { strictMode: false })) {
      setTelephoneError('Telefone inválido');
      return;
    }
    setTelephoneError('');

    try {
      const response = await api.post('/clientgetninjas', dataToSubmit);
      onClose(response.data.id, response.data.name, response.data.email, response.data.telephone);
      setClientGetNinjasFormData(initialFormData);
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response ? error?.response.data.message : error?.message,
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setClientGetNinjasFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClose = () => {
    onClose('', '', '', '');
    setClientGetNinjasFormData(initialFormData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={handleClose}>X</button>
        <StyledForm onSubmit={handleSubmit}>
          <h1>Insira o nome do cliente</h1>
          <input
            name="name"
            type="text"
            placeholder="Nome do cliente"
            value={clientGetNinjasFormData.name}
            onChange={handleInputChange}
            required
          />
          <input
            name="email"
            type="text"
            placeholder="Email do cliente"
            value={clientGetNinjasFormData.email}
            onChange={handleInputChange}
            required
          />
          {emailError && <p>{emailError}</p>}
          <MaskedInput
            name="telephone"
            type="text"
            placeholder="Telefone do cliente"
            value={clientGetNinjasFormData.telephone}
            onChange={handleInputChange}
            mask={['(', /\d/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            required
          />
          {telephoneError && <p>{telephoneError}</p>}
          <button type="submit">
            Confirmar
          </button>
        </StyledForm>
      </Container>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  max-width: 67.5rem;

  position: relative;

  padding: 3rem;

  background-color: white;

  border-radius: 1rem;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);


  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const StyledForm = styled.form`

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #292eff;
  }

  input {
    border: none;

    min-width: 0;

    font-size: 1.375rem;
    font-weight: 500;

    height: 3.5rem;
    line-height: 3.5rem;

    padding: 0 1.75rem;

    border-radius: 1rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  button[type="submit"] {
    border: none;
    background-color: #292eff;

    color: #fff;

    font-size: 1.25rem;
    font-weight: 500;

    border-radius: 1rem;

    height: 3.5rem;
    line-height: 3.625rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

  }

`;
