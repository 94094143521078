import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { io, Socket } from 'socket.io-client';

import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import subHours from 'date-fns/subHours';

import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { api } from '../services/api';

import {
  ICoupon, IProduct, IServiceAppointment, IServiceType,
} from '../types';

interface IAppointmentContextData {
  activeAppointment: IServiceAppointment | undefined;
  clearSolicitationToConfirm: () => void;
  clearNewAppointment: () => void;
  finishAppointment: () => void;
  cancelAppointment: () => void;
  updateAppointmentList: () => void;
  requestedSolicitation?: IRequestedSolicitation;
  onRequestSolicitation: (solicitation: IRequestedSolicitation) => void;
  onSolicitationToConfirm: (e: IClientSolicitationAccepted) => void;
  isSearchingProviderOpen: boolean;
  solicitationToConfirm?: IClientSolicitationAccepted;
  socket?: Socket;
}

interface IRequestedSolicitation {
  id: string;
  date: string; // ISO String
  price: number;
  coupon?: ICoupon;
  serviceType?: IServiceType;
  product: IProduct;
}

export interface IClientSolicitationAccepted {
  provider_id: string;
  provider_avatar: string;
  provider_average_rating: number;
  provider_name: string;
  price: number;
  service_id: string;
}

export const AppointmentContext = createContext({} as IAppointmentContextData);

export function AppointmentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { data, isAuthLoading } = useAuth();
  const history = useHistory();
  const [activeAppointment, setActiveAppointment] = useState<
    IServiceAppointment | undefined
  >();
  const [socket, setSocket] = useState<Socket>();
  const [solicitationToConfirm, setSolicitationToConfirm] = useState<
    IClientSolicitationAccepted | undefined
  >();
  const [requestedSolicitation, setRequestedSolicitation] = useState<IRequestedSolicitation>();
  const [isSearchingProviderOpen, setIsSearchingProviderOpen] = useState(false);

  const headers = {
    Authorization: `Bearer ${data?.access_token}`,
  };

  useEffect(() => {
    if (requestedSolicitation && !solicitationToConfirm) {
      setIsSearchingProviderOpen(true);
    } else {
      setIsSearchingProviderOpen(false);
    }
  }, [requestedSolicitation, solicitationToConfirm]);

  useEffect(() => {
    if (isSearchingProviderOpen) {
      socket?.on(
        'client_solicitation_accepted',
        (e: IClientSolicitationAccepted) => {
          if (requestedSolicitation?.id === e.service_id) {
            onSolicitationToConfirm(e);
          }
        },
      );

      return () => {
        socket?.off('client_solicitation_accepted');
      };
    }
    return () => {
      socket?.off('client_solicitation_accepted');
    };
  }, [isSearchingProviderOpen]);

  useEffect(() => {
    if (data?.access_token) {
      getAppointment();
    } else if (activeAppointment) {
      setActiveAppointment(undefined);
    }
  }, [data?.access_token]);

  useEffect(() => {
    if (!(data?.user?.id && data?.access_token) || isAuthLoading) {
      return () => {
        socket?.disconnect();
      };
    }
    const API_URL = process.env.REACT_APP_API_URL || '';
    const s = io(API_URL, {
      transports: ['websocket', 'polling'],
      query: { user_id: data.user.id },
    });
    setSocket(s);

    return () => {
      socket?.disconnect();
    };
  }, [data?.access_token, isAuthLoading]);

  useEffect(() => {
    if (socket && data?.access_token) {
      socket.on('client_solicitation_error', (e) => {
        toast.error(
          e?.message || 'Erro ao solicitar serviço, por favor tente novamente',
        );
      });

      return () => {
        socket.off('client_solicitation_error');
      };
    }

    return () => {};
  }, [socket, data?.access_token]);

  useEffect(() => {
    if (activeAppointment) {
      const interval = setInterval(() => {
        getAppointmentDetails(activeAppointment.code);
      }, 10000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [activeAppointment]);

  const getAppointment = async (shouldOverwrite = false) => {
    if (!data.access_token) {
      return;
    }
    const response = await api.get<{
      service_appointments: IServiceAppointment[];
    }>('/appointments', {
      headers,
      params: {
        customer_done: false,
        initial_date: '1970-01-01',
        final_date: '2070-01-01',
      },
    });

    const newDateMinus6Hours = subHours(new Date(), 6);

    const serviceAppointments = response.data.service_appointments
      .filter((appointment) => !!appointment.provider)
      .filter((appointment) => isAfter(
        parseISO(appointment.service_solicitation.date),
        newDateMinus6Hours,
      ))
      .sort(
        (a, b) => new Date(a.service_solicitation.date).getTime()
          - new Date(b.service_solicitation.date).getTime(),
      );

    const lastAppointment = serviceAppointments[0];
    if (!lastAppointment || shouldOverwrite) {
      setActiveAppointment(undefined);
      return;
    }
    setActiveAppointment(lastAppointment);
  };

  const getAppointmentDetails = async (code: string | number) => {
    const response = await api.get(`/appointments/${code}`, {
      headers,
    });
    setActiveAppointment(response.data.service_appointment);
  };

  const finishAppointment = () => {
    getAppointment(true);
  };

  const cancelAppointment = () => {
    getAppointment(true);
    history.push('/');
  };

  function onSolicitationToConfirm(e: IClientSolicitationAccepted) {
    setSolicitationToConfirm(e);
    history.push('/');
  }

  function onRequestSolicitation(solicitation: IRequestedSolicitation) {
    setRequestedSolicitation(solicitation);
  }

  function clearSolicitationToConfirm() {
    setSolicitationToConfirm(undefined);
  }

  function clearNewAppointment() {
    setRequestedSolicitation(undefined);
    setSolicitationToConfirm(undefined);
  }

  function updateAppointmentList() {
    setTimeout(() => {
      getAppointment();
    }, 3000);
  }

  return (
    <AppointmentContext.Provider
      value={{
        activeAppointment,
        cancelAppointment,
        clearSolicitationToConfirm,
        clearNewAppointment,
        finishAppointment,
        onRequestSolicitation,
        onSolicitationToConfirm,
        requestedSolicitation,
        socket,
        solicitationToConfirm,
        updateAppointmentList,
        isSearchingProviderOpen,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
}
