/* eslint-disable max-len */
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { useAuth } from '../../hooks/useAuth';

import { ReactComponent as MapIcon } from '../../assets/images/markedmap.svg';

import { Container } from './styles';
import { api } from '../../services/api';
import { FinishAppointmentModal } from '../FinishAppointmentModal';
import { CancelAppointmentModal } from '../CancelAppointmentModal';
import { NotificationModal } from '../NotificationModal';
import { useAppointments } from '../../hooks/useAppointments';
import { IServiceAppointment } from '../../types';

function HandleTitle({ appointment }: { appointment: IServiceAppointment }) {
  if (appointment.provider.on_loop && appointment.id === appointment.provider.working_on_appointment_id) {
    return (<p>O profissional está indo até a sua residencia</p>);
  }

  if (appointment.customer_done) return null;

  if (appointment.status_id === 3) {
    return (
      <p>
        Solicitação cancelada
        {' '}
      </p>
    );
  }

  return (
    <p>
      Solicitação agendada:
      {' '}
      <strong>
        {format(parseISO(appointment.service_solicitation.date), "dd/MM/yyyy 'às' HH:mm'h'")}
      </strong>
    </p>
  );
}

function HandleCancelButton({ appointment, children }: { appointment: IServiceAppointment, children: React.ReactElement }) {
  if (appointment.provider.on_loop && appointment.id === appointment.provider.working_on_appointment_id) {
    return <div />;
  }

  return children;
}

export default function ServiceAppointment() {
  const { data } = useAuth();
  const {
    activeAppointment, finishAppointment, cancelAppointment,
  } = useAppointments();
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    type: 'success' as 'error' | 'success',
    title: '',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const handleFinishAppointment = async () => {
    setIsFinishModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    setIsCancelModalOpen(false);
    try {
      await api.delete(`/appointments/${activeAppointment?.id}/cancel`, {
        data: {
          cancellation_time: new Date().toISOString(),
        },
      });
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Agendamento cancelado com sucesso!',
        onClose: () => {
          setHandleNotificationModal((pState) => ({
            ...pState,
            isOpen: false,
          }));
          cancelAppointment();
        },
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || 'Algo deu errado',
      }));
    }
  };

  const handleFinishModalClose = () => {
    setIsFinishModalOpen(false);
  };

  const handleFinishModalConfirm = () => {
    setIsFinishModalOpen(false);
    finishAppointment();
  };

  if (!activeAppointment || !data?.user?.is_active) {
    return null;
  }

  return (
    <Container>
      <div className="heading">
        <h3>Atendimento agendado</h3>
        {/* <NavLink to="/agendamento">
          <MapIcon />
          Acompanhar
        </NavLink> */}
      </div>
      <p>
        {activeAppointment.service_type.name}
        {' '}
        -
        {' '}
        {activeAppointment?.provider && activeAppointment.provider.user.individual_person.name}
      </p>
      <div className="footer">
        <HandleTitle appointment={activeAppointment} />
        <div style={{ display: 'flex', gap: '1rem' }}>
          {
        activeAppointment.customer_done ? (
          <h3>
            Finalizado
          </h3>
        ) : (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleFinishAppointment();
            }}
            disabled={!activeAppointment?.provider_done}
            title={!activeAppointment?.provider_done ? 'Aguarde o prestador finalizar o serviço para finalizar o atendimento' : ''}
          >
            Finalizar atendimento
          </button>
        )
      }
          <HandleCancelButton appointment={activeAppointment}>
            <button
              type="button"
              className="cancel-appointment"
              onClick={() => setIsCancelModalOpen(true)}
              // disabled={!!activeAppointment?.provider_started_at}
            >
              Cancelar
            </button>
          </HandleCancelButton>
        </div>
      </div>
      <FinishAppointmentModal
        isOpen={isFinishModalOpen}
        onClose={handleFinishModalClose}
        onConfirm={handleFinishModalConfirm}
        appointment={activeAppointment}
      />
      <CancelAppointmentModal
        isOpen={isCancelModalOpen}
        onClose={() => {
          setIsCancelModalOpen(false);
        }}
        onConfirm={handleConfirmCancel}
      />
      <NotificationModal
        {...handleNotificationModal}
      />
    </Container>
  );
}
