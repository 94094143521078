import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';

import {
  CancelButton,
  Container, Content, StyledForm, SubmitButton, SuccesfulContainer,
} from './styles';
import { api } from '../../services/api';
import { NotificationModal } from '../../components/NotificationModal';

export default function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [wasSent, setWasSent] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await api.post('password/forgot', {
        email,
      });

      setWasSent(true);
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message,
      }));
    }
  };

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />
        <h1>
          Redefinição de senha
        </h1>
        {wasSent ? (
          <>
            <SuccesfulContainer>
              <CheckIcon />
              <span>
                E-mail de validação enviado
              </span>
            </SuccesfulContainer>
            <p>
              Verifique seu e-mail para obter instruções
              de como prosseguir.
            </p>
          </>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            <h2>
              Esqueceu a senha? Digite seu e-mail abaixo para iniciar o processo de redefinição.
            </h2>
            <input
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              required
            />
            <SubmitButton type="submit">Enviar</SubmitButton>
            {/* <CancelButton type="button" onClick={handleGoBack}>Voltar</CancelButton> */}
          </StyledForm>
        )}
      </Content>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </Container>
  );
}
