import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
/* Reset */
  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;vertical-align:baseline;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, input, button {
    font-family: 'Axiforma', sans-serif;
  }

  body {
    scrollbar-width: thin;
    scrollbar-color: #1117a2 #e4e4ef;

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1.25rem;
      background-color: #e4e4ef;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1.25rem;
      background-color: #1117a2;
    }
  }

  button {
    cursor: pointer;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 87.5%;
    }

    @media (max-width: 768px) {
      font-size: 75%;
    }

    @media (max-width: 550px) {
      font-size: 67.5%;
    }

    @media (max-width: 480px) {
      font-size: 62.5%;
    }

    @media (max-width: 320px) {
      font-size: 55%;
    }
  }

  .react-modal__overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .react-modal__background {
    background-color:#E5EAFD82;
  }

  .react-modal__blue-background {
    background-color: #00148981;
  }

  .react-modal__content {
    border: none;
    outline: none;
    max-height:100%;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
`;
