/* eslint-disable max-len */
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AboutLogo from '../../assets/images/about-logo.png';

import { HeaderButton } from '../../components/HeaderButton';
import { GoBackButton } from '../../components/GoBackButton';
import { NotificationBell } from '../../components/NotificationBell';

export default function About() {
  const history = useHistory();

  const goBack = () => {
    history.push('/');
  };

  return (
    <Container>
      <StyledHeader>
        <div>
          <h1>
            {
              history.location.pathname === '/sobre/quem-somos' && 'Quem somos'
            }
            {
              history.location.pathname === '/sobre/como-funciona' && 'Como funciona'
            }
          </h1>
          <div>
            <NotificationBell iconColor="#fff" />
            <HeaderButton />
          </div>
        </div>
      </StyledHeader>
      <GoBackButton onClick={goBack} />
      <Content>
        <img src={AboutLogo} alt="Logo" />
        {
              history.location.pathname === '/sobre/quem-somos' && <AboutUs />
            }
        {
              history.location.pathname === '/sobre/como-funciona' && <HowItWorks />
            }
      </Content>
    </Container>
  );
}

function AboutUs() {
  return (
    <div>
      <p>A REPFY é uma empresa fundada por experientes profissionais do ramo de serviços, com grande expertise em atendimento ao cliente.</p>

      <p>O Intuito da REPFY é tornar-se referência como plataforma de contratação de serviços gerais, unir o profissional e o cliente de forma segura, rápida e prática.</p>

      <p>
        Para que isso fosse possível, contamos com talentosos desenvolvedores, que junto a experientes profissionais de serviços, uniram a expertise da logística e vivência em campo, com a beleza de um código inteligente.
        Acreditamos na empatia e experiência, como chave para realização de trabalhos com qualidade e excelência.
      </p>

      <p>
        Quer saber mais sobre a gente? Fale conosco no e-mail
        {' '}
        <a href="mailto:contato@repfy.com.br">contato@repfy.com.br</a>
        , ou visite nosso site
        {' '}
        <a href="http://www.repfy.com.br" target="_blank" rel="noreferrer">www.repfy.com.br</a>
      </p>

    </div>
  );
}

function HowItWorks() {
  return (
    <div>
      <p>
        A Plataforma REPFY, possui duas categorias: CLIENTES e PARCEIROS. Ambas disponíveis nas modalidades Mobile e Web.
      </p>
      <h3>
        PLATAFORMA CLIENTE
      </h3>
      <p>
        O cliente faz o seu cadastro na plataforma, e sempre que precisar de um serviço, consegue solicitar um profissional, de forma emergencial ou agendada. O melhor é que o preço já é simulado na hora em que a solicitação é feita, pois nem sempre é possível esperar horas ou dias por um orçamento não é mesmo?
      </p>
      <p>
        O pagamento é prático, podendo ser feito através do cartão de crédito pré-cadastrado, ou através de créditos comprados pelo aplicativo, então caso esteja sem a sua carteira ou dinheiro vivo, isso também não será um problema!
      </p>
      <p>
        Toda validação do serviço, assim como o histórico, são documentados para segurança, além disso, a equipe REPFY monitora o deslocamento e o andamento dos atendimentos em tempo real em nossa central de monitoramento.
      </p>
      <h3>
        PLATAFORMA PARCEIROS
      </h3>
      <p>
        O parceiro faz o seu cadastro na plataforma, monta sua precificação por categoria de serviços e preço de visita, e pronto. Após nossa análise de segurança e verificação, o parceiro é habilitado a ser acionado via app, por parte dos clientes cadastrados na plataforma, podendo aceitar ou não aquela solicitação. Caso não aceite, o aplicativo ira procurar outro prestador mais próximo, cadastrado na plataforma, para o atendimento.
      </p>
      <p>
        Para mais informações,
        {' '}
        <NavLink to="/faq">visite nosso FAQ clicando aqui</NavLink>
        , acesse nosso site, ou entre em contato através do e-mail
        {' '}
        <a href="mailto:contato@repfy.com.br">contato@repfy.com.br</a>
        .
      </p>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #031790;

  }

  p {
    font-size: 1rem;
    line-height: 1.5;
    color: #202125;
  }
`;

const StyledHeader = styled.header`
  width: 100%;
  height: 8rem;

  display: flex;
  align-items: center;

  background-color: #081564;
  margin-top: -1.5rem;

  > div {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h1 {
      font-size: 2rem;
      color: #fff;
    }

    > div {
      display: flex;
      align-items: center;

      > svg {
        & > path {
          fill: #fff;
        }
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
`;

const Content = styled.div`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  > div {
    width: 100%;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h3 {
      font-weight: bold;
    }

    line-height: 1.5;
    max-width: max(360px, 40vw);
  }

  > * {
    margin: 0 auto;
  }
`;
