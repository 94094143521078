import { createContext, useEffect, useState } from 'react';

import OneSignal from 'react-onesignal';

import { useAuth } from '../hooks/useAuth';

interface INotification {
  id: string;
  heading: string;
  content: string;
  url: string;
  date: Date;
}

interface INotificationContext {
  notifications: INotification[];
  handleOpenNativeNotificationPrompt: () => void;
  canReceivePushNotifications: boolean;
}

export const NotificationContext = createContext({} as INotificationContext);

export function NotificationProvider({ children }: {children: React.ReactNode}) {
  const { data } = useAuth();
  const [canReceivePushNotifications, setCanReceivePushNotifications] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
      safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID || '',
      welcomeNotification: {
        disable: true,
      },
    }).then(() => {
      OneSignal.on('subscriptionChange', (e: any) => {
        OneSignal.isPushNotificationsEnabled()
          .then((isEnabled) => setCanReceivePushNotifications(isEnabled));
      });
      OneSignal.on('notificationPermissionChange', (e: any) => {
        setCanReceivePushNotifications(!!(e.to && e.to === 'granted'));
      });

      OneSignal.on('notificationDisplay', (e: INotification) => {
        setNotifications((prevState) => [{ ...e, date: new Date() }, ...prevState]);
      });
      OneSignal.isPushNotificationsEnabled()
        .then((isEnabled) => setCanReceivePushNotifications(isEnabled));
    });
  }, []);

  useEffect(() => {
    if (data?.access_token) {
      OneSignal.setExternalUserId(data?.user?.id);
    } else {
      OneSignal.getExternalUserId().then((userId) => {
        if (userId) {
          OneSignal.removeExternalUserId();
        }
      });
    }
  }, [data.access_token]);

  function handleOpenNativeNotificationPrompt() {
    OneSignal.showNativePrompt();
  }

  return (
    <NotificationContext.Provider value={{
      notifications,
      handleOpenNativeNotificationPrompt,
      canReceivePushNotifications,
    }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
