import { useState, useEffect } from 'react';

import styled from 'styled-components';

import Hexagon from '../../assets/images/hexagon.svg';
import Logo from '../../assets/images/logo.png';

export default function SplashScreen() {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (visible
    ? (
      <Container>
        <img
          src={Hexagon}
          alt="hexagon"
          className="splash__hexagon splash__hexagon-1"
        />
        <img
          src={Hexagon}
          alt="hexagon"
          className="splash__hexagon splash__hexagon-2"
        />
        <img
          src={Hexagon}
          alt="hexagon"
          className="splash__hexagon splash__hexagon-3"
        />
        <img
          src={Hexagon}
          alt="hexagon"
          className="splash__hexagon splash__hexagon-4"
        />
        <img src={Logo} alt="logo" className="splash__logo" />
      </Container>
    ) : null
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, #001489 0%, #001489 30%, #000D58 60%, #0B0A45 100%);

  z-index:99999999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: fadeOut 3.75s forwards;

  .splash__hexagon {
    min-width: 125px;
    width: 20%;
    height: auto;
  }

  .splash__hexagon-1 {
    --rotation: 45deg;
    --translate-x: -50%;
    --translate-y: -35%;
    position: absolute;
    top: 0;
    left: 0;

    width: 20%;
    height: auto;

    transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));

    animation: animateHexagonOne 1.5s forwards;

  }

  .splash__hexagon-2 {
    --rotation: 250deg;
    --translate-x: -30%;
    --translate-y: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));

    animation: animateHexagonTwo 1.5s forwards ;

  }

  .splash__hexagon-3 {
    --rotation: 45deg;
    --translate-x:20%;
    --translate-y: -30%;
    position: absolute;
    top: 0;
    right: 0;

    width: 25%;
    height: auto;

    transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));

    animation: animateHexagonThree 1.5s forwards ;

  }

  .splash__hexagon-4 {
    --rotation: 150deg;
    --translate-x: 20%;
    --translate-y: 50%;
    position: absolute;
    bottom: 0;
    right: 0;

    width: 20%;

    transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));

    animation: animateHexagonFour 1.5s forwards ;

  }

  .splash__logo {
    height: 3rem;
    width: auto;

    transform: scale(1);

    animation: scaleUp 1.5s forwards;
  }

  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(1.25);
    }
  }

  @keyframes animateHexagonOne {
    0% {
      transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));
    }

    100% {
      transform: scale(1.5) translate(0, 0) rotate(var(--rotation));
    }
  }

  @keyframes animateHexagonTwo {
    0% {
      transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));
    }

    100% {
      transform: scale(1.5) translate(0, calc(var(--translate-y) / 2)) rotate(calc(var(--rotation) - 360deg));
    }
  }

  @keyframes animateHexagonThree {
    0% {
      transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));
    }

    100% {
      transform: scale(1.5) translate(0, 0) rotate(calc(var(--rotation) - 360deg));
    }
  }

  @keyframes animateHexagonFour {
    0% {
      transform: scale(1) translate(var(--translate-x), var(--translate-y)) rotate(var(--rotation));
    }

    100% {
      transform: scale(1.5) translate(0, calc(var(--translate-y) / 2)) rotate(calc(var(--rotation) - 360deg));
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;
