import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { GoBackButton } from '../../components/GoBackButton';

import { api } from '../../services/api';

export default function Terms() {
  const history = useHistory();
  const [terms, setTerms] = useState('');

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    const response = await api.get('/system-infos');
    setTerms(response.data.system_infos.terms_and_conditions);
  };

  const goBack = () => {
    history.push('/');
  };

  return (
    <Container>
      <GoBackButton onClick={goBack} />
      <Content>
        <h2>
          Termos de uso e condições
        </h2>
        <p>
          {terms.replace(/\n\n/g, '\n').replace(/\n/g, '\n\n')}
        </p>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 90%;
  margin: 3rem auto;
  max-width: 720px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 70%;
    margin: 0 auto;

    height: 4.5rem;

    border-radius: 0.5rem;

    font-size: 1.5rem;
    color: #292EFF;

    text-align: center;

    background-color: #fff;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  p {
    margin-top: 3rem;
    font-size: 1rem;
    color: #727272;
    line-height: 1.75;
    white-space: pre-line;
    text-align:justify;
    text-justify: distribute;
  }
`;
