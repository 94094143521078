import axios, { AxiosError } from 'axios';

function removeStorageItems() {
  window.localStorage.removeItem('@repfyWebClient-token');
  window.localStorage.removeItem('@repfyWebClient-refreshToken');
  window.localStorage.removeItem('@repfyWebClient-user');

  window.sessionStorage.removeItem('@repfyWebClient-token');
  window.sessionStorage.removeItem('@repfyWebClient-refreshToken');
  window.sessionStorage.removeItem('@repfyWebClient-user');
}

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('@repfyWebClient-token');
  if (!token) {
    return config;
  }

  const newConfig = {
    ...config,
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  };
  return newConfig;
}, (error) => Promise.reject(error));

let failedRequests = [] as {
  onSuccess: (token: string) => void;
  onError: (error: AxiosError) => void;
}[];
let isRefreshing = false;

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (
        error.response?.data?.message
          .toLowerCase()
          .includes('token jwt expirado')
      ) {
        const requestConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .put('/sessions/refresh-token', {
              refresh_token:
                window.localStorage.getItem('@repfyWebClient-refreshToken')
                || '',
            })
            .then((response) => {
              const { access_token, refresh_token } = response.data;

              const localStorageAccessToken = window.localStorage.getItem(
                '@repfyWebClient-token',
              );

              if (localStorageAccessToken) {
                window.localStorage.setItem(
                  '@repfyWebClient-token',
                  access_token,
                );
                window.localStorage.setItem(
                  '@repfyWebClient-refreshToken',
                  refresh_token,
                );
              } else {
                window.sessionStorage.setItem(
                  '@repfyWebClient-token',
                  access_token,
                );
                window.sessionStorage.setItem(
                  '@repfyWebClient-refreshToken',
                  refresh_token,
                );
              }

              failedRequests.forEach((request) => request.onSuccess(access_token));
              failedRequests = [];
            })
            .catch((err: AxiosError) => {
              failedRequests.forEach((request) => request.onError(err));
              failedRequests = [];

              removeStorageItems();

              window.location.href = '/';
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequests.push({
            onSuccess: (token: string) => {
              if (requestConfig.headers) {
                requestConfig.headers.Authorization = `Bearer ${token}`;
                resolve(api(requestConfig));
              }
            },
            onError: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      return Promise.reject(error);
    }
    if (error.response?.status === 418) {
      removeStorageItems();
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);
