import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';

import Logo from '../../assets/images/logo.png';
import { ReactComponent as HexagonBorders } from '../../assets/images/hexagon-borders.svg';

import { Hexagon } from '../Hexagon';
import { LoadingBackground } from '../LoadingBackground';
import { CategoryIcon } from '../CategoryIcon';
import { api } from '../../services/api';

interface ISearchingProviderProps {
  serviceName: string;
  firstText?: string;
  secondText?: string;
  serviceSolicitationId?: string;
  clearNewAppointment: () => void;
}

export default function SearchingProvider({
  serviceName,
  firstText,
  secondText,
  serviceSolicitationId = '',
  clearNewAppointment,
}: ISearchingProviderProps) {
  const history = useHistory();

  const handleCancelSearch = async () => {
    if (!serviceSolicitationId) {
      history.push('/');
    }

    clearNewAppointment();

    try {
      await api.delete(`/service-solicitations/${serviceSolicitationId || ''}`);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      history.push('/');
    }
  };

  return (
    <LoadingBackground isAnimated>
      <Container shouldTranslateText={!secondText}>
        <div className="headings-container">
          <img src={Logo} alt="repfy logo" />
          <h1 className={firstText && secondText && 'first-text'}>
            {firstText || 'Buscando o profissional mais próximo de você'}
          </h1>
          <h1 className={secondText && 'second-text'}>{secondText || ''}</h1>
        </div>
        <div className="hexagons-container">
          <AnimatedHexagon customSize="12rem">
            <CategoryIcon text={serviceName} />
          </AnimatedHexagon>
          <div className="hexagon-borders-wrapper">
            <HexagonBorders />
            <CategoryIcon text={serviceName} className="icon" />
          </div>
        </div>
        <button
          type="button"
          className="cancel-search-button"
          onClick={handleCancelSearch}
        >
          Cancelar busca
        </button>
      </Container>
    </LoadingBackground>
  );
}

const Container = styled.div<{
  shouldTranslateText?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 25rem;

  text-align: center;

  .headings-container {
    position: absolute;

    top: 15vh;

    max-width: 25rem;

    img {
      width: 9.375rem;
      height: auto;
    }

    > h1 {
      margin-top: 2rem;
      font-size: 2rem;
    }

    ${(props) => props.shouldTranslateText
      && css`
        animation: verticalSpring 3.5s infinite;
      `}
  }

  .first-text {
    animation: fadeInOut 3.5s infinite;
  }

  .second-text {
    transform: translateY(-145%);
    animation: fadeInOut2 3.5s infinite;
  }

  .hexagons-container {
    position: relative;

    margin-top: 15rem;
  }

  .hexagon-borders-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);

    animation: fadeInOut2 3.5s infinite;

    svg {
      width: 30.375rem;
      height: auto;
    }
  }

  .icon {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25%;
    width: auto;
  }

  .cancel-search-button {
    position: absolute;
    bottom: 5%;

    background: transparent;
    border: none;

    color: #fff;

    font-size: 1.125rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @keyframes fadeInOut {
    0% {
      opacity: 1;
      visibility: visible;
    }

    25% {
      opacity: 0;
      visibility: hidden;
    }

    50% {
      opacity: 0;
      visibility: hidden;
    }

    75% {
      opacity: 1;
      visibility: visible;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes fadeInOut2 {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    25% {
      opacity: 1;
      visibility: visible;
    }

    50% {
      opacity: 1;
      visibility: visible;
    }

    75% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }

  @keyframes verticalSpring {
    0% {
      transform: translateY(50%);
    }

    25% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(0);
    }

    75% {
      transform: translateY(50%);
    }

    100% {
      transform: translateY(50%);
    }
  }
`;

const AnimatedHexagon = styled(Hexagon)`
  animation: fadeInOut 3.5s infinite;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
