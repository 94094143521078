import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function PrivateRoute({ component: Component, ...rest }: any) {
  const { data } = useAuth();
  const token = data?.access_token;

  return (
    <Route
      {...rest}
      render={(props) => (token && data.user.is_active ? (
        <Component {...props} />
      ) : (
        <Redirect to="/entrar" />
      ))}
    />
  );
}
