import { NavLink, useHistory } from 'react-router-dom';

import styled from 'styled-components';

import FaqIcon from '../../assets/images/faq.svg';
import EmailAtIcon from '../../assets/images/email-at.svg';
import WhatsAppIcon from '../../assets/images/whatsapp-icon.svg';

import { Hexagon } from '../../components/Hexagon';
import { GoBackButton } from '../../components/GoBackButton';

export default function Support() {
  const history = useHistory();
  const goBack = () => {
    history.push('/');
  };
  return (
    <Container>
      <GoBackButton onClick={goBack} />
      <Content>
        <h2>
          Algum problema ou dúvida? Entre em contato para podermos te ajudar
        </h2>
        <NavLink to="/faq">
          <Hexagon customSize="6.625rem">
            <img src={FaqIcon} alt="" />
          </Hexagon>
          <span>
            REPFAQ - Perguntas mais frequentes
          </span>
        </NavLink>
        <a href="mailto:suporte@repfy.com.br">
          <Hexagon customSize="6.625rem">
            <img src={EmailAtIcon} alt="" className="email-at-icon" />
          </Hexagon>
          <span>
            Enviar e-mail ao suporte REPFY
          </span>
        </a>
        <a href="https://wa.me/5511966408643" target="_blank" rel="noreferrer">
          <Hexagon customSize="6.625rem">
            <img src={WhatsAppIcon} alt="" />
          </Hexagon>
          <span>
            Converse com nossa equipe pelo WhatsApp
          </span>
        </a>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;


  h2 {
    font-size: 1.625rem;
    color: #001489;
    width: 90%;
    margin: 0 auto;
  }

  a {
    position: relative;
    display: block;
    width: 100%;
    height: 9rem;

    text-decoration: none;

    margin-top: 4rem;

    background-color: #F5F5FA;
    border-radius: 0.5rem;

    font-size: 1.5rem;
    font-weight: 700;
    color: #241DCD;
    text-align: center;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    span {
      display: block;
      margin-top: 5rem;
    }

    > div {
      position: absolute;
      left: 50%;
      top: 1rem;
      transform: translate(-50%, -50%);
    }

    .email-at-icon {
      transform: translate(0, 15%);
    }
  }
`;
