import styled, { css } from 'styled-components';

/*
  ordem dos z-index
    1. Botão de cancelar agendamento
    2. Container do endereço
    3. Container inferior do chat
    4. Splash inicial que fica na parte superior da tela ( animação )
*/

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% + 2rem);
  overflow: hidden;
  flex: 1;
  margin-top: -2rem;

  animation: addMarginLeft 8s;

  .address-container {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10000;
    padding: 1rem;
    max-width: 720px;
    background-color: #F5F5FA80;
    color: #242BF3;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    border-radius: 0.5rem;

    svg, path {
      fill: #242BF3;
    }
  }

  @media(max-width: 600px) {
    margin-bottom: -10rem;
    height: unset;
  }

  @keyframes addMarginLeft {
    0% {
      position: fixed;
      left: 0;
      right: 0;
    }

    75% {
      position: fixed;
      left: 0;
      right: 0;
    }

    100% {
      position: relative;
      left: unset;
      right :unset;
    }
  }
`;

export const ChatContainer = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;

  z-index: 99999;

  width: 100%;
  max-width: 45rem;

  bottom: 0;
  left: 50%;

  padding-bottom: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #081564;

  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;

  color: #fff;

  transition: transform 0.4s;

  ${({ isOpen }) => (isOpen ? css`
      transform: translateX(-50%) translateY(0%);
  ` : css`
      transform: translateX(-50%) translateY(calc(100% - 2.5rem));
  `)}

  > button {
    background: transparent;
    border: none;

    margin-top: 0.75rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    > svg {
      transition: transform 0.4s;
      transform: ${(props) => (!props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
      fill: #fff;
      width: 1rem;
      height: 0.625rem;

    }
  }

  .provider-information {
    width: 90%;
    margin: 0 auto;
    max-width: 28.125rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;


    line-height: 1.5;

    > img {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      border: 3px solid #292EFF;
      object-fit: cover;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: 1rem;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 700;
    }

    button {
      border: none;
      background-color: transparent;
      color: #fff;
      font-size: 1rem;
      border-bottom: 4px solid #005FFF;

      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  @media(max-width: 601px) {
    bottom: 6rem;
  }

`;

export const InitialSplash = styled.div`
  position: absolute;
  z-index: 999999;
  top: 0;
  height: 50vh;
  width: 100%;

  text-align: center;
  line-height: 1.15;

  color: #fff;
  border-bottom-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
  background: linear-gradient(45deg, #000A45, #001489);

  animation: fadeOutWithDelay 8s forwards;

  > div:first-child {
    max-width: 31.25rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1.5rem;
  }

  img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    border: 3px solid #292EFF;
    object-fit: cover;
  }

  h1 {
    font-size: 1.875rem;

    strong {
      font-weight: 700;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 400;
  }

  @keyframes fadeOutWithDelay {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const CancelAppointmentButton = styled.button`
  position: absolute;
  z-index: 9999;
  right: 10rem;
  bottom: 9rem;
  padding: 1rem;

  width: 100%;
  max-width: 17.5rem;

  opacity: 65%;
  background: #fff;
  color: #001489;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  border: none;
  border-radius: 0.5rem;

  box-shadow: 0px 3px 6px #20048133;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  > div {
    display: flex;
    gap: 0.5rem;

    svg {
      fill: #DD352E;
    }

    h2 {
      font-size: 1.125rem;
    }
  }



  p {
    font-size: 0.875rem;
    font-weight: 400;
  }
`;
