import { Route, Switch } from 'react-router-dom';
import { About } from '../pages/About';
import { AppointmentMap } from '../pages/AppointmentMap';
import { Faq } from '../pages/Faq';
import { ForgotPassword } from '../pages/ForgotPassword';
import { History } from '../pages/History';
import { Home } from '../pages/Home';
import { Notifications } from '../pages/Notifications';
import { Payment } from '../pages/Payment';
import { Profile } from '../pages/Profile';
import { RequestService } from '../pages/RequestService';
import { ResetPassword } from '../pages/ResetPassword';
import { Signin } from '../pages/Signin';
import { Signup } from '../pages/Signup';
import { Support } from '../pages/Support';
import { Terms } from '../pages/Terms';
import { UserConfirmation } from '../pages/UserConfirmation';
import PrivateRoute from './PrivateRoute';

export default function Router() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/entrar" component={Signin} />
      <Route exact path="/cadastro/confirmar" component={UserConfirmation} />
      <Route exact path="/cadastro" component={Signup} />
      <Route exact path="/alterar-senha" component={ForgotPassword} />
      <Route exact path="/resetar-senha" component={ResetPassword} />
      <PrivateRoute exact path="/solicitar/:serviceTypeId" component={RequestService} />
      <PrivateRoute exact path="/agendamento" component={AppointmentMap} />
      <PrivateRoute path="/perfil" component={Profile} />
      <PrivateRoute path="/pagamento" component={Payment} />
      <PrivateRoute path="/historico" component={History} />
      <PrivateRoute path="/notificacoes" component={Notifications} />
      <Route path="/suporte" component={Support} />
      <Route path="/faq" component={Faq} />
      <Route path="/termos" component={Terms} />
      <Route path="/sobre" component={About} />
    </Switch>
  );
}
