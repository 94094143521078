import styled from 'styled-components';

import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';

interface IGoBackButtonProps {
  onClick: () => void;
  color?: 'white' | 'darkBlue';
  customClassName?: string;
}

export default function GoBackButton({ onClick, color = 'darkBlue', customClassName = '' }:IGoBackButtonProps) {
  return (
    <Container type="button" onClick={onClick} color={color} className={customClassName}>
      <ArrowLeftIcon />
    </Container>
  );
}

const Container = styled.button<{
  color?: IGoBackButtonProps['color'];
}>`
  background: transparent;
  border: none;

  --darkBlue: #081564;
  --white: '#fff';

  position: absolute;

  top: 8rem;
  left: 12.5rem;
  padding: 0.5rem;

  > svg {
    height: 1rem;
    width: 1rem;
  }

  path {
    fill: transparent;
    stroke: ${({ color }) => (color === 'white' ? 'var(--white)' : 'var(--darkBlue)')};
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 600px) {
    position: unset;
    width: 100%;
    margin: 0 auto;

    display: block;
    width: fit-content;
  }
`;
