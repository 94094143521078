import Modal from 'react-modal';
import styled from 'styled-components';

import FyCash from '../../assets/images/fy-cash.png';
import Mastercard from '../../assets/images/mastercard.png';
import Visa from '../../assets/images/visa.png';
import { IUserCard } from '../../types';

interface IEditMainCardModal {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (id: string) => void;
  cards: IUserCard[];
}

export default function EditMainCardModal({
  cards, isOpen, onClose, onSelect,
}: IEditMainCardModal) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onClose}>X</button>
        <h2>Selecione o cartão</h2>
        {cards
        && cards.map((card) => (
          <button type="button" key={card.id} onClick={() => onSelect(card.id)}>
            <CardBrandLogo brand={card.card_brand_id} />
            <span className="center-dots">
              ....
            </span>
            {card.last_digits}
            {card.is_preferential && (
              <span style={{ marginLeft: '0.5rem' }}>
                (Meio preferencial)
              </span>
            )}
          </button>
        ))}
      </Container>
    </Modal>
  );
}

function CardBrandLogo({ brand }: { brand: number }) {
  switch (brand) {
    case 1:
      return <img src={Visa} alt="Visa" />;
    case 2:
      return <img src={Mastercard} alt="Mastercard" />;
    default:
      return <img src={FyCash} alt="FyCash" />;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;

  max-width: 67.5rem;

  position: relative;

  padding: 3rem;

  background-color: white;

  border-radius: 1rem;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  h2 {
    color: #292eff;
    font-weight: 700;
    font-size: 1rem;
  }

  button {
    border: none;
    background-color: transparent;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
    background: transparent;
  }

  > button {
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;

    min-height: 4.375rem;
    padding: 1rem;

    font-size: 1rem;
    color: #05007A;

    .center-dots {
      color: #05007a;
      font-size: 2.5rem;
      margin-top: -1.25rem;
    }

    > span {
      color: #241DCD;
      margin-right: 0.5rem;
    }

    > img {
      width: 3.4rem;
      height: 2rem;
      object-fit: cover;
      margin-right: 1.75rem;
    }
  }

  > button:nth-child(2n+1) {
    background-color: #EFEFFF;
    border-radius: 0.5rem;

    &.close-button {
      background: transparent;
    }
  }
`;
