import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { IServiceAppointment } from '../../types';
import { CategoryIcon } from '../../components/CategoryIcon';
import { GoBackButton } from '../../components/GoBackButton';

export default function History() {
  const [selected, setSelected] = useState<'history' | 'feed'>('history');
  const [appointments, setAppointments] = useState<IServiceAppointment[]>([]);
  const history = useHistory();

  useEffect(() => {
    getAppointments();
  }, []);

  const getAppointments = async () => {
    try {
      const response = await api.get('/appointments', {
        params: {
          customer_done: true,
        },
      });
      setAppointments(response.data.service_appointments);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const handleGoBack = () => {
    history.push('/');
  };

  const goToRequestService = (serviceId: number) => {
    history.push(`/solicitar/${serviceId}`);
  };

  return (
    <>
      <GoBackButton onClick={handleGoBack} />
      <Container>
        <StyledButton type="button" onClick={() => setSelected('history')} isSelected={selected === 'history'}>
          Histórico de solicitações
        </StyledButton>
        {/*
          <StyledButton
            type="button"
            onClick={() => setSelected('feed')}
            isSelected={selected === 'feed'}
          >
            Seu feed
          </StyledButton>
        */}
        <div />
        <SolicitationList isSelected={selected === 'history'}>
          {appointments.map((appointment) => (
            <div key={appointment.id}>
              <CategoryIcon text={appointment.service_type.name} />
              <div>
                <h3>
                  {appointment.service_type.name}
                  {' '}
                  {appointment?.provider?.user?.individual_person?.name ? ` - ${appointment?.provider?.user?.individual_person?.name.split(' ')[0]}` : ''}
                </h3>

                <time>
                  {format(parseISO(appointment.service_solicitation.date),
                    "'Dia' dd/MM/yyyy '-' HH:mm'h'")}
                </time>
                <p>
                  Pagamento debitado:
                  {' '}
                  {new Intl.NumberFormat(
                    'pt-BR',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    },
                  ).format((Number(appointment.transaction.value) / 100))}
                  {' '}
                  em seu cartão final:
                  {' '}
                  {appointment.transaction.card_last_digits}
                </p>

                {/*
                  <button
                    type="button"
                    onClick={() => goToRequestService(appointment.service_type.id)}
                  >Reabrir solicitação</button> */}
              </div>
            </div>
          ))}
        </SolicitationList>
        {/* <FeedContainer isSelected={selected === 'feed'} /> */}
        <div />
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  min-height: 100%;

  padding: 1.75rem 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 2rem;
  justify-items: start;
`;

const StyledButton = styled.button<{
  isSelected?:boolean;
}>`
  background: transparent;
  border: none;

  font-size: 1.125rem;
  min-height: 3rem;
  padding: 0 1.5rem;

  color:#241DCD;

  ${({ isSelected }) => isSelected && css`
    border-radius: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  `}
`;

const SolicitationList = styled.div<{
  isSelected?:boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;

  ${({ isSelected }) => (!isSelected ? css`
    border-radius: 2rem;
    width: 100%;
    height: 100%;
    background: #F2F2FF;
    > * {
      display: none !important;
    }
    @media (max-width: 601px) {
      display: none;
    }
  ` : css`
    @media (max-width: 601px) {
      grid-column: span 2;
    }
  `)}

  > div {
    width: 100%;
    background: #EFEFFF;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;

    > img {
      height: 3rem;
      filter: invert(1) hue-rotate(180deg);
      -webkit-filter: invert(1) hue-rotate(180deg);
    }

    > div {
      width: 100%;

      display: flex;
      flex-direction: column;

      margin-left: 1.5rem;

      line-height: 1.25;

      h3 {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        color: #241DCD;
      }

      time {
        font-size: 0.75rem;
        color: #001383;
        margin-top: 0.5rem;
      }

      p {
        font-size: 0.75rem;
        color: #001383;
        margin-top: 0.75rem;
      }

      button {
        background: transparent;
        border: none;

        margin-left: auto;
        margin-top: 1.25rem;

        min-height: 2rem;
        font-size: 1rem;
        color: #fff;
        background: #292EFF;
        border-radius: 1rem;
        padding: 0 1.75rem;

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  > div:nth-child(2n) {
    background: #F5F5FA;
  }
`;

const FeedContainer = styled.div<{
  isSelected?:boolean;
}>`
  width: 100%;
  height: 100%;
  ${({ isSelected }) => (!isSelected ? css`
    border-radius: 2rem;
    background: #F2F2FF;
    > * {
      display: none !important;
    }
  ` : css`
    @media (max-width: 601px) {
      grid-column: span 2;
    }
  `)}
`;
