import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';

import { ReactComponent as EmailIcon } from '../../assets/images/email.svg';

import { StyledForm } from './styles';
import { NotificationModal } from '../NotificationModal';

export default function EditEmailForm() {
  const history = useHistory();
  const { data, updateUserInfo } = useAuth();
  const [email, setEmail] = useState(data?.user.email);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await api.put('/users', { email });
      updateUserInfo(response.data);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Alteração salva com sucesso!',
        onClose: () => {
          setHandleNotificationModal((pState) => ({
            ...pState,
            isOpen: false,
          }));
          history.push('/perfil/dados-pessoais');
        },
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || '',
      }));
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <h3>
        Alterar e-mail
      </h3>
      <label>
        <div>
          <EmailIcon />
          <input type="email" onChange={handleChange} value={email} placeholder="E-mail" required />
        </div>
      </label>
      <button type="submit">
        Salvar alteração
      </button>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
        onClose={handleNotificationModal.onClose}
      />
    </StyledForm>
  );
}
