/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { v4 as generateGuid } from 'uuid';

import db from '../../services/firebase';

import { ReactComponent as ChatIcon } from '../../assets/images/mapchat.svg';

interface IChatProps {
  appointmentId: string;
  user: {
    id: string;
    individual_person: {
      name: string;
    }
    avatar_url: string;
  };
}

type Message = {
  _id: string;
  text: string;
  createdAt: db.firestore.Timestamp;
  user: {
    _id: string;
    avatar: string;
    name: string;
  };
}

export default function Chat({ appointmentId, user }: IChatProps) {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const message: Message = {
      _id: generateGuid(),
      text: newMessage,
      createdAt: db.firestore.Timestamp.now(),
      user: {
        _id: user.id,
        avatar: user.avatar_url,
        name: user.individual_person.name,
      },
    };
    try {
      await db.firestore().collection('chats').doc(appointmentId).update({ messages: db.firestore.FieldValue.arrayUnion(message) });
      setNewMessage('');
    } catch (error: any) {
      if (error?.code === 'not-found' || error?.code === 'firestore/not-found') {
        await db.firestore().collection('chats').doc(appointmentId).set({ messages: message });
      }
    }
  };

  const handleNewMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
  };

  useEffect(() => {
    const unsubscribe = db
      .firestore()
      .collection('chats')
      .doc(appointmentId)
      .onSnapshot((collection) => {
        if (collection.data()?.messages.length) {
          const receivedMessages = collection
            .data()
            ?.messages.map((msg: Message) => ({
              _id: msg._id,
              text: msg.text,
              createdAt: new Date(msg.createdAt.toDate()),
              user: msg.user,
            }))
            .sort((a: Message, b: Message) => b.createdAt.seconds - a.createdAt.seconds);
          setMessages(receivedMessages);
        }
      });
    return () => unsubscribe();
  }, []);

  return messages.length > 0 ? (
    <Container>
      <header>
        <ChatIcon />
        Mensagens
      </header>
      <MessagesContainer>
        {messages.map((msg: Message) => (
          <MessageText key={msg._id} receiving={msg.user._id === user?.id}>
            <img src={msg.user.avatar} alt="" />
            {msg.text}
          </MessageText>
        ))}
      </MessagesContainer>
      <MessageBox onSubmit={handleSubmit} isFirstMessage={false}>
        <button type="submit">
          <ChatIcon />
        </button>
        <input type="text" value={newMessage} onChange={handleNewMessageChange} placeholder="Enviar uma mensagem..." />
      </MessageBox>
    </Container>
  ) : (
    <MessageBox onSubmit={handleSubmit} isFirstMessage>
      <button type="submit">
        <ChatIcon />
      </button>
      <input type="text" value={newMessage} onChange={handleNewMessageChange} placeholder="Enviar uma mensagem..." />
    </MessageBox>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 28.125rem;

  background: #4553A7;
  border-radius: 2rem;

  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  header {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem 2rem 0.5rem 2rem;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    border-bottom: 1px solid #EEEEEE;
    > svg {
      fill: #fff;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    path {
      fill: #fff;
    }
  }
`;

const MessagesContainer = styled.div`
  max-height: 35vh;
  height: fit-content;
  padding: 1.25rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  width: 100%;

  scrollbar-width: thin;
  scrollbar-color: #2443FB transparent;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: #2443FB;
  }
`;

const MessageText = styled.div<{
  receiving?: boolean;
}>`
  width: fit-content;
  max-width: 100%;
  display: flex;
  align-items: center;
  border-radius: 1.75rem;
  padding: 0.25rem 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

  img {
    height: 2.625rem;
    width: 2.625rem;
    border-radius: 50%;
    border: 1px solid #292EFF;
    object-fit: cover;
  }

  ${({ receiving }) => (receiving ? css`
    color: #001386;
    background: #EFEFFF;
    margin-left: auto;
    flex-direction: row-reverse;
    img {
      margin-right: -1.75rem;
      margin-left: 1rem;
    }

  ` : css`
    background: #fff;
    color: #241DCD;

    img {
      margin-left: -1.75rem;
      margin-right: 1rem;
    }
  `)}



`;

const MessageBox = styled.form<{
  isFirstMessage?: boolean;
}>`
  border-radius: 0 0 2rem 2rem;
  min-height: 5.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 1.25rem 1.25rem;
  width: 100%;
  max-width: 28.125rem;
  margin: 0 auto;

  background: ${({ isFirstMessage }) => (isFirstMessage ? 'transparent' : '#2443FB')};

  > button {
    background: transparent;
    border: none;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    svg {
      height: 2.5rem;
      width: 2.5rem;
      margin-bottom: -0.625rem;
    }

    path {
      fill: ${({ isFirstMessage }) => (isFirstMessage ? '#005FFF' : '#fff')};
    }
  }

  > input {
    border: none;

    height: 2.75rem;
    border-radius: 1.25rem;
    padding: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: #241DCD;
    width: 100%;

    &::placeholder {
      color: #241DCD;
    }
  }
`;
