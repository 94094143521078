import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as CheckmarkIcon } from '../../assets/images/checkmark.svg';

import Confirmation from '../../assets/images/confirmation.svg';
import BackgroundHexagons from '../../assets/images/backgroundhexagons.png';
import Logo from '../../assets/images/logo.png';
import { TermsModal } from '../../components/TermsModal';
import { NotificationModal } from '../../components/NotificationModal';
import { api } from '../../services/api';

export default function UserConfirmation() {
  const history = useHistory();
  const [confirmFormData, setConfirmFormData] = useState({
    code: '',
    accepted_terms: true,
  });
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    type: 'success' as 'success' | 'error',
    title: 'E-mail validado!',
    message: 'Código de verificação validado com sucesso!',
    buttonText: 'ok, vamos lá!',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setConfirmFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!confirmFormData.code) {
      return;
    }
    if (confirmFormData.accepted_terms) {
      try {
        await api.post('/users/verify', {
          code: confirmFormData.code,
        });

        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          type: 'success',
          title: 'E-mail validado!',
          message: 'Código de verificação validado com sucesso!',
          buttonText: 'ok, vamos lá!',
        }));
      } catch (error: any) {
        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          type: 'error',
          title: error?.response?.data?.message || 'Código inválido!',
          message: '',
          buttonText: '',
        }));
      }
    } else {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: 'É preciso aceitar os termos de uso',
        message: '',
        buttonText: '',
      }));
    }
  };

  const handleAcceptTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmFormData((prevState) => ({
      ...prevState,
      accepted_terms: event.target.checked,
    }));
  };

  const handleOpenTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleCloseNotificationModal = () => {
    if (handleNotificationModal.type === 'success') {
      history.push('/entrar');
    } else {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: false,
      }));
    }
  };

  return (
    <>
      <Container>
        <Content>
          <img src={Logo} alt="logo" />
          <h1>Cadastre-se</h1>

          <h2>Verificação de Usuário</h2>

          <img src={Confirmation} alt="fluxo de cadastro" />

          <h3>Código de autenticação</h3>

          <p>Enviamos um e-mail para fazer a verificação do usuário</p>
          <p>Insira abaixo o código recebido</p>
          <StyledForm onSubmit={handleSubmit}>
            <IconInputContainer active={confirmFormData.code.length > 0}>
              <CheckmarkIcon />
              <input type="text" name="code" value={confirmFormData.code} onChange={handleInputChange} placeholder="Insira o código" required />
            </IconInputContainer>
            <TermsContainer>
              <input id="terms-checkbox" type="checkbox" checked={confirmFormData.accepted_terms} onChange={handleAcceptTerms} />
              <button type="button" onClick={handleOpenTermsModal}>
                Ler termos.
                {' '}
              </button>
              <label htmlFor="terms-checkbox">
                {' '}
                Li e aceito os termos de uso.
              </label>
            </TermsContainer>
            <button type="submit">
              Finalizar cadastro
            </button>
          </StyledForm>
        </Content>
      </Container>
      <TermsModal
        isOpen={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
        onCheck={() => {
          handleAcceptTerms({ target: { checked: !confirmFormData.accepted_terms } } as any);
          setIsTermsModalOpen(false);
        }}
        isChecked={confirmFormData.accepted_terms}
      />
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleCloseNotificationModal}
        title={handleNotificationModal.title}
        message={handleNotificationModal.message}
        buttonText={handleNotificationModal.buttonText}
        type={handleNotificationModal.type}
      />
    </>
  );
}

const IconInputContainer = styled.div<
{
  active: boolean;
}>`
  width: 100%;
  background-color: #fff;

  border-radius: 1.75rem;

  font-size: 1rem;
  font-weight: 300;

  height: 3.5rem;

  display: flex;
  align-items: center;

  padding: 0 1.5rem;

  svg {
    margin-right: 0.875rem;
    width: 1.375rem;
    height: 1.375rem;

    fill: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    // stroke: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    path {
      fill: ${(props) => (props.active ? '#021793' : '#CDCDCD')};
    }
  }

  input {
    flex: 1;
    font-size: 1rem;
    border: none;
    outline: none;
    color: #021793;
  }
`;

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  background:  url(${BackgroundHexagons}), linear-gradient(to bottom,#001489 0%, #0b0a45 100%);
  background-position: center;
  background-size: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem 0;

  max-width: 20.5rem;
  margin: 0 auto;
  text-align: center;

  width: 100%;
  height: 100%;

  color: #ffffff;

  > * + * {
    margin-top: 2rem;
  }

  > img:first-child {
    height: 2rem;
  }

  > img:not(:first-child) {
    width: 13.5rem;
    height: auto;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top :3.5rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
  }

  > p {
    font-size: 1rem;
    line-height: 1.25;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 98%;

  button[type="submit"] {
    border: none;
    color: #fff;
    height: 3rem;
    background-color: #292eff;
    font-size: 1.25rem;
    border-radius: 3rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const TermsContainer = styled.div`
  display: flex;
  align-items: center;

  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;

  margin: 0 auto;

  gap: 0.5rem;

  label {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 700;
    color: #fff;
  }

  input {
    background-color: #0027FF;
    color: #fff;
    width: 1rem;
    height: 1rem;
    margin: 0;
    padding:0;
    cursor: pointer;
  }

  button {
    background-color: transparent;
    border: none;
    color: #0027FF;
    font-size: 0.75rem;
    font-weight: 700;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
