/* eslint-disable react/require-default-props */
import Modal from 'react-modal';
import styled from 'styled-components';

import Success from '../../assets/images/success.svg';
import Failure from '../../assets/images/failure.svg';
import { Hexagon } from '../Hexagon';

interface INotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
  title: string;
  buttonText?: string;
  type: 'success' | 'error';
}

export interface IHandleNotificationModal {
  isOpen: boolean;
  onClose: () => void;
  message?: string;
  type: 'success' | 'error';
  title: string;
  buttonText?: string;
}

export default function NotificationModal({
  isOpen, onClose, message, title, buttonText, type,
}: INotificationModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={400}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={onClose}>X</button>
        <h1>
          {title || (
            type === 'error' ? 'Algo deu errado' : 'Ação realizada com sucesso!'
          )}
        </h1>
        <p>
          {message || ''}
        </p>

        <Hexagon customSize="7rem">
          <img src={type === 'error' ? Failure : Success} alt="sucesso" />
        </Hexagon>

        {buttonText ? (
          <button type="button" onClick={onClose} className="action-button">
            {buttonText}
          </button>
        ) : ''}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  position: relative;

  width: 100vw;
  max-width: 20rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 1rem;

  background-color: white;
  border-radius: 1.375rem;

  color: #292EFF;

  line-height: 1.25;

  h1 {
    font-size: 1.375rem;
    font-weight: 700;
    align-self:flex-start;
    text-align: center;
    width: 100%;
  }

  p {
    font-size: 1.375rem;
    margin-bottom: 2rem;
  }

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .action-button {
    border: none;

    border-radius: 1.5rem;
    background-color: #292EFF;
    color: #fff;
    font-size: 1.375rem;

    margin-top: 3rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    transition: filter 0.2s;

    width: 100%;

    height: 3rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
