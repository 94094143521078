import styled from 'styled-components';

import MastercardLogo from '../../assets/images/mastercard.png';
import VisaLogo from '../../assets/images/visa.png';

import { IUserCard } from '../../types';

interface ISelectedCardProps {
  card: IUserCard;
  customClassName?: string;
}

function getCardBrandLogo(brand_id: number) {
  switch (brand_id) {
    case 1:
      return VisaLogo;
    case 2:
      return MastercardLogo;
    default:
      return VisaLogo;
  }
}

export function SelectedCard({ card, customClassName = '' }: ISelectedCardProps) {
  const cardImage = getCardBrandLogo(card.card_brand_id);

  return (
    <Container className={customClassName}>
      <div className="card-info-container">
        <img src={cardImage} alt="bandeira" />
        <span className="center-dots">
          ....
        </span>
        {card.last_digits}
      </div>
      {card.is_preferential && (
        <span className="preferential-label">
          (Meio preferencial)
        </span>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  color: #9B9B9B;

  span {
    vertical-align: middle;
  }

  .card-info-container {
    display: flex;

    align-items: center;
    gap: 0.25rem;

    color: #05007a;

    min-height: 2.5rem;
  }

  .center-dots {
    color: inherit;
    font-size: 2.5rem;
    margin-top: -1.25rem;
  }

  .preferential-label {
    color: inherit;
    font-size: inherit;
    margin: 0;
  }
`;
