import styled, { css } from 'styled-components';

import { ReactComponent as WardrobeIcon } from '../../assets/images/wardrobe.svg';
import { ReactComponent as WashingMachineIcon } from '../../assets/images/washing-machine.svg';

import { ServiceTypeSolicitationQuestion } from '../../types';
import { ReactComponent as CheckIcon } from '../../assets/images/service-checkmark.svg';
import { ReactComponent as CheckIconGray } from '../../assets/images/service-checkmark-gray.svg';

interface IQuestionIconProps {
  serviceName: string;
  question?: ServiceTypeSolicitationQuestion;
  useInput?: number;
}

export default function QuestionIcon({ serviceName, question, useInput }: IQuestionIconProps) {
  if (question?.service_type_solicitation_question_has_values.some((questionValue) => ['sim', 'não'].includes(questionValue.value.toLowerCase()))) {
    return (
      useInput === 0 ? <CheckIconGray /> : <CheckIcon />
    );
  }
  switch (true) {
    case serviceName.toLowerCase().includes('montador'):
      return useInput === 0 ? <CheckIconGray /> : <WardrobeIcon />;
    case serviceName.toLowerCase().includes('eletrodomésticos'):
      return useInput === 0 ? <CheckIconGray /> : <WashingMachineIcon />;
    default:
      return useInput === 0 ? <CheckIconGray /> : <CheckIcon />;
  }
}
