import styled from 'styled-components';
import EditProfileRouter from '../../routes/subSubRoutes/EditProfileRouter';

export default function AccountInformation() {
  return (
    <Container>
      <h2>Meus dados cadastrais</h2>
      <EditProfileRouter />
    </Container>
  );
}

const Container = styled.div`
  h2 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #292eff;
    text-align: center;
  }
`;
