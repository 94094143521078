import { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Placeholder from '../../assets/images/placeholder.jpg';

import { useAuth } from '../../hooks/useAuth';

import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import CameraIcon from '../../assets/images/camera.svg';

import { NotificationModal } from '../../components/NotificationModal';
import ProfileRouter from '../../routes/subRoutes/ProfileRouter';
import { Hexagon } from '../../components/Hexagon';
import { api } from '../../services/api';

export default function Profile() {
  const history = useHistory();
  const { data, updateUserInfo } = useAuth();
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    type: 'success' as 'error' | 'success',
    title: '',
  });

  const handleAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const formData = new FormData();
    formData.append('avatar', file);
    try {
      const response = await api.patch('/users/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      updateUserInfo(response.data);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        title: 'Alteração salva com sucesso!',
        type: 'success',
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message,
      }));
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <header>
        <div>
          <button type="button" onClick={handleGoBack}>
            <ArrowLeftIcon />
          </button>
          <div>
            <img
              src={data?.user.avatar_url}
              alt="avatar do usuário"
              onError={
                (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  (e.target as HTMLImageElement).src = Placeholder;
                }
              }
            />
            <label>
              <Hexagon customSize="3rem">
                <img src={CameraIcon} alt="alterar avatar" />
              </Hexagon>
              <input type="file" value="" onChange={handleAvatarChange} hidden />
            </label>
          </div>
        </div>
      </header>
      <article>
        <ProfileRouter />
      </article>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        type={handleNotificationModal.type}
        title={handleNotificationModal.title}
      />
    </Container>
  );
}

const Container = styled.div`
  header {
    margin-top: -1.25rem;
    padding-top: 2.5rem;
    height: 6.75rem;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #081564;
    color: #fff;

    > div:last-child {
      position: relative;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        background: transparent;
        border: none;
        position: absolute;
        left: 2rem;
        bottom: 2rem;

        > svg {
          width: 0.75rem;
          height: 1.25rem;
          fill: transparent;
        }

        path {
          fill: unset;
          stroke: #fff;
        }

        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.8);
        }
      }

      > div:last-child {
        position: absolute;
        top: -4.5rem;

        label {
          position: absolute;
          cursor: pointer;
          bottom: 1.5rem;
          right: 0rem;

          background: transparent;
          border: none;

          transition: filter 0.2s;

          &:hover {
            filter: brightness(0.8);
          }
        }

        > img {
          clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
          width: 12.5rem;
          height: 12.5rem;
          object-fit: cover;
          background-color: #EFEFFF;

        }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -1rem;
          transform: translateY(30%);
          width: 9.5rem;
          height: 9.5rem;
          background: linear-gradient(to top, #3224FF, #000C47);
          clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
        }

      }

    }
  }

  article {
    width: 100%;
    height: 100%;
    margin-top: 6rem;

    > h2 {
      font-size: 1.5rem;
      font-weight: 700;
      color: #292EFF;
      text-align: center;
    }
  }
`;
