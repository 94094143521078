import { useState } from 'react';

import styled from 'styled-components';

import Modal from 'react-modal';

import { Rating } from 'react-simple-star-rating';

import { toast } from 'react-toastify';

import Placeholder from '../../assets/images/placeholder.jpg';
import { ReactComponent as ChatIcon } from '../../assets/images/mapchat.svg';

import { IServiceAppointment } from '../../types';
import { Hexagon } from '../Hexagon';
import { CategoryIcon } from '../CategoryIcon';
import { useAuth } from '../../hooks/useAuth';
import { api } from '../../services/api';

interface IFinishAppointmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  appointment: IServiceAppointment;
}

export default function FinishAppointmentModal({
  isOpen, onClose, appointment, onConfirm,
}: IFinishAppointmentModalProps) {
  const { data } = useAuth();
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const dataToSubmit = {
      rate: rating ? rating / 20 : 0,
      feedback: feedback || undefined,
    };
    try {
      await api.put(`/appointments/${appointment?.id}`, {
        done: true,
      });

      await api.post(`/providers/${appointment?.provider?.id}/rate`, dataToSubmit);

      onConfirm();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" onClick={onClose}>X</button>
        <div>
          <div className="images-container">
            <img
              src={appointment?.provider?.user?.avatar_url || Placeholder}
              alt="prestador"
              onError={
                (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                  (e.target as HTMLImageElement).src = Placeholder;
                }
              }
            />
            <StyledHexagon customSize="8rem">
              <CategoryIcon text={appointment.service_type.name} />
            </StyledHexagon>
          </div>
          <h1>
            <strong>
              {appointment?.provider?.user?.individual_person.name || ''}
            </strong>
          </h1>
          <p>Finalizou o seu atendimento</p>
          <p>
            Avalie a prestação de serviços de
            {' '}
            {(appointment?.provider && appointment?.provider?.user?.individual_person?.name.split(' ')[0]) || ''}
          </p>
          <Rating
            ratingValue={rating}
            onClick={handleRatingChange}
            fillColor="#005FFF"
            size={48}
          />
        </div>
        <div>
          <h1>
            Deixar um feedback
            <ChatIcon />
          </h1>
          <p>
            Gostou do trabalho de
            {' '}
            {(appointment?.provider && appointment?.provider?.user?.individual_person?.name.split(' ')[0]) || ''}
            ? Deixe um comentário para outras pessoas saberem que ele é um ótimo profissional
          </p>
          <form onSubmit={handleSubmit}>
            <textarea name="feedback" id="feedback" placeholder={`Escreva aqui o que achou de ${(appointment?.provider && appointment?.provider?.user?.individual_person?.name.split(' ')[0]) || ''}...`} value={feedback} onChange={handleFeedbackChange} />
            <button type="submit">
              Enviar
            </button>
          </form>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  background-color: #081564;
  border-radius: 1.5rem;
  position: relative;
  width: 98vw;
  height: fit-content;

  max-width: 55rem;
  margin: 0 auto;

  line-height: 1.25;

  display: flex;
  justify-content: space-between;

  padding: 3.5rem 4rem;

  color: #fff;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 2rem;
  }

  button:first-child {
    background: transparent;
    border: none;

    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 900;
  }

  h1 {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    strong {
      font-weight: 700;
    }

    svg {
      width: 2rem;
      height: 2rem;
      margin-left: 0.5rem;
    }
  }

  .images-container {
    position: relative;

    margin-bottom: 2rem;

    width: fit-content;

    > img {
      height: 12rem;
      width: 12rem;
      object-fit: cover;
      border: 3px solid #292EFF;
      border-radius: 50%;
      position: relative;
      z-index: 9999;
    }
  }

  > div:last-child {
    max-width: 25rem;
    padding: 2rem;
    padding-right: 0;
    border-left: 1px solid #fff;

    display: flex;
    flex-direction: column;

    gap: 2rem;

    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 1rem;
    }

    textarea {
      resize: none;
      width: 100%;
      background-color: #EFEFFF;
      color: #241DCD;
      font-size: 1rem;
      font-weight: 500;
      font-family: 'Axiforma', sans-serif;
      border-radius: 1.125rem;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

      padding: 1.5rem;

      height: 100%;

      &::placeholder{
        color: #241DCD
      }

      &:focus {
        outline: #241DCD solid 2px;
      }
    }

    @media (max-width: 650px) {
      border-left: unset;
      padding: 0;
    };
  }

  button[type="submit"] {
    background-color: #292eff;
    color: #fff;
    border-radius: 3rem;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 3rem;
    padding: 0 1.5rem;
    border: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 1rem;
  };
`;

const StyledHexagon = styled(Hexagon)`
  position: absolute;
  right: 0;
  bottom: -2rem;
  right: -6rem;
`;
