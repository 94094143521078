import { ReactComponent as HomeIcon } from '../assets/images/home.svg';
import { ReactComponent as HistoryIcon } from '../assets/images/history.svg';
import { ReactComponent as ProfileIcon } from '../assets/images/profile.svg';

interface INavigationTitle {
  [key: string]: {
    text: string;
    icon?: React.ReactNode;
    iconColor?: string;
    headerBackgroundColor?: 'darkBlue' | 'lightBlue';
  }
}

export const NavigationTitles: INavigationTitle = {
  home: {
    text: 'Home',
    icon: <HomeIcon />,
  },
  historico: {
    text: 'Histórico',
    icon: <HistoryIcon />,
  },
  perfil: {
    text: 'Perfil',
    icon: <ProfileIcon />,
    iconColor: '#fff',
    headerBackgroundColor: 'darkBlue',
  },
  pagamento: {
    text: 'Meios de pagamento',
    iconColor: '#fff',
    headerBackgroundColor: 'darkBlue',
  },
  suporte: {
    text: 'Suporte',
    iconColor: '#fff',
    headerBackgroundColor: 'darkBlue',
  },
  faq: {
    text: 'Perguntas frequentes',
    iconColor: '#fff',
    headerBackgroundColor: 'darkBlue',
  },
  termos: {
    text: 'Termos de uso',
    iconColor: '#fff',
    headerBackgroundColor: 'darkBlue',
  },
  notificacoes: {
    text: 'Notificações',
  },
};
