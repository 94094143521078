import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';

import { NotificationModal } from '../NotificationModal';

import { ReactComponent as LockIcon } from '../../assets/images/lock.svg';
import { api } from '../../services/api';

export default function EditPasswordForm() {
  const history = useHistory();
  const [editPasswordFormData, setEditPasswordFormData] = useState({
    password: '',
    old_password: '',
    password_confirmation: '',
  });
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditPasswordFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const dataToSubmit = {
      ...editPasswordFormData,
    };
    try {
      await api.patch('/users/password', dataToSubmit);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        onClose: () => {
          setHandleNotificationModal((previous) => ({ ...previous, isOpen: false }));
          history.push('/perfil');
        },
        isOpen: true,
        type: 'success',
        title: 'Alteração salva com sucesso!',
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message,
      }));
    }
  };

  return (
    <>
      <Container>
        <h3>Alterar senha</h3>
        <StyledForm onSubmit={handleSubmit}>
          <StyledLabel active={editPasswordFormData.old_password.length > 0}>
            Senha atual
            <div>
              <LockIcon />
              <input name="old_password" type="password" placeholder="Senha" value={editPasswordFormData.old_password} onChange={handleInputChange} />
            </div>
          </StyledLabel>
          <StyledLabel active={editPasswordFormData.password.length > 0}>
            Nova senha
            <div>
              <LockIcon />
              <input name="password" type="password" placeholder="Alterar senha" value={editPasswordFormData.password} onChange={handleInputChange} />
            </div>
          </StyledLabel>
          <StyledLabel active={editPasswordFormData.password_confirmation.length > 0} style={{ marginTop: '0' }}>
            <div>
              <LockIcon />
              <input name="password_confirmation" type="password" placeholder="Confirmar nova senha" value={editPasswordFormData.password_confirmation} onChange={handleInputChange} />
            </div>
          </StyledLabel>
          <button type="submit">
            Alterar senha
          </button>
        </StyledForm>
      </Container>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
        onClose={handleNotificationModal.onClose}
      />
    </>
  );
}

const Container = styled.div`
  h3 {
    font-size: 1.875rem;
    font-weight: 500;
    color: #292EFF;
    text-align: center;
  }
`;

const StyledForm = styled.form`
  margin: 2.25rem auto;
  background-color: #EFEFFF;
  border-radius: 2rem;
  padding: 2rem 3rem;

  width: 100%;
  max-width: 31.25rem;

  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: 1.75rem;
  }

  button[type="submit"] {
    background-color: #292EFF;
    border: none;
    border-radius: 2rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    color: #fff;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-size: 1.375rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

const StyledLabel = styled.label<{
  active?:boolean;
}>`
  font-size: 1.5rem;
  font-weight: 500;
  color: #021793;

  div {
    display: flex;
    align-items: center;
    background-color:#fff;
    border-radius: 2rem;
    height: 4rem;
    padding: 0 1.25rem;

    margin-top: 1rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 0.5rem;
      path {
        fill: ${({ active }) => (active ? '#021793' : '')};
      }
    }

    input {
      width: 90%;
      outline: none;
      border: none;
      color: #222;
      font-size: 1.25rem;
      font-weight: 400;
      background-color: transparent;

      &::placeholder {
        color: #ADACAC;
      }
    }
  }
`;
