import { useState } from 'react';
import Modal from 'react-modal';

/* import {
  useJsApiLoader,
} from '@react-google-maps/api'; */

import axios from 'axios';

import styled from 'styled-components';
import { api } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { NotificationModal } from '../NotificationModal';

interface IAddressModalProps {
  isOpen: boolean;
  onClose: (addressId: string, addressStreet: string, addressNumber: string, addressNeighborhood: string) => void;
  isEditing?:boolean;
  onEditSubmit?:(formData: IAddressFormData) => void;
}

export interface IAddressFormData {
  cep: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  city_ibge_code: string;
  name: string;
}

const initialFormData = {
  cep: '',
  street: '',
  neighborhood: '',
  number: '',
  complement: '',
  city: '',
  city_ibge_code: '',
  name: '',
};

const libraries = ['places'] as any;

export default function AddressModal({
  isEditing, isOpen, onClose, onEditSubmit,
}: IAddressModalProps) {
  /* const { isLoaded } = useJsApiLoader({
    libraries,
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  }); */
  const { updateAddress } = useAuth();
  const [addressFormData, setAddressFormData] = useState(initialFormData);
  const [cepError, setCepError] = useState(false);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const getGeolocation = async () => {
    const service = new google.maps.Geocoder();
    const request: any = {
      address: `${addressFormData?.street} ${addressFormData?.number} ${addressFormData?.cep}`,
    };
    const newGeo = await service.geocode(request, (results, status) => {
      if (results && results.length) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        return {
          latitude,
          longitude,
        };
      }
      return {
        latitude: 0,
        longitude: 0,
      };
    });
    return {
      latitude: newGeo.results[0] ? newGeo.results[0].geometry.location.lat() : 0,
      longitude: newGeo.results[0] ? newGeo.results[0].geometry.location.lng() : 0,
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (cepError) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: 'CEP inválido',
      }));
      return;
    }

    const newGeo = {
      latitude: 0,
      longitude: 0,
    };

    const dataToSubmit = {
      name: addressFormData.name,
      street: addressFormData.street,
      neighborhood: addressFormData.neighborhood,
      number: addressFormData.number,
      cep: addressFormData.cep,
      complement: addressFormData.complement,
      city_ibge_code: Number(addressFormData.city_ibge_code),
      main: true,
      // geolocation: newGeo,
    };

    if (isEditing) {
      if (onEditSubmit) {
        onEditSubmit(addressFormData);
        return;
      }
    }

    try {
      const response = await api.post('/addresses', dataToSubmit);
      // updateAddress(response.data.id, response.data);
      onClose(response.data.id, response.data.street, response.data.number, response.data.neighborhood);
      setAddressFormData(initialFormData);
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response ? error?.response.data.message : error?.message,
      }));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCepBlur = async () => {
    if (addressFormData.cep.length < 8) {
      return;
    }
    try {
      const response = await axios.get(`
        https://viacep.com.br/ws/${addressFormData.cep.replace(/-/g, '')}/json/
      `);
      if (response.data.erro) {
        setCepError(true);
      } else {
        setCepError(false);
        setAddressFormData((prevState) => ({
          ...prevState,
          cep: response.data.cep.replace(/-/g, ''),
          street: response.data.logradouro,
          neighborhood: response.data.bairro,
          complement: response.data.complemento,
          city: response.data.localidade,
          city_ibge_code: response.data.ibge,
        }));
      }
    } catch (error: any) {
      setCepError(true);
    }
  };

  const handleClose = () => {
    onClose('', '', '', '');
    setAddressFormData(initialFormData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={handleClose}>X</button>
        <StyledForm onSubmit={handleSubmit}>
          <h1>Insira o endereço</h1>
          <input
            name="name"
            type="text"
            placeholder="Nome do local"
            value={addressFormData.name}
            onChange={handleInputChange}
            required
          />
          <input
            name="cep"
            type="text"
            placeholder="CEP"
            value={addressFormData.cep}
            onChange={handleInputChange}
            onBlur={(e) => handleCepBlur()}
            minLength={8}
            maxLength={9}
            required
          />
          <input
            name="street"
            type="text"
            placeholder="Rua"
            value={addressFormData.street}
            onChange={handleInputChange}
            required
          />
          <input
            name="neighborhood"
            type="text"
            placeholder="Bairro"
            value={addressFormData.neighborhood}
            onChange={handleInputChange}
            required
          />
          <input
            name="number"
            type="text"
            placeholder="Número"
            value={addressFormData.number}
            onChange={handleInputChange}
            required
          />
          <input
            name="complement"
            type="text"
            placeholder="Complemento"
            value={addressFormData.complement}
            onChange={handleInputChange}
          />
          <button type="submit">
            Confirmar
          </button>
        </StyledForm>
      </Container>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </Modal>
  );
}

const Container = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  max-width: 67.5rem;

  position: relative;

  padding: 3rem;

  background-color: white;

  border-radius: 1rem;

  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);


  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const StyledForm = styled.form`

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > h1 {
    font-size: 2rem;
    font-weight: 700;
    color: #292eff;
  }

  input {
    border: none;

    min-width: 0;

    font-size: 1.375rem;
    font-weight: 500;

    height: 3.5rem;
    line-height: 3.5rem;

    padding: 0 1.75rem;

    border-radius: 1rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  button[type="submit"] {
    border: none;
    background-color: #292eff;

    color: #fff;

    font-size: 1.25rem;
    font-weight: 500;

    border-radius: 1rem;

    height: 3.5rem;
    line-height: 3.625rem;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

  }

`;
