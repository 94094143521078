import { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

import { api } from '../../services/api';

import { ReactComponent as ProfileIcon } from '../../assets/images/profile.svg';

import { StyledForm } from './styles';
import { NotificationModal } from '../NotificationModal';

export default function EditName() {
  const history = useHistory();
  const { data, updateUserInfo } = useAuth();
  const [name, setName] = useState(data?.user.individual_person.name);
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    title: '',
    type: 'success' as 'error' | 'success',
    onClose: () => setHandleNotificationModal((prevState) => ({
      ...prevState,
      isOpen: false,
    })),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await api.put('/users', { name });
      updateUserInfo(response.data);
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Alteração salva com sucesso!',
        onClose: () => {
          setHandleNotificationModal((pState) => ({
            ...pState,
            isOpen: false,
          }));
          history.push('/perfil/dados-pessoais');
        },
      }));
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || '',
      }));
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <h3>
        Alterar nome
      </h3>
      <label>
        <div>
          <ProfileIcon />
          <input type="text" onChange={handleChange} value={name} placeholder="Nome" required />
        </div>
      </label>
      <button type="submit">
        Salvar alteração
      </button>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
        onClose={handleNotificationModal.onClose}
      />
    </StyledForm>
  );
}
