import { useState, useEffect } from 'react';

import styled from 'styled-components';
import Modal from 'react-modal';
import { api } from '../../services/api';

interface ITermsModalProps {
  isOpen: boolean;
  onClose: () => void;
  isChecked: boolean;
  onCheck: () => void;
}

export default function TermsModal({
  isOpen, onClose, isChecked, onCheck,
}: ITermsModalProps) {
  const [terms, setTerms] = useState('');

  useEffect(() => {
    if (isOpen) {
      getTerms();
    }
  }, [isOpen]);

  const getTerms = async () => {
    const response = await api.get('/system-infos');

    setTerms(response.data.system_infos.terms_and_conditions);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__blue-background"
    >
      <Container>
        <h1>Termos de uso e condições</h1>

        <div className="wrapper">
          <div>
            <p>
              {terms.replace(/\n\n/g, '\n').replace(/\n/g, '\n\n')}
            </p>
          </div>
        </div>

        <label>
          <input type="checkbox" defaultChecked={isChecked} onChange={onCheck} />

          <span>Li e concordo com os termos de uso.</span>
        </label>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: max(40vw, 32rem);
  max-height: 80vh;

  border-radius: 2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 1.5rem;
  padding-right: 0.75rem;

  > * + * {
    margin-top: 2rem;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 400;

    height: 3.875rem;

    text-align: center;
    line-height: 3.875rem;

    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    border-radius: 0.5rem;

    color: #292EFF;
  }

  .wrapper {

    max-height: 50vh;
    overflow-y: auto;

    > div {
      p {
        font-size: 1rem;
        color: #727272;
        font-weight: 500;
        white-space: pre-line;
        text-align:justify;
        text-justify: distribute;
        padding-right: 0.75rem;
      }
    }



    scrollbar-width: thin;
    scrollbar-color: #241DCD #CFCFFF;

    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1.25rem;
      background-color: #CFCFFF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1.25rem;
      background-color: #241DCD;
    }

  }

  > label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: #A2A2A2;

    input {
      background-color: #292EFF;
      color: #fff;
      width: 1rem;
      height: 1rem;
      cursor:pointer;

      transition: filter 0.3s;

      &:hover {
        filter: brightness(0.8);
      }
    }
    cursor:pointer;
  }
`;
