import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import formatRelative from 'date-fns/formatRelative';
import ptBR from 'date-fns/locale/pt-BR';

import { GoBackButton } from '../../components/GoBackButton';
import { NotificationContext } from '../../contexts/NotificationContext';

const formatRelativeLocale = {
  ...ptBR.formatRelative,
  today: "'hoje às' HH:mm'h'",
  yesterday: "'ontem às' HH:mm'h'",
  lastWeek: "dd/MM/yyyy 'às' HH:mm'h'",
  other: "dd/MM/yyyy 'às' HH:mm'h'",
} as { [key: string]: string };

export const locale = {
  ...ptBR,
  formatRelative: (token: string) => formatRelativeLocale[token],
};

export default function Notifications() {
  const history = useHistory();
  const { notifications } = useContext(NotificationContext);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <GoBackButton onClick={handleGoBack} />
      <h2>
        Suas notificações
      </h2>
      {
        notifications.length > 0 ? (
          <NotificationsList>
            {notifications.map((notification) => (
              <div key={notification.id}>
                <h3>{notification.heading}</h3>
                <h3>{notification.content}</h3>
                <p>
                  {formatRelative(notification.date, new Date(), {
                    locale,
                  })}
                </p>
              </div>
            ))}
          </NotificationsList>
        ) : (
          <p>Você não possui notificações</p>
        )
      }
    </Container>
  );
}

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 1.75rem 0;

  h2 {
    display: flex;
    align-items: center;

    color: #241DCD;
    font-size: 1.25rem;
    width: fit-content;
    min-height: 3rem;
    padding: 0.25rem 2.25rem;

    border-radius: 1.5rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    margin-bottom: 2.75rem;
  }

  > p {
    color: #ADACAC;
  }

`;

const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    width: 100%;

    max-width: 36.25rem;

    background: #EFEFFF;
    border-radius: 0.5rem;

    padding: 1.875rem;

    h3 {
      font-size: 1rem;
      color: #241DCD;
    }

    p {
      color: #001383;
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }
  }

`;
