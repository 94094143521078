import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import Modal from 'react-modal';

import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Rating } from 'react-simple-star-rating';

import { toast } from 'react-toastify';

import Placeholder from '../../assets/images/placeholder.jpg';

import { Hexagon } from '../Hexagon';
import { CategoryIcon } from '../CategoryIcon';
import { useAppointments } from '../../hooks/useAppointments';
import { api } from '../../services/api';

export default function NewAppointmentModal() {
  const history = useHistory();
  const {
    solicitationToConfirm,
    requestedSolicitation,
    clearNewAppointment,
    clearSolicitationToConfirm,
    socket,
    updateAppointmentList,
  } = useAppointments();

  const handleConfirm = () => {
    socket?.emit('client_solicitation_response', {
      ...solicitationToConfirm,
      accepted: true,
      origin: 2,
      priceProvider: solicitationToConfirm?.price,
    });
    clearNewAppointment();
    updateAppointmentList();
  };

  const handleReject = async () => {
    try {
      socket?.emit('client_solicitation_response', {
        ...solicitationToConfirm,
        accepted: false,
        origin: 2,
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    } finally {
      clearSolicitationToConfirm();
    }
  };

  const handleCancel = async () => {
    try {
      await api.delete(
        `/service-solicitations/${requestedSolicitation?.id || ''}`,
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    } finally {
      clearNewAppointment();
    }
  };

  return (
    <Modal
      isOpen={!!solicitationToConfirm}
      onRequestClose={handleCancel}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={handleCancel}>
          X
        </button>
        <div className="images-container">
          <img
            src={solicitationToConfirm?.provider_avatar}
            alt="prestador"
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              (e.target as HTMLImageElement).src = Placeholder;
            }}
          />
          <StyledHexagon customSize="9rem">
            <CategoryIcon
              text={requestedSolicitation?.serviceType?.name || ''}
            />
          </StyledHexagon>
        </div>
        <span>{requestedSolicitation?.serviceType?.name || ''}</span>
        <h1>{solicitationToConfirm?.provider_name || ''}</h1>
        <Rating
          onClick={() => {}}
          fillColor="#005FFF"
          ratingValue={0}
          initialValue={
            solicitationToConfirm?.provider_average_rating !== undefined
              ? solicitationToConfirm?.provider_average_rating
              : 0
          }
          size={20}
          readonly
        />
        {/* <p>
          Confirmou o seu agendamento para:
          {' '}
          {format(
            parseISO(requestedSolicitation?.date || new Date().toISOString()),
            "dd/MM/yyyy 'às' HH:mm'h'",
          )}
        </p>
        <p>
          Aguarde
          {' '}
          {newAppointment.provider.user.individual_person.name.split(' ')[0]}
          {' '}
          na data e horário agendado!
        </p>
        <button type="button" onClick={goToAgenda}>Ver agenda</button> */}
        <div className="information-container">
          <div className="product-container">
            <h2>{requestedSolicitation?.product.model || ''}</h2>
            <p>
              Marca:
              {' '}
              {requestedSolicitation?.product.brand || ''}
              {' '}
            </p>
            <p>
              Tipo:
              {' '}
              {requestedSolicitation?.product.type || ''}
            </p>
          </div>
          <div className="date-container">
            <span>Data:</span>
            <span>
              {format(
                parseISO(
                  requestedSolicitation?.date || new Date().toISOString(),
                ),
                "dd/MM/yyyy 'às' HH:mm'h'",
              )}
            </span>
          </div>

          <div className="value-container">
            <span>Valor total:</span>
            <span>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(
                (solicitationToConfirm?.price
                  && solicitationToConfirm?.price / 100)
                  || 0,
              )}
            </span>
          </div>
          <div className="buttons-container">
            <button type="button" className="accept" onClick={handleConfirm}>
              Aceitar
            </button>
            <button type="button" className="reject" onClick={handleReject}>
              Recusar
            </button>
          </div>
        </div>
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  background-color: #081564;
  border-radius: 1.5rem;
  position: relative;
  width: 98vw;
  height: fit-content;

  max-width: 32rem;

  padding: 2.5rem 4rem;

  color: #fff;

  button {
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  .close-button {
    background: transparent;
    border: none;

    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 900;
  }

  .images-container {
    position: relative;

    margin: 0 auto;
    margin-left: 2.5rem;
    margin-bottom: 2rem;

    width: fit-content;

    > img {
      height: 13.5rem;
      width: 13.5rem;
      object-fit: cover;
      border: 3px solid #292eff;
      border-radius: 50%;
      position: relative;
      z-index: 9999;
    }
  }

  h1 {
    font-size: 1.75rem;
    font-weight: 700;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  span {
    font-size: 1.125rem;
  }

  p {
    font-size: 1.5rem;
    margin-top: 2rem;
    line-height: 1.1;
  }

  p + p {
    margin-top: 3rem;
  }

  /* button:not(:first-child) {
    background: transparent;
    border: none;

    display: block;

    font-size: 1.5rem;
    font-weight: 500;
    color: #fff;

    border-bottom: 5px solid #292EFF;

    transition: filter 0.2s;

    margin: 0 auto;
    margin-top: 2.5rem;

    &:hover {
      filter: brightness(0.8);
    }
  } */

  .information-container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .product-container {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;

    border-top: 1px solid #fff;

    line-height: 1.15;

    h2 {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  .date-container {
    border-top: 1px solid #fff;
    padding-top: 1rem;
  }

  .date-container,
  .value-container,
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .buttons-container button {
    border: none;
    border-radius: 1.5rem;
    height: 3rem;
    font-size: 1.25rem;
    color: #fff;
    width: 100%;

    &.reject {
      border: 1px solid #fff;
      background: transparent;
    }

    &.accept {
      background: #292eff;
    }
  }
`;

const StyledHexagon = styled(Hexagon)`
  position: absolute;
  right: 0;
  bottom: -2rem;
  right: -6rem;
`;
