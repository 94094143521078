import styled from 'styled-components';

import BackgroundHexagons from '../../assets/images/backgroundhexagons.png';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  background:  url(${BackgroundHexagons}), linear-gradient(to bottom,#001489 0%, #0b0a45 100%);
  background-position: center;
  background-size: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  width: 100%;
  height: 100%;

  max-width: 22rem;

  color: #ffffff;

  > * + * {
    margin-top: 2rem;
  }

  img {
    height: 2rem;
  }

  h1 {
    font-size: 1.375rem;
    font-weight: 600;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 20rem;
  width: 98%;

  > input {
    border: none;
    border-radius: 1.25rem;
    background-color: #fff;
    width: 100%;

    height: 3rem;

    font-size: 1rem;

    padding: 0 1.5rem;

    line-height: 3rem;


    &::placeholder {
      color: #ADACAC;
    }
  }

  label {
    display: flex;
    align-items: center;


    input {
      margin-right: 0.5rem;
    }
  }

  button[type="submit"] {
    border: none;

    height: 3rem;

    background-color: #292eff;

    color: #fff;
    font-size: 1.25rem;

    border-radius: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);

    transition: filter 0.2s;

    margin-top: 0.5rem;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
