import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import Logo from '../../assets/images/logo.png';
import { NotificationModal } from '../../components/NotificationModal';
import { api } from '../../services/api';

import { Container, Content, StyledForm } from './styles';

export default function ResetPassword() {
  const { search } = useLocation();
  const [wasSent, setWasSent] = useState(false);
  const [resetPasswordFormData, setResetPasswordFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () => setHandleNotificationModal((prevState) => ({ ...prevState, isOpen: false })),
    title: '',
    type: 'success' as 'success' | 'error',
  });

  const query = new URLSearchParams(search);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const token = query.get('token');

      const { password, passwordConfirmation } = resetPasswordFormData;

      if (password !== passwordConfirmation) {
        setHandleNotificationModal((prevState) => ({
          ...prevState,
          isOpen: true,
          type: 'error',
          title: 'Os campos de senha e confirmação de senha devem ser iguais',
        }));
        return;
      }

      const dataToSubmit = {
        token,
        password,
        password_confirmation: passwordConfirmation,
      };

      await api.post('/password/reset', dataToSubmit);

      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'success',
        title: 'Senha alterada com sucesso!',
      }));

      setResetPasswordFormData({
        password: '',
        passwordConfirmation: '',
      });

      setWasSent(true);
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: error?.response?.data?.message || error?.message || 'Erro ao alterar senha',
      }));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setResetPasswordFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  if (wasSent) {
    return (
      <Container>
        <Content>
          <img src={Logo} alt="logo" />
          <h1>
            Senha alterada com sucesso!
          </h1>

          <h2>
            Volte à aplicação para realizar o login
          </h2>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />
        <h1>
          Redefinição de senha
        </h1>

        <StyledForm onSubmit={handleSubmit}>
          <h2>
            Por favor digite a sua nova senha abaixo
          </h2>

          <input
            name="password"
            type="password"
            placeholder="Nova senha"
            value={resetPasswordFormData.password}
            onChange={handleInputChange}
            minLength={8}
            required
          />

          <input
            name="passwordConfirmation"
            type="password"
            placeholder="Confirmar nova senha"
            value={resetPasswordFormData.passwordConfirmation}
            onChange={handleInputChange}
            minLength={8}
            required
          />

          <button type="submit">Salvar senha</button>
        </StyledForm>
      </Content>
      <NotificationModal
        isOpen={handleNotificationModal.isOpen}
        onClose={handleNotificationModal.onClose}
        title={handleNotificationModal.title}
        type={handleNotificationModal.type}
      />
    </Container>
  );
}
